import React from "react";

import { Button, Link } from "/component/base";
import ProviderDetailsModalBase from "/component/modal/ProviderDetailsModal/ProviderDetailsModalBase";
import { useTranslation } from "/hook";
import useContactInfo from "/hook/useContactInfo";
import { layout } from "/styles";
import { formatText } from "/util";

import { Props } from "./AthenaSchedulingProviderDetailsModal.types";

const callCenterName = "summitCallCenter";

const AthenaSchedulingProviderDetailsModal = ({
  hideCta = false,
  goBackOnCtaClick,
  close,
  isOpen,
  provider,
}: Props) => {
  const { t } = useTranslation("modal-schedulingProviderDetails");

  const { data, loading } = useContactInfo();
  const callCenterContact = data?.contactInfo?.find(({ name }) => name === callCenterName);

  // Depending on the provider's state we will either link the user to book through decision support,
  // or we will provide a `tel:` link that the user can call to book
  const getCta = () => {
    if (hideCta) return null;
    // If the user clicks on a provider after completing decision support, clicking the CTA
    // should close the modal, since the provider they clicked on has availability options
    if (goBackOnCtaClick) {
      return (
        <Button
          variant="secondary"
          onClick={close}
          aria-label={t("cta.ariaLabel.goBack")}
          css={layout.paddingHorizontal(40)}
        >
          {t("cta.viewAvailability")}
        </Button>
      );
    }

    // TODO: ExperianFlowId is not available in the provider objject in b2c-grphaql
    // Start decision support, with some initial params, if the provider has an experianFlowId
    // if (provider?.experianFlowId) {
    //   const handleViewAvailability = () => {
    //     const to = `${routes.scheduling}${routes.decisionSupport}/${provider.experianFlowId}`;
    //     const search = qs.stringify({
    //       athenaId: provider.providerId,
    //     });
    //     history.push(`${to}?${search}`);
    //   };

    //   return (
    //     <Button
    //       variant="secondary"
    //       onClick={handleViewAvailability}
    //       css={layout.paddingHorizontal(40)}
    //     >
    //       {t("cta.viewAvailability")}
    //     </Button>
    //   );
    // }

    //If the provider cannot be booked through decision support, link to call to manually book an appointment
    // TODO: Update how to get call center phone number after GROW-3027
    if (callCenterContact) {
      return (
        // TODO: Cleanup Link.Button when we need it to behave like a plain Anchor.
        <Link.Button as="a" variant="secondary" href={`tel:${callCenterContact.phoneNumber}`} to="">
          {t("cta.callToBook")}
        </Link.Button>
      );
    }

    return null;
  };

  return (
    <ProviderDetailsModalBase
      close={close}
      loading={loading}
      isOpen={isOpen}
      primarySpecialty={provider?.specialty}
      nameWithDesignations={provider?.displayName}
      avatarUrl={provider?.imageUrl}
      initials={formatText.parseInitials({
        firstName: provider?.firstName,
        lastName: provider?.lastName,
      })}
      cta={getCta()}
      bio={""}
      qualifications={[]}
      languages={["English"]}
    />
  );
};

export default AthenaSchedulingProviderDetailsModal;
