import { address } from "/apollo/client/local";
import { AddressStorageKey } from "/apollo/client/local/address/address.types";
import { GET_DEMOGRAPHICS_patient_demographics } from "/b2c/schema";
import { googlePlaces } from "/util";

export const initializeAddress = async (
  patient: Partial<GET_DEMOGRAPHICS_patient_demographics> | null | undefined,
  googleMaps: typeof google.maps | undefined,
) => {
  if (!localStorage.getItem(AddressStorageKey) && patient) {
    const { address1, city, state, zipCode } = patient;
    const addressString = `${address1}, ${city}, ${state}, ${zipCode}`;
    if (googleMaps) {
      const result = await googlePlaces.getPlaceByAddress(googleMaps, addressString);
      if (result) {
        const addressComponents = googlePlaces.extractAddress(result.address_components);
        address.update({
          ...addressComponents,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        });
      }
    }
  }
};
