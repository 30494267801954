import React from "react";

import * as Icons from "/component/base/Icon";
import Text from "/component/base/Text";
import { layout } from "/styles";
import { date } from "/util";

import { InactiveContainer, InsuranceItemContainer, TextContainer } from "./InsuranceItem.styles";

interface Props {
  insurancePlanName: string | null;
  memberId: string | null;
  groupNumber: string | null;
  dateLastVerified: string;
  insuranceType: string;
  eligibilityStatus: string | null;
  index: number;
}

const InsuranceItem = ({
  insurancePlanName,
  memberId,
  groupNumber,
  dateLastVerified,
  insuranceType,
  eligibilityStatus,
  index,
}: Props) => {
  const inactive = eligibilityStatus === "Ineligible";
  const topPadding = index > 0 ? "standard" : "skip";
  return (
    <div css={[layout.flexItemAuto, layout.padding(topPadding, "skip", "skip", "skip")]}>
      <Text variant="body1Bold">{insuranceType}</Text>
      <InsuranceItemContainer>
        <Icons.InsuranceCard
          color={inactive ? "iconDisabled" : "brandSecondary"}
          background={{
            size: 54,
            color: inactive ? "backgroundSurfaceHover" : "brandSecondaryLight",
          }}
        />

        <TextContainer>
          <Text variant="body1Bold">{insurancePlanName}</Text>
          {memberId && (
            <Text color="textSecondary" variant="body2">{`Member ID: ${memberId}`}</Text>
          )}
          {groupNumber && (
            <Text color="textSecondary" variant="body2">{`Group number: ${groupNumber}`}</Text>
          )}
          {inactive ? (
            <InactiveContainer>
              <Text color="textSecondary" variant="body2Bold">
                {"Inactive"}
              </Text>
            </InactiveContainer>
          ) : (
            <Text color="textSecondary" variant="body2">{`Date last verified: ${date.formatDate(
              dateLastVerified,
              "MMMM d, y",
            )}`}</Text>
          )}
        </TextContainer>
      </InsuranceItemContainer>
    </div>
  );
};

export default InsuranceItem;
