import { Dialog } from "@headlessui/react";
import React from "react";
import styled from "styled-components";

import { getTextStyles, TextProps } from "/component/base/Text";
import { layout, transition } from "/styles";
import { ThemeType } from "/theme/default";

import { DialogProps, DialogTextProps } from "./Modal.types";

export const closeButtonSize = 40;

// ====================================
// HeadlessUI Styled Wrappers
// ====================================

// The base Dialog.Title and Dialog.Description components accept super generic props and I was
// having trouble typing our styled-component versions of them to accept (**and enforce**) text
// props -- they would accept any prop and any type without complaining.
// That's why I'm creating these compnents that just return their Dialog counterparts.

const BaseTitle = (props: DialogProps) => <Dialog.Title {...props} />;
const BaseDescription = (props: DialogProps) => <Dialog.Description {...props} />;

export const Description = styled(BaseDescription)<DialogTextProps>(getTextStyles);
export const Title = styled(BaseTitle)<DialogTextProps>(getTextStyles);

// ====================================
// Base Modal Styles
// ====================================

// The Base component is the wrapper component that will be rendered in a Portal by HeadlessUI.
// It contains the overlay as well as the actual visual modal itself.
export const Base = styled(Dialog)<any>( // eslint-disable-line @typescript-eslint/no-explicit-any
  layout.absoluteFill,
  ({ theme }) => ({
    position: "fixed",
    zIndex: theme.zIndex.modal,
  }),
  layout.flexCenter,
);

export const ModalContainer = styled.div(
  ({ theme }) => ({
    backgroundColor: theme.colors.backgroundPrimary,
    position: "absolute",
    overflow: "hidden",
    width: "100%",
    maxHeight: "80vh",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    bottom: 0,

    [theme.media.tablet]: {
      width: theme.sizes.modalWidth.large,
      // Max height will be the view port height with 80px of vertical margin outside the modal
      maxHeight: `calc(100vh - ${theme.spacing.base * 40}px)`,
      boxShadow: theme.shadows.base,
      borderRadius: 24,
      bottom: "unset",
    },
  }),
  layout.flexVertical,
  transition.modalAppear,
);

export const Body = styled.div(({ theme }) => ({
  overflow: "auto",

  padding: `0 ${theme.spacing.gutter}px ${theme.spacing.expanded}px`,

  [theme.media.tablet]: {
    padding: `0 ${theme.spacing.base * 20}px ${theme.spacing.base * 20}px`,
  },
}));

export const Overlay = styled(Dialog.Overlay)(
  ({ theme }) => ({
    backgroundColor: theme.colors.modalOverlay,
  }),
  layout.absoluteFill,
  transition.fade,
);

// ====================================
// Header Styles
// ====================================

type HeaderProps = {
  padRight: boolean;
  hasTitle: boolean;
};

export const Header = styled.div<HeaderProps>(({ theme, padRight, hasTitle }) => {
  const gutter = theme.spacing.gutter;

  const paddingRight = padRight ? gutter + closeButtonSize : gutter;
  const basePaddingBottom = theme.spacing.condensed;

  return {
    display: "flex",
    padding: `${gutter}px ${paddingRight}px ${basePaddingBottom}px ${gutter}px`,

    // Apply some additional top padding to the Body element if the header has a title
    [`& + ${Body}`]: {
      [theme.media.tablet]: {
        paddingTop: hasTitle ? theme.spacing[40] - basePaddingBottom : 0,
      },
    },
  };
}, layout.flexCenterHorizontal);

export const HeaderTitle = styled(Title)<TextProps & { tabIndex?: number }>({
  flex: "1 1 auto",
  textAlign: "center",
});

// ====================================
// Footer Styles
// ====================================

export interface FooterProps {
  shadow?: boolean;
  background?: boolean;
}

const footerBase = ({ theme, shadow = true, background = true }: ThemeType & FooterProps) => ({
  backgroundColor: background ? theme.colors.backgroundSecondary : "transparent",
  boxShadow: shadow ? theme.shadows.large : "none",
  padding: `${theme.spacing.standard}px ${theme.spacing.gutter}px ${theme.spacing.gutter}px`,
});

export const Footer = styled.div<FooterProps>(footerBase, layout.flexSpaceHorizontal);

export const FooterWithLargeButton = styled.div(footerBase, ({ theme }) => ({
  [theme.media.tablet]: {
    paddingLeft: theme.spacing.base * 20,
    paddingRight: theme.spacing.base * 20,
  },
}));
