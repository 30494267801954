import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Trans } from "react-i18next";

import useDemographics from "/b2c/query/useDemographics.b2c";
import { Link, Loading } from "/component/base";
import List from "/component/base/List";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import { useContactInfo, useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { date, formatText } from "/util";
import { reactPlugin } from "/util/appInsights.util";
import { isMobileOrTabletDevice } from "/util/device.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import {
  AddressesSubtitle,
  AddressText,
  InfoListItem,
  ListContainer,
  PersonalInformationSubtitle,
} from "./PersonalInformationB2C.styles";
import { buildAddressValue } from "./PersonalInformationB2C.utils";

const PersonalInformation = () => {
  const { data, loading } = useDemographics();
  const isMobileOrTablet = isMobileOrTabletDevice();
  const { t } = useTranslation("personalInformation");
  const { recordAnalyticsEvent } = useAnalytics();
  const patient = data?.patient?.demographics;
  const { contactByName } = useContactInfo();
  const callCenterPhone = contactByName("summitCallCenter")?.phoneNumber;

  useEffect(() => {
    recordAnalyticsEvent("viewProfile");
  }, [recordAnalyticsEvent]);

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_ACCOUNT,
      source: AnalyticsSource.PERSONAL_INFO,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  if (loading) {
    return (
      <MainContent parentPage="account" pageTitle={t("title")}>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>

        <Loading />
      </MainContent>
    );
  }

  if (patient) {
    const birthDate = date.removeOffset(patient.dateOfBirth);
    const details = [
      { label: t("firstName"), value: patient?.firstName },
      { label: t("lastName"), value: patient?.lastName },
      {
        label: t("dateOfBirth"),
        value: birthDate && date.formatDate(birthDate, "MMMM d, yyyy"),
      },
    ];

    // Only displaying the user's home address for now. This could be
    // updated in the future to show multiple user addresses.
    const addresses = [{ label: t("home") }].map(({ label }) => ({
      label,
      stringValue: buildAddressValue(patient),
    }));

    return (
      <MainContent parentPage="account" pageTitle={t("title")}>
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>

        <PersonalInformationSubtitle color="textSecondary">
          <Trans
            components={{
              anchor: isMobileOrTablet ? (
                <Link.Anchor
                  href={`tel:${callCenterPhone}`}
                  variant="secondary"
                  onClick={() => {
                    logButtonClickEvent("Call center");
                  }}
                />
              ) : (
                <Text variant="body1Bold" />
              ),
            }}
            i18nKey="subtitle"
            t={t}
            tOptions={{ context: callCenterPhone ? "hasNumber" : undefined }}
            values={{ phoneNumber: formatText.parsePhone(callCenterPhone) }}
          />
        </PersonalInformationSubtitle>
        <ListContainer>
          <List
            as="Divs"
            data={details}
            showChevron={false}
            hideLastChildBorder
            renderItem={({ label, value }) => (
              <InfoListItem>
                <Text>{label}</Text>
                <Text>{value}</Text>
              </InfoListItem>
            )}
          />
        </ListContainer>
        <AddressesSubtitle variant="body1Bold" color="textSecondary">
          {t("addresses")}
        </AddressesSubtitle>
        <ListContainer>
          <List
            as="Divs"
            data={addresses}
            showChevron={false}
            hideLastChildBorder
            renderItem={({ label, stringValue }) => (
              <InfoListItem css={layout.margin("skip", "condensed", "skip", "skip")}>
                <Text>{label}</Text>
                <AddressText>{stringValue}</AddressText>
              </InfoListItem>
            )}
          />
        </ListContainer>
      </MainContent>
    );
  }

  // TODO: Handle errors
  return <Text>{t("personalInfoError")}</Text>;
};

export default withAITracking(reactPlugin, PersonalInformation, undefined, "ai-tracking");
