import { GET_DEMOGRAPHICS_patient_demographics } from "/b2c/schema";

export const buildAddressValue = (address: GET_DEMOGRAPHICS_patient_demographics | null) => {
  if (address) {
    const line1 = [address.address1, address.address2].filter((el) => el).join(", ");
    const line2 = [address.city, address.state, address.zipCode].filter((el) => el).join(", ");
    return `${line1}\n${line2}`;
  } else {
    return null;
  }
};
