import React, { FC, PropsWithChildren, useCallback, useMemo } from "react";

import { useAuth } from "/hook";
import { Countly } from "/util/countly";

import { AnalyticsContext, AnalyticsContextProps } from "./AnalyticsContext";

const AnalyticsProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const recordAnalyticsEvent: AnalyticsContextProps["recordAnalyticsEvent"] = useCallback(
    (key, segments) => {
      Countly.addEvent({
        key,
        segmentation: {
          auth: isAuthenticated,
          sharedModule: true,
          ...segments,
        },
      });
    },
    [isAuthenticated],
  );

  const contextValue = useMemo(() => ({ recordAnalyticsEvent }), [recordAnalyticsEvent]);

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};

export { AnalyticsProvider };
