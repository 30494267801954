import { gql } from "@apollo/client";

import { B2CQueryResult, useB2CQuery } from "../client";
import { filterKnownErrors } from "../client/utils";
import { getAppointment, getAppointmentVariables } from "../schema/types";

export const AppointmentQuery = gql`
  query getAppointment($appointmentId: Int!) {
    patient {
      appointment(appointmentId: $appointmentId) {
        appointmentId
        appointmentStatus
        visitType
        onlineCheckInEligibility {
          isEligible
        }
        patientAppointmentTypeName
        startDateTime
        endDateTime
        provider {
          providerId
          npi
          firstName
          lastName
          displayName
          specialty
          imageUrl
        }
        departmentId
        department {
          name
          address
          address2
          city
          state
          zipCode
          latitude
          longitude
        }
      }
    }
  }
`;

export const useAppointment = (
  appointmentId: getAppointmentVariables["appointmentId"],
): B2CQueryResult<getAppointment, getAppointmentVariables> => {
  const { refetch, ...queryResult } = useB2CQuery<getAppointment>(AppointmentQuery, {
    variables: { appointmentId },
    fetchPolicy: "network-only",
  });

  const refetchWrapper = () => {
    return refetch({});
  };

  return filterKnownErrors({
    ...queryResult,
    refetch: refetchWrapper,
  });
};
