import { useReactiveVar } from "@apollo/client";
import awsdk from "awsdk";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { freshlyInvitedCaregiver } from "/apollo/client/local";
import summitLogoSmall from "/asset/image/summit-logo-small.png";
import useLazyDemographics from "/b2c/query/useLazyDemographics.b2c";
import { Alert, Button, Loading, Text } from "/component/base";
import { Menu } from "/component/base/Icon";
import CaregiverOptInModal from "/component/modal/CaregiverOptInModal";
import MobileNavModal from "/component/modal/MobileNavModal";
import OnboardingModal from "/component/modal/OnboardingModal";
import UpdateUserPasswordModal from "/component/modal/UpdateUserPasswordModal";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import { SignUpFormType } from "/constant/signUp.constant";
import routes from "/constant/url.constant";
import { useAmwell, useAppInit, useGoogleMaps, useTranslation, useViewportSize } from "/hook";
import useBookingReroute from "/hook/useBookingReroute";
import { layout } from "/styles";
import { screenSizes } from "/theme/default/breakpoints";
import { initializeAddress } from "/util/address.util";

import DemographicModal from "../../modal/DemographicModal";
import { Base, DesktopNavMenu, MobileNavHeader } from "./MainLayout.styles";
import { Props } from "./MainLayout.types";
import { useUserAccount } from "./useUserAccount";

const SIGN_UP_V2_ENABLED = process.env.SIGN_UP_V2_ENABLED === "true";
const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";

const LOGO_SIZE = 30;

const MainLayout = ({ children }: Props) => {
  const [navVisible, setNavVisible] = useState(false);
  const googleMaps = useGoogleMaps();
  const [showConfirmPasswordReset, setShowConfirmPasswordReset] = useState(false);
  const [activeToken, setToken] = useState("");
  const [demographicModal, setDemographicModal] = useState(false);
  const [caregiverOptInModal, setCaregiverOptInModal] = useState(false);
  const { inviteID, freshInvite } = useReactiveVar(freshlyInvitedCaregiver.var);
  const [facilityId, setFacilityId] = useState("");

  const { login, isAuthenticated, logout, isReady, loadPatientFailed, setLoadPatientFailed } =
    useAppInit();

  const [getPatientData] = useLazyDemographics();

  const { refetch } = useUserAccount();
  const { sdk, consumer, setActiveVisit } = useAmwell();
  const { t } = useTranslation("layout-MainLayout");
  const { width } = useViewportSize();
  const history = useHistory();
  const { caregiverMode } = useCaregiverProvider();
  const location = useLocation<{ promptCaregiver: boolean }>();
  const showMobileNav = !!width && screenSizes.tabletPortrait > width;

  useEffect(() => {
    if (!isReady) return;

    if (inviteID && ENABLE_CAREGIVER_PROXY) {
      if (isAuthenticated) {
        freshlyInvitedCaregiver.update({ inviteID: undefined });
        history.push(routes.caregiver, {
          confirmAccessId: inviteID,
          newlyCreatedAccount: freshInvite,
        });
      } else {
        if (freshInvite) history.push(routes.signUp);
        else {
          freshlyInvitedCaregiver.update({ inviteID: undefined, freshInvite: false });
          login(`${window.location.origin}${routes.confirmCaregiver}/${inviteID}`);
        }
      }
    }
  }, [isReady, isAuthenticated]);

  useEffect(() => {
    const showPasswordReset = !!location.pathname.match(/\/passwordReset?/);
    const { token, departmentId } = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
    const departID = departmentId as string;
    setShowConfirmPasswordReset(showPasswordReset);

    if (showPasswordReset) {
      setToken(token as string);
      history.replace("/passwordReset");
    } else if (departID) {
      setFacilityId(departID);
    } else if (ENABLE_CAREGIVER_PROXY && location.state?.promptCaregiver) {
      setCaregiverOptInModal(true);
    }
  }, []);

  const checkActiveTelehealthVisit = async () => {
    if (sdk?.initialized && consumer && isReady && isAuthenticated) {
      try {
        const activeVisit = await sdk.visitService.findActiveVisit(consumer);
        if (activeVisit) {
          setActiveVisit(activeVisit as awsdk.AWSDKVisit);
          history.push(routes.telehealthWaitingRoom);
        }
      } catch (e: unknown) {
        setActiveVisit(undefined);
        console.warn(e);
      }
    }
  };

  const handleCloseNav = () => {
    setNavVisible(false);
  };

  useEffect(() => {
    checkActiveTelehealthVisit();
  }, [isAuthenticated, isReady, consumer]);

  const checkSignupStatus = React.useCallback(async () => {
    const { data: accountData } = await refetch();
    try {
      const { data: patientData } = await getPatientData();

      const patient = patientData?.patient;
      const phoneVerificationStatus = accountData?.viewer?.user?.account?.phoneVerificationStatus;
      googleMaps && patient && (await initializeAddress(patient.demographics, googleMaps));

      if (SIGN_UP_V2_ENABLED) {
        if (
          phoneVerificationStatus &&
          ["notVerified", "pending"].indexOf(phoneVerificationStatus) >= 0
        ) {
          history.push(`${routes.demographics}?form=${SignUpFormType.PhoneVerification}`);
        } else if (!patient) {
          setDemographicModal(true);
        }
      }
    } catch {
      setLoadPatientFailed(true);
    }
  }, [getPatientData, refetch, googleMaps]);

  useEffect(() => {
    if (isAuthenticated && isReady && !caregiverMode) {
      checkSignupStatus();
    }
  }, [isAuthenticated, isReady, checkSignupStatus]);

  useBookingReroute(isReady);

  return (
    <Base>
      <MobileNavHeader>
        <Link to={routes.root}>
          <img height={LOGO_SIZE} width={LOGO_SIZE} alt={t("logo")} src={summitLogoSmall} />
        </Link>

        <Button
          css={[layout.spacedChildrenHorizontal("condensed"), layout.flexCenterHorizontal]}
          variant="unstyled"
          onClick={() => setNavVisible(true)}
        >
          <Text color="textSecondary" variant="body2Bold">
            {t("menu")}
          </Text>

          <Menu size={32} />
        </Button>
      </MobileNavHeader>

      {showMobileNav && (
        <MobileNavModal
          close={handleCloseNav}
          isAuthenticated={isAuthenticated}
          isOpen={navVisible}
          logout={logout}
        />
      )}

      <DesktopNavMenu isAuthenticated={isAuthenticated} logout={logout} />

      {!facilityId && !showConfirmPasswordReset && isReady && !isAuthenticated && (
        <OnboardingModal />
      )}

      {showConfirmPasswordReset && isReady && !isAuthenticated && (
        <UpdateUserPasswordModal
          token={activeToken}
          close={() => {
            history.replace("/home");
          }}
        />
      )}

      <Alert
        close={() => setLoadPatientFailed(false)}
        footer={
          <Button
            fullWidth="flex"
            onClick={() => {
              logout();
              setLoadPatientFailed(false);
            }}
          >
            {t("dialog.errorDialog.signOutLabel")}
          </Button>
        }
        title={t("dialog.errorDialog.title")}
        isOpen={loadPatientFailed}
      >
        {t("dialog.errorDialog.subtitle")}
      </Alert>

      {isReady ? children : <Loading />}

      <DemographicModal isOpen={demographicModal} close={() => setDemographicModal(false)} />

      <CaregiverOptInModal
        isOpen={caregiverOptInModal}
        close={() => setCaregiverOptInModal(false)}
        addCaregiver={() => {
          setCaregiverOptInModal(false);
          history.push(routes.caregiver);
        }}
      />
    </Base>
  );
};

export default MainLayout;
