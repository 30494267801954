import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";

import noResultsFound from "/asset/image/noResultsFound.png";
import { ErrorCode, getErrorCode } from "/b2c/client/utils";
import useMedications from "/b2c/query/useMedications.b2c";
import usePharmacy from "/b2c/query/usePharmacy.b2c";
import {
  GET_MEDICATIONS_patient_activeMedications,
  GET_MEDICATIONS_patient_historicalMedications,
  MedicationType,
} from "/b2c/schema/types";
import { Button, List, ListItem, Text } from "/component/base";
import * as Icons from "/component/base/Icon";
import { AlertCircleOpen } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import LoadingSkeleton from "/component/partial/LoadingSkeleton";
import SegmentedControl from "/component/partial/SegmentedControl";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { card, layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import MedicationListItem from "./components";
import {
  DocsWrapper,
  ListContainer,
  NoPharmacyCardWrapper,
  SegmentedControlWrapper,
} from "./MedicationsB2C.styles";

const IMAGE_HEIGHT = 261;
const IMAGE_WIDTH = 183;

const Medications = () => {
  const { t } = useTranslation("medications");
  const history = useHistory();
  const { recordAnalyticsEvent } = useAnalytics();

  useEffect(() => {
    recordAnalyticsEvent("viewMedications");
  }, [recordAnalyticsEvent]);

  const {
    data: medications,
    error: medicationsError,
    loading: medicationsLoading,
  } = useMedications();
  const { data: pharmacyData, error: pharmacyError, loading: pharmacyLoading } = usePharmacy();

  const activeMedications = medications?.activeMedications || [];
  const historicalMedications = medications?.historicalMedications || [];

  const [medicationType, setMedicationType] = useState<MedicationType | undefined>(
    MedicationType.ACTIVE,
  );
  const showEmptyState = activeMedications?.length === 0 && historicalMedications?.length === 0;

  const isWalgreens = pharmacyData?.clinicalProviderName?.toLowerCase().includes("walgreens");

  const menuItems = [
    {
      value: MedicationType.ACTIVE,
      label: t("segmentedControl.active"),
    },
    {
      value: MedicationType.HISTORICAL,
      label: t("segmentedControl.inactive"),
    },
  ];

  const handleItemSelected = (type: MedicationType | undefined) => {
    if (type === MedicationType.HISTORICAL) {
      recordAnalyticsEvent("medicationsInactive", {
        noData: historicalMedications.length === 0,
      });
    }
    setMedicationType(type);
  };

  const handlePressMedication = (
    medication:
      | GET_MEDICATIONS_patient_activeMedications
      | GET_MEDICATIONS_patient_historicalMedications,
  ) => {
    history.push(`${routes.medications}/${medication.medicationId}`, { medication });
  };

  const handleChangePreferredPharmacy = () => {
    history.push(routes.preferredPharmacy, { pharmacy: pharmacyData });
  };

  const handleAddPharmacy = () => {
    history.push(routes.pharmacySearch);
  };

  const getPharmacyIcon = () => {
    if (isWalgreens) {
      return (
        <Icons.Walgreens color="brandSecondary" size={48} background={{ color: "transparent" }} />
      );
    }
    return (
      <Icons.PreferredPharmacy
        color="brandSecondary"
        size={32}
        background={{ color: "transparent" }}
      />
    );
  };

  const getPharmacyContent = () => {
    if (pharmacyData) {
      return (
        <ListItem.Button
          css={[card.base, layout.padding("standard")]}
          showChevron={false}
          onClick={handleChangePreferredPharmacy}
        >
          <div css={[layout.flexCenterHorizontal, layout.spacedChildrenHorizontal("standard")]}>
            <div>{getPharmacyIcon()}</div>
            <div css={[layout.flexVertical, layout.spacedChildrenVertical("condensed")]}>
              <Text>{t("myPharmacy")}</Text>
              <Text variant="body1Bold">{pharmacyData?.clinicalProviderName}</Text>
              <Text color="textSecondary">
                {[pharmacyData?.address, pharmacyData?.city, pharmacyData?.state]
                  .filter(Boolean)
                  .join(", ")}
              </Text>
            </div>
          </div>
        </ListItem.Button>
      );
    }
    return (
      <NoPharmacyCardWrapper>
        <div css={[layout.flexVerticalCenter, layout.spacedChildrenVertical()]}>
          <Text variant="title3">{t("noPharmacy.title")}</Text>
          <Text color="textSecondary">{t("noPharmacy.subtitle")}</Text>

          <Button css={{ width: 260 }} variant="secondary" onClick={handleAddPharmacy}>
            {t("noPharmacy.addPharmacy")}
          </Button>
        </div>
      </NoPharmacyCardWrapper>
    );
  };

  if (medicationsLoading || pharmacyLoading)
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <LoadingSkeleton skeletonVariant="list-item" />
      </MainContent>
    );

  if (
    (medicationsError &&
      getErrorCode(medicationsError, medications) !== ErrorCode.PARTIAL_RESPONSE) ||
    (pharmacyError && getErrorCode(pharmacyError, pharmacyData) !== ErrorCode.PARTIAL_RESPONSE)
  ) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(80)}>
            <EmptyStateCta
              icon={<AlertCircleOpen color="brandPrimary" size={48} />}
              title={t("error.fetchError.title")}
              subtitle={t("error.fetchError.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  if (showEmptyState) {
    return (
      <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
        <Helmet>
          <title>{t("pageTitle")}</title>
        </Helmet>
        {getPharmacyContent()}
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(60)}>
            <EmptyStateCta
              imageHeight={IMAGE_HEIGHT}
              imageWidth={IMAGE_WIDTH}
              imageSource={noResultsFound}
              title={t("emptyState.title")}
              subtitle={t("emptyState.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  return (
    <MainContent pageTitle={t("pageTitle")} parentPage="myHealth">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      {getPharmacyContent()}
      <SegmentedControlWrapper>
        <SegmentedControl
          css={layout.margin("expanded", "none", "none", "none")}
          onItemSelected={handleItemSelected}
          options={menuItems}
          value={medicationType}
        />
      </SegmentedControlWrapper>
      <DocsWrapper>
        <ListContainer>
          <List
            as="Buttons"
            chevronVariant="grey"
            hideLastChildBorder
            onClick={handlePressMedication}
            data={
              medicationType === MedicationType.ACTIVE ? activeMedications : historicalMedications
            }
            renderItem={(medication) => (
              <MedicationListItem
                medication={{
                  medicationName: medication.medicationName || "",
                  datePrescribed: medication.datePrescribed,
                }}
              />
            )}
          />
        </ListContainer>
      </DocsWrapper>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Medications, undefined, "ai-tracking");
