import {
  addDays,
  addMinutes,
  addYears as dateFnsAddYears,
  differenceInDays,
  format,
  parse,
  parseISO,
  subYears,
} from "date-fns";
import { enUS } from "date-fns/locale";
import { format as formatTz, toDate, utcToZonedTime } from "date-fns-tz";
import i18n from "i18next";
import isDate from "lodash/fp/isDate";
import isEmpty from "lodash/fp/isEmpty";

import { DayOfWeek } from "/apollo/schema";

export type DateLike = Date | string;

export const dayOfWeekMap = Object.freeze({
  [DayOfWeek.SUNDAY]: 0,
  [DayOfWeek.MONDAY]: 1,
  [DayOfWeek.TUESDAY]: 2,
  [DayOfWeek.WEDNESDAY]: 3,
  [DayOfWeek.THURSDAY]: 4,
  [DayOfWeek.FRIDAY]: 5,
  [DayOfWeek.SATURDAY]: 6,
});

/**
 * Given a start and end Date return the times as well as the duration between them.
 *
 * Example response would be: 10:30 AM - 11:00 AM (30 mins)
 *
 * Optionally provide the `formatString` to customize the date format. Defaults to `hh:mm A`
 */
export const formatTimesWithDiff = (
  start: Date,
  end: Date,
  /**
   * Optionally provide the string you'd like to use to format the dates
   * Default hh:mm A (10:30 AM)
   */
  formatString = "hh:mm a",
) => {
  const startTime = format(start, formatString);
  const endTime = format(end, formatString);
  const { hours, minutes } = getDuration(start, end);
  const diff = hours * 60 + minutes;
  const minsText = diff !== 1 ? "mins" : "min";

  return `${startTime} - ${endTime} (${diff} ${minsText})`;
};

export const formatDate = (date: DateLike, formatStr: string): string | null => {
  const formatOpts = { locale: enUS };
  if (!date) return null;
  if (isDate(date)) {
    return format(date, formatStr, formatOpts);
  } else if (!isEmpty(date)) {
    return format(parseISO(date), formatStr, formatOpts);
  } else {
    return null;
  }
};

export const getDate = (date: DateLike): string | null => {
  if (isEmpty(date)) {
    return null;
  }
  const formatOpts = { locale: enUS };
  const dateParsed = isDate(date) ? date : parseISO(date);

  return format(addMinutes(dateParsed, dateParsed.getTimezoneOffset()), "LLL dd yyyy", formatOpts);
};

export const translateWeekday = (dayOfWeek: DayOfWeek, format = "EEEE"): string | null => {
  const outDate = new Date();
  const dayToSet = dayOfWeekMap[dayOfWeek];
  const currentDay = outDate.getDay();
  const distance = dayToSet - currentDay;

  outDate.setDate(outDate.getDate() + distance);
  return formatDate(outDate, format);
};

/**
 * toTimeAgo returns a formatted date string, the specific formatting of which
 * depends on how long ago the given date was from today.
 */
export const toTimeAgo = (date: DateLike) => {
  const dateTime = isDate(date) ? date.getTime() : parseISO(date).getTime();

  switch (differenceInDays(Date.now(), dateTime)) {
    case 0:
      return formatDate(date, "h:mm a");
    case 1:
      return i18n.t("dateUtil-yesterday");
    default:
      return formatDate(date, "MMM d, yyyy");
  }
};

/**
 * Given a start and end date, this function returns an object that details the
 * elapsed number of days, hours, and minutes.
 */
export const getDuration = (
  startDate: Date,
  endDate: Date,
): {
  days: number;
  hours: number;
  minutes: number;
} => {
  const seconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return {
    days,
    hours: hours - days * 24,
    minutes: minutes - hours * 60,
  };
};

const extractOffset = (dateTimeString: string) => {
  const matches = dateTimeString.match(/T.*([-+]\d\d:\d\d|[-+]\d\d\d\d|Z)$/);
  if (matches) {
    return matches[1] === "Z" ? "-00:00" : matches[1];
  }
  return null;
};

export const removeOffset = (dateTimeString: string | null) => {
  if (!dateTimeString) return null;

  if (dateTimeString.indexOf("T")) {
    return dateTimeString.split("T")[0];
  }

  return dateTimeString;
};

/**
 * Takes a iso datetime string and formats it's date relative to the timezone/offset in the string.
 * If the datetime string does not contain a timezone/offset, then it is assumed to be in
 * local time. The easiest way of thinking about this is that the date will be formatted
 * in a way that reflects the datetime string that you pass in, regardless of your current
 * timezone, which is something that the `format` function from `date-fns` struggles with.
 * Currently not supported is the format value of type `z` or `Z`, that is, printing the
 * timezone name (e.g. PST, Pacific Standard Time).
 * Examples:
 * - formatISODateTimeString("2021-02-01T07:00:00.00+04:00", "yyyy-MM-dd HH:mm") => "2021-02-01 07:00:00"
 * - formatISODateTimeString("2021-02-01T07:00:00.00Z", "yyyy-MM-dd HH:mm") => "2021-02-01 07:00:00"
 * - formatISODateTimeString("2021-02-01T07:00:00.00", "yyyy-MM-dd HH:mm") => "2021-02-01 07:00:00"
 * - formatISODateTimeString("2021-02-01", "yyyy-MM-dd HH:mm") => "2021-02-01 00:00:00"
 */
export const formatISODateTimeString = (dateTimeString: string, formatString: string): string => {
  if (parseISO(dateTimeString).toString() === "Invalid Date") {
    throw new Error(`Invalid ISO Date String: ${dateTimeString}`);
  }
  // This issue explains the logic here https://github.com/marnusw/date-fns-tz/issues/40
  const offset = extractOffset(dateTimeString);
  if (offset) {
    return formatTz(utcToZonedTime(dateTimeString, offset), formatString, {
      timeZone: offset,
    });
  } else {
    return formatTz(toDate(dateTimeString), formatString);
  }
};

export const isDateToday = (date: Date): boolean => {
  const today = new Date();
  return (
    today.getDate() === date.getDate() &&
    today.getMonth() === date.getMonth() &&
    today.getFullYear() === date.getFullYear()
  );
};

export const minAgeTest = (dob: string | null | undefined): boolean => {
  if (dob) {
    return new Date(dob) < subYears(new Date(), 18);
  }
  return false;
};

export const maxMinorAgeTest = (dob: string | null | undefined): boolean => {
  if (dob) {
    return new Date(dob) >= subYears(new Date(), 18);
  }
  return false;
};

export const remainingDaysString = (date: DateLike) => {
  const dateTime = isDate(date) ? date.getTime() : parseISO(date).getTime();
  const today = new Date().setHours(0, 0, 0, 0);
  const numOfDays = differenceInDays(dateTime, today);
  switch (numOfDays) {
    case 0:
      return i18n.t("dateUtil-today");
    case 1:
      return i18n.t("dateUtil-tomorrow");
    default:
      break;
  }
  return i18n.t("dateUtil-remainingDays", { numOfDays });
};

export const daysUntilString = (date: DateLike) => {
  if (!date) return;
  const dateTime = isDate(date) ? date.getTime() : parseISO(date).getTime();
  const today = new Date().setHours(0, 0, 0, 0);
  const numOfDays = differenceInDays(dateTime, today);
  switch (numOfDays) {
    case 0:
      return i18n.t("dateUtil-availableToday");
    case 1:
      return i18n.t("dateUtil-availableTomorrow");
    default:
      break;
  }
  return i18n.t("dateUtil-available", { numOfDays });
};

export const ordinalDate = (date: number): string => {
  if (date > 3 && date < 21) return "th";
  switch (date % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const timeConversion = (time_24: string | undefined) => {
  if (time_24) {
    return format(parse(time_24, "HH:mm:ss", new Date()), "hh:mm a");
  }
  return null;
};

export const addYears = (dateTimeString: string, years: number): Date => {
  return addDays(dateFnsAddYears(new Date(dateTimeString), years), 1);
};

// convert a time string (8:00AM or 08:00) into today's date that can be formatted
export const parseDateTime = (time: string) => {
  const result = new Date();

  let hours;
  let minutes;
  if (time.includes("AM")) {
    hours = parseInt(time.slice(0, -2).split(":")[0], 10);
    minutes = parseInt(time.slice(0, -2).split(":")[1], 10);
  } else if (time.includes("PM")) {
    hours = parseInt(time.slice(0, -2).split(":")[0], 10) + 12;
    minutes = parseInt(time.slice(0, -2).split(":")[1], 10);
  }
  // hours displayed in miltary time
  else {
    hours = parseInt(time.split(":")[0], 10);
    minutes = parseInt(time.split(":")[1], 10);
  }
  result.setHours(hours);
  result.setMinutes(minutes);
  result.setSeconds(0);

  return result;
};
