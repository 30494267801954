// Modules
import {
  ApolloClient as BaseApolloClient,
  createHttpLink,
  DefaultContext,
  DocumentNode,
  from,
  InMemoryCache,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  MutationHookOptions,
  MutationTuple,
  NormalizedCacheObject,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { auth } from "../../apollo/client/local";

const httpLink = createHttpLink({
  uri: `${process.env.SERVICE_LAYER}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const errors = [] as string[];

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations: _locations, path: _path }) =>
      errors.push(`[GraphQL error]: ${message}`),
    );

  if (networkError) errors.push(`[Network error]: ${networkError}`);

  console.log(errors.join("\n\n"));
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${auth.read().token}`,
    },
  };
});
export const createB2CClient = (): BaseApolloClient<NormalizedCacheObject> => {
  const client = new BaseApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, authLink, httpLink]),
    connectToDevTools: process.env.NODE_ENV === "development",
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
      },
      query: {
        errorPolicy: "all",
      },
    },
  });

  return client;
};

const client = createB2CClient();

export default client;
export type ApolloClient = typeof client;

// Modify the query to automatically use the new client as the parameter
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useB2CQuery: <TData, TVariables extends OperationVariables = any>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Omit<QueryHookOptions<TData, TVariables>, "client">,
) => QueryResult<TData, TVariables> = (query, options) =>
  useQuery(query, {
    client,
    ...options,
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useB2CLazyQuery: <TData, TVariables extends OperationVariables = any>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Omit<LazyQueryHookOptions<TData, TVariables>, "client">,
) => LazyQueryResultTuple<TData, TVariables> = (query, options) =>
  useLazyQuery(query, {
    client,
    ...options,
  });

//Modify the mutation to automatically use the new client as the parameter
export const useB2CMutation: <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TContext = DefaultContext,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Omit<MutationHookOptions<TData, TVariables, TContext>, "client">,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => MutationTuple<TData, TVariables, TContext, any> = (mutation, options) =>
  useMutation(mutation, {
    client,
    ...options,
  });

export interface B2CError {
  extensions?: {
    status_code?: number;
  };
  path?: (number | string)[];
}

export interface B2CQueryResult<TData, TVariables extends OperationVariables>
  extends QueryResult<TData, TVariables> {
  errors?: B2CError[];
}
