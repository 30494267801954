import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { useCancelAppointment } from "/b2c/mutation/useCancelAppointment.b2c";
import { useAppointment } from "/b2c/query/appointment.b2c";
import { getAppointmentTelehealthLink_patient_appointmentTelehealthLink } from "/b2c/schema/types";
import { Alert, Button, InlineMessage, Loading } from "/component/base";
import { AlertCircle } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import AppointmentDetails from "/component/partial/AppointmentDetails";
import { useToastContext } from "/component/provider/ToastProvider";
import { useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { CancelButton } from "./Appointment.styles";

const Appointment = ({ match, location }: RouteComponentProps<{ appointmentId?: string }>) => {
  const { t } = useTranslation("appointment");

  const { showToast } = useToastContext();
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const appointmentId = match.params.appointmentId as string;
  const appointmentTelehealthInfo =
    location.state as getAppointmentTelehealthLink_patient_appointmentTelehealthLink;

  const { loading, errors, data } = useAppointment(Number(appointmentId));
  const [cancelAppointment, { loading: cancelLoading }] = useCancelAppointment({
    variables: { appointmentId: Number(appointmentId) },
  });

  const appointment = data?.patient.appointment;

  // If there's no data, show an error
  const hasError = !loading && ((errors && errors.length > 0) || !appointment);

  const handleCancelClick = () => {
    setShowCancelConfirmation(true);
  };

  const handleCancelConfirm = async () => {
    logModalButtonClickEvent("Yes, cancel appointment");
    try {
      const res = await cancelAppointment();
      // Check if there are GQL errors and throw an error so that we can show the toast in the `catch` block
      if (res.errors && res.errors.length > 0) {
        throw new Error();
      }
      showToast({ message: t("cancellationSuccessful") });
    } catch (e: any) {
      showToast({ message: t("cancellationError"), type: "error" });
    }

    setShowCancelConfirmation(false);
  };

  const logModalButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.APPOINTMENT_DETAILS,
      source: AnalyticsSource.MODAL_CANCEL_APPOINTMENT,
      button_name: buttonName,
      appointment_id: appointment?.appointmentId.toString(),
    } as ButtonClickParams);
  };

  return (
    <MainContent pageTitle={t("title")} parentPage="appointments" renderAccessoryBelowTitleOnMobile>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      {!!loading && <Loading />}
      {hasError && (
        <InlineMessage icon={<AlertCircle />} variant="important">
          {t("error")}
        </InlineMessage>
      )}
      {!loading && !(errors && errors.length > 0) && appointment && (
        <AppointmentDetails
          appointment={appointment}
          appointmentTelehealthInfo={appointmentTelehealthInfo}
          onCancelClick={handleCancelClick}
        />
      )}

      <Alert
        isOpen={showCancelConfirmation}
        title={t("cancelConfirmation.title")}
        close={() => setShowCancelConfirmation(false)}
        footer={
          <>
            <Button onClick={() => handleCancelConfirm()} isLoading={cancelLoading}>
              {t("cancelConfirmation.confirm")}
            </Button>
            <CancelButton
              variant="borderBottom"
              onClick={() => {
                logModalButtonClickEvent("Nevermind");
                setShowCancelConfirmation(false);
              }}
              isDisabled={cancelLoading}
            >
              {t("cancelConfirmation.cancel")}
            </CancelButton>
          </>
        }
      />
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Appointment, undefined, "ai-tracking");
