import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { RouteComponentProps } from "react-router";

import { Button, Text } from "/component/base";
import { BandageFilled, ShieldCheckFilled, VideoColorFilled } from "/component/base/Icon";
import MainContent from "/component/layout/MainContent";
import routes from "/constant/url.constant";
import { useTranslation } from "/hook";
import { useAmwellInsurance } from "/hook/useAmwellInsurance/useAmwellInsurance";
import { useAnalytics } from "/hook/useAnalytics/useAnalytics";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import {
  AcceptedInsurances,
  BandageFilledContainer,
  ButtonContainer,
  CommonConditionsSubtitle,
  CommonDisclaimer,
  ContentContainer,
  Description,
  HeaderIconContainer,
  InsuranceDisclaimer,
  Title,
} from "./TelehealthIntro.styles";

const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";

const TelehealthIntro = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation("telehealth");
  const { data } = useAmwellInsurance();
  const { recordAnalyticsEvent } = useAnalytics();

  useEffect(() => {
    recordAnalyticsEvent("viewAmwellIntro");
  }, [recordAnalyticsEvent]);

  const handleContinue = () => {
    if (ENABLE_CAREGIVER_PROXY) history.push(routes.telehealthPatientSelection);
    else history.push(routes.telehealthProviders);
  };

  return (
    <MainContent parentPage={"telehealth"}>
      <Helmet>
        <title>{t("intro.helmetTitle")}</title>
      </Helmet>

      <HeaderIconContainer>
        <VideoColorFilled css={{ alignSelf: "center" }} size={60} color="textTitle" />
      </HeaderIconContainer>

      <Title>{t("intro.title")}</Title>

      <Text variant="body1">{t("intro.subtitle")}</Text>

      <ContentContainer>
        <CommonDisclaimer>
          <BandageFilledContainer>
            <BandageFilled background={{ color: "backgroundSecondary" }} color="textNavyBlue" />
          </BandageFilledContainer>
          <Description>
            <Text variant="body1Bold">{t("intro.commonConditions.title")}</Text>
            <CommonConditionsSubtitle>
              <Text css={{ flex: 0.8 }} color="textSecondary" variant="body2">
                {t("intro.commonConditions.subtitle")}
              </Text>
            </CommonConditionsSubtitle>
          </Description>
        </CommonDisclaimer>

        <InsuranceDisclaimer>
          <ShieldCheckFilled background={{ color: "backgroundSecondary" }} color="textNavyBlue" />
          <Description>
            <Text variant="body1Bold">{t("intro.insurance.title")}</Text>
            <Text color="textSecondary" variant="body2">
              {t("intro.insurance.subtitle")}
            </Text>
          </Description>
        </InsuranceDisclaimer>
      </ContentContainer>

      <AcceptedInsurances>
        <Text color="textSecondary" variant="body2Bold">
          {t("intro.acceptedInsurances")}
        </Text>
        <Text css={layout.marginVertical("condensed")} variant="body1">
          {data?.healthPlans.map((healthPlan) => healthPlan.name).join(", ") || ""}
        </Text>
      </AcceptedInsurances>

      <ButtonContainer>
        <Button onClick={handleContinue} variant="primary">
          {t("intro.continue")}
        </Button>
      </ButtonContainer>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, TelehealthIntro, undefined, "ai-tracking");
