import React from "react";
// Import all of the feather icons that our app needs.
// Note: If you need a new icon, import it here and add an export
// down below
import {
  AlertCircle as alertCircleOpen,
  ArrowLeft as arrowLeft,
  Bell as bell,
  Briefcase as briefcase,
  CheckCircle as checkCircle,
  CornerUpLeft as cornerUpLeft,
  CornerUpRight as cornerUpRight,
  CreditCard as creditCard,
  Edit as edit,
  Eye as eye,
  EyeOff as eyeOff,
  File as file,
  FilePlus as filePlus,
  FileText as fileText,
  Globe as globe,
  HelpCircle as helpCircle,
  Home as home,
  Link as link,
  Lock as lock,
  LogOut as logout,
  Mail as mail,
  Menu as menu,
  Phone as phone,
  Search as search,
  Send as send,
  Settings as settings,
  Sliders as sliders,
  Star as star,
  Trash2 as trash,
} from "react-feather";

// Import custom icons stored in the repo as SVGs
import { ReactComponent as alert } from "/asset/svg/alert.svg";
import { ReactComponent as alertCircle } from "/asset/svg/alert-circle.svg";
import { ReactComponent as arrowRightCircle } from "/asset/svg/arrow-right-circle.svg";
import { ReactComponent as arrowUpRight } from "/asset/svg/arrow-up-right.svg";
import { ReactComponent as availableSoon } from "/asset/svg/available-soon.svg";
import { ReactComponent as badge } from "/asset/svg/badge.svg";
import { ReactComponent as bandageFilled } from "/asset/svg/bandage-filled.svg";
import { ReactComponent as billPayment } from "/asset/svg/bill-payment.svg";
import { ReactComponent as calendar } from "/asset/svg/calendar.svg";
import { ReactComponent as caregiver } from "/asset/svg/caregiver.svg";
import { ReactComponent as cash } from "/asset/svg/cash.svg";
import { ReactComponent as certificate } from "/asset/svg/certificate.svg";
import { ReactComponent as check } from "/asset/svg/check.svg";
import { ReactComponent as checkmark } from "/asset/svg/checkmark.svg";
import { ReactComponent as chevronDown } from "/asset/svg/chevron-down.svg";
import { ReactComponent as chevronLeft } from "/asset/svg/chevron-left.svg";
import { ReactComponent as chevronRight } from "/asset/svg/chevron-right.svg";
import { ReactComponent as chevronUp } from "/asset/svg/chevron-up.svg";
import { ReactComponent as cityMD } from "/asset/svg/city-md.svg";
import { ReactComponent as clipboard } from "/asset/svg/clipboard.svg";
import { ReactComponent as clipboardFilled } from "/asset/svg/clipboard-filled.svg";
import { ReactComponent as clipboardHeart } from "/asset/svg/clipboard-heart.svg";
import { ReactComponent as clock } from "/asset/svg/clock.svg";
import { ReactComponent as clockFilled } from "/asset/svg/clock-filled.svg";
import { ReactComponent as close } from "/asset/svg/close.svg";
import { ReactComponent as cross } from "/asset/svg/cross.svg";
import { ReactComponent as crossFilled } from "/asset/svg/cross-filled.svg";
import { ReactComponent as distance } from "/asset/svg/distance.svg";
import { ReactComponent as document } from "/asset/svg/document.svg";
import { ReactComponent as faq } from "/asset/svg/faq.svg";
import { ReactComponent as heartFilled } from "/asset/svg/heart-filled.svg";
import { ReactComponent as helpCircleFilled } from "/asset/svg/help-circle-filled.svg";
import { ReactComponent as homeFilled } from "/asset/svg/home-filled.svg";
import { ReactComponent as houseArrow } from "/asset/svg/house-arrow.svg";
import { ReactComponent as imagingResult } from "/asset/svg/imaging-result.svg";
import { ReactComponent as info } from "/asset/svg/info.svg";
import { ReactComponent as insuranceCard } from "/asset/svg/insurance-card.svg";
import { ReactComponent as labResult } from "/asset/svg/lab-result.svg";
import { ReactComponent as live } from "/asset/svg/live.svg";
import { ReactComponent as locationFilled } from "/asset/svg/location-filled.svg";
import { ReactComponent as logo } from "/asset/svg/logo.svg";
import { ReactComponent as logoLocationFilled } from "/asset/svg/logo-location-filled.svg";
import { ReactComponent as mailFilled } from "/asset/svg/mail-filled.svg";
import { ReactComponent as mailOpen } from "/asset/svg/mail-open.svg";
import { ReactComponent as mapPin } from "/asset/svg/map-pin.svg";
import { ReactComponent as mapPinFilled } from "/asset/svg/map-pin-filled.svg";
import { ReactComponent as medication } from "/asset/svg/medication.svg";
import { ReactComponent as medicine } from "/asset/svg/medicine.svg";
import { ReactComponent as message } from "/asset/svg/message.svg";
import { ReactComponent as multiplePatients } from "/asset/svg/multiple-patients.svg";
import { ReactComponent as myAccount } from "/asset/svg/my-account.svg";
import { ReactComponent as myAccountComplete } from "/asset/svg/my-account-complete.svg";
import { ReactComponent as myAccountFilled } from "/asset/svg/my-account-filled.svg";
import { ReactComponent as myHealth } from "/asset/svg/my-health.svg";
import { ReactComponent as navigation } from "/asset/svg/navigation.svg";
import { ReactComponent as newHome } from "/asset/svg/new-home.svg";
import { ReactComponent as newMessage } from "/asset/svg/new-message.svg";
import { ReactComponent as newSettings } from "/asset/svg/new-settings.svg";
import { ReactComponent as notificationBell } from "/asset/svg/notification-bell.svg";
import { ReactComponent as pencil } from "/asset/svg/pencil.svg";
import { ReactComponent as person } from "/asset/svg/person.svg";
import { ReactComponent as personCheck } from "/asset/svg/person-check.svg";
import { ReactComponent as smartphone } from "/asset/svg/phone.svg";
import { ReactComponent as pill } from "/asset/svg/pill.svg";
import { ReactComponent as plusSquare } from "/asset/svg/plus.svg";
import { ReactComponent as plus } from "/asset/svg/plus-2.svg";
import { ReactComponent as preferredPharmacy } from "/asset/svg/preferred-pharmacy.svg";
import { ReactComponent as provider } from "/asset/svg/provider.svg";
import { ReactComponent as searchProvider } from "/asset/svg/search-provider.svg";
import { ReactComponent as shieldCheckFilled } from "/asset/svg/shield-check-filled.svg";
import { ReactComponent as singlePatient } from "/asset/svg/single-patient.svg";
import { ReactComponent as smile } from "/asset/svg/smile.svg";
import { ReactComponent as smileFilled } from "/asset/svg/smile-filled.svg";
import { ReactComponent as starFilled } from "/asset/svg/star.svg";
import { ReactComponent as tickCircle } from "/asset/svg/tickCircle.svg";
import { ReactComponent as triangleDown } from "/asset/svg/triangle-down.svg";
import { ReactComponent as user } from "/asset/svg/user.svg";
import { ReactComponent as userCircleFilled } from "/asset/svg/userCircleFilled.svg";
import { ReactComponent as video } from "/asset/svg/video.svg";
import { ReactComponent as videoColorFilled } from "/asset/svg/video-colorfilled.svg";
import { ReactComponent as videoFilled } from "/asset/svg/video-filled.svg";
import { ReactComponent as walgreens } from "/asset/svg/walgreens-logo.svg";
import theme from "/theme/default";

import { Background } from "./Icon.styles";
import { Props } from "./Icon.types";

export const createIconComponent =
  (Icon: React.ElementType) =>
  ({ color: colorProp, size = 24, className, background: backgroundProp, style }: Props) => {
    // This will allow us to style the check in CSS without running into specificity
    // issues caused by inline-styles. If you want to style your icon with css, just
    // don't include the color prop
    const color = colorProp ? theme.colors[colorProp] : undefined;

    const icon = (
      <Icon
        style={{
          color,
          width: size,
          height: size,
          ...style,
        }}
        className={className}
      />
    );

    if (!backgroundProp) {
      return icon;
    }

    const background = {
      size: backgroundProp.size || 48,
      color: backgroundProp.color || "backgroundSecondary",
    };

    return (
      <Background className={className} background={background}>
        {icon}
      </Background>
    );
  };

export const Alert = createIconComponent(alert);
export const AlertCircle = createIconComponent(alertCircle);
export const AlertCircleOpen = createIconComponent(alertCircleOpen);
export const ArrowLeft = createIconComponent(arrowLeft);
export const ArrowRightCircle = createIconComponent(arrowRightCircle);
export const ArrowUpRight = createIconComponent(arrowUpRight);
export const AvailableSoon = createIconComponent(availableSoon);
export const Bell = createIconComponent(bell);
export const Briefcase = createIconComponent(briefcase);
export const Calendar = createIconComponent(calendar);
export const Check = createIconComponent(check);
export const Checkmark = createIconComponent(checkmark);
export const CheckCircle = createIconComponent(checkCircle);
export const ChevronDown = createIconComponent(chevronDown);
export const ChevronLeft = createIconComponent(chevronLeft);
export const ChevronRight = createIconComponent(chevronRight);
export const ChevronUp = createIconComponent(chevronUp);
export const CityMD = createIconComponent(cityMD);
export const Clipboard = createIconComponent(clipboard);
export const ClipboardFilled = createIconComponent(clipboardFilled);
export const ClipboardHeart = createIconComponent(clipboardHeart);
export const Clock = createIconComponent(clock);
export const CornerUpLeft = createIconComponent(cornerUpLeft);
export const CornerUpRight = createIconComponent(cornerUpRight);
export const CreditCard = createIconComponent(creditCard);
export const Cross = createIconComponent(cross);
export const CrossFilled = createIconComponent(crossFilled);
export const Edit = createIconComponent(edit);
export const Eye = createIconComponent(eye);
export const EyeOff = createIconComponent(eyeOff);
export const File = createIconComponent(file);
export const FilePlus = createIconComponent(filePlus);
export const FileText = createIconComponent(fileText);
export const Globe = createIconComponent(globe);
export const HeartFilled = createIconComponent(heartFilled);
export const HelpCircle = createIconComponent(helpCircle);
export const HelpCircleFilled = createIconComponent(helpCircleFilled);
export const Home = createIconComponent(home);
export const HomeFilled = createIconComponent(homeFilled);
export const HouseArrow = createIconComponent(houseArrow);
export const ImagingResult = createIconComponent(imagingResult);
export const Info = createIconComponent(info);
export const InsuranceCard = createIconComponent(insuranceCard);
export const LabResult = createIconComponent(labResult);
export const Link = createIconComponent(link);
export const Lock = createIconComponent(lock);
export const LogoLocationFilled = createIconComponent(logoLocationFilled);
export const Logout = createIconComponent(logout);
export const Mail = createIconComponent(mail);
export const MailFilled = createIconComponent(mailFilled);
export const MailOpen = createIconComponent(mailOpen);
export const MapPin = createIconComponent(mapPin);
export const MapPinFilled = createIconComponent(mapPinFilled);
export const Menu = createIconComponent(menu);
export const Message = createIconComponent(message);
export const MultiplePatients = createIconComponent(multiplePatients);
export const MyAccount = createIconComponent(myAccount);
export const MyAccountFilled = createIconComponent(myAccountFilled);
export const Navigation = createIconComponent(navigation);
export const Pencil = createIconComponent(pencil);
export const Phone = createIconComponent(phone);
export const Pill = createIconComponent(pill);
export const PlusSquare = createIconComponent(plusSquare);
export const Close = createIconComponent(close);
export const Provider = createIconComponent(provider);
export const Smartphone = createIconComponent(smartphone);
export const Search = createIconComponent(search);
export const Send = createIconComponent(send);
export const Settings = createIconComponent(settings);
export const SinglePatient = createIconComponent(singlePatient);
export const Sliders = createIconComponent(sliders);
export const Smile = createIconComponent(smile);
export const SmileFilled = createIconComponent(smileFilled);
export const Star = createIconComponent(star);
export const StarFilled = createIconComponent(starFilled);
export const Trash = createIconComponent(trash);
export const TriangleDown = createIconComponent(triangleDown);
export const User = createIconComponent(user);
export const UserCircleFilled = createIconComponent(userCircleFilled);
export const Video = createIconComponent(video);
export const VideoFilled = createIconComponent(videoFilled);
export const VideoColorFilled = createIconComponent(videoColorFilled);
export const Caregiver = createIconComponent(caregiver);
export const Plus = createIconComponent(plus);
export const PersonCheck = createIconComponent(personCheck);
export const NewHome = createIconComponent(newHome);
export const MyHealth = createIconComponent(myHealth);
export const SearchProvider = createIconComponent(searchProvider);
export const BillPayment = createIconComponent(billPayment);
export const NewMessage = createIconComponent(newMessage);
export const Certificate = createIconComponent(certificate);
export const NewSettings = createIconComponent(newSettings);
export const NotificationBell = createIconComponent(notificationBell);
export const Faq = createIconComponent(faq);
export const Logo = createIconComponent(logo);
export const MyAccountComplete = createIconComponent(myAccountComplete);
export const Medicine = createIconComponent(medicine);
export const Medication = createIconComponent(medication);
export const PreferredPharmacy = createIconComponent(preferredPharmacy);
export const Distance = createIconComponent(distance);
export const Document = createIconComponent(document);
export const TickCircle = createIconComponent(tickCircle);
export const Person = createIconComponent(person);
export const InsuranceBadge = createIconComponent(badge);
export const Cash = createIconComponent(cash);
export const BandageFilled = createIconComponent(bandageFilled);
export const ShieldCheckFilled = createIconComponent(shieldCheckFilled);
export const ClockFilled = createIconComponent(clockFilled);
export const LocationFilled = createIconComponent(locationFilled);
export const Live = createIconComponent(live);
export const Walgreens = createIconComponent(walgreens);

export { FeatherIcon } from "./Icon.util";
