import React from "react";

import { Text } from "/component/base";
import routes from "/constant/url.constant";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import { Base, Link, LinkItem, TabButton } from "./TopTabBar.styles";
import { Props } from "./TopTabBar.types";
/**
 * TopTabBar renders a `<nav>` containing a list of links (<a>) that will navigate based on
 * the `to` value in the link.
 *
 * You can optionally provide a before/after element. The `after` element will commonly be used
 * to render a filter button to the right of the navigation.
 */
const TopTabBar = ({ className, after, before, links, onClick, asButton, disabled }: Props) => {
  const logButtonClickEvent = (routeName?: string) => {
    let userFlow = "";
    let source = "";
    let buttonName = routeName;
    switch (routeName) {
      case "?state=upcoming":
        userFlow = AnalyticsUserFlow.APPOINTMENTS;
        source = AnalyticsSource.APPOINTMENTS;
        buttonName = "Appointments (Upcoming)";
        break;
      case "?state=past":
        userFlow = AnalyticsUserFlow.APPOINTMENTS;
        source = AnalyticsSource.APPOINTMENTS;
        buttonName = "Appointments (Past)";
        break;
      case routes.messaging:
        userFlow = AnalyticsUserFlow.MESSAGES;
        source = AnalyticsSource.MESSAGES;
        buttonName = "Messages (Inbox)";
        break;
      case routes.sentMessages:
        userFlow = AnalyticsUserFlow.MESSAGES;
        source = AnalyticsSource.MESSAGES;
        buttonName = "Messages (Sent)";
        break;
      default:
        break;
    }
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: userFlow,
      source: source,
      button_name: buttonName,
    } as ButtonClickParams);
  };
  return (
    <Base className={className}>
      {before}
      <nav>
        <ul>
          {links.map(({ to, label, isActive }) => (
            <LinkItem key={label}>
              {asButton ? (
                <TabButton
                  disabled={disabled || isActive}
                  $isActive={isActive}
                  onClick={() => {
                    onClick && onClick(to.toString());
                  }}
                >
                  <Text color="inherit" variant={isActive ? "body1Bold" : "body1"}>
                    {label}
                  </Text>
                </TabButton>
              ) : (
                <Link
                  exact
                  to={to}
                  $isActive={isActive}
                  onClick={() => {
                    logButtonClickEvent(to.toString());
                  }}
                >
                  <Text color="inherit" variant={isActive ? "body1Bold" : "body1"}>
                    {label}
                  </Text>
                </Link>
              )}
            </LinkItem>
          ))}
        </ul>
      </nav>

      {after}
    </Base>
  );
};

export default TopTabBar;
