// ===================================================================
// Import modules
// ===================================================================
import "./i18n";

import { applyPolyfills, defineCustomElements } from "amwell-visit-console/loader";
import * as React from "react";
import * as ReactDOM from "react-dom";

// ===================================================================
// Import components
// ===================================================================
import AppRoot from "/component/root/AppRoot";

applyPolyfills().then(() => {
  defineCustomElements(window).then(() => {
    ReactDOM.render(<AppRoot />, document.getElementById("root"));
  });
});
