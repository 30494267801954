import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";

import { List } from "/component/base";
import * as Icons from "/component/base/Icon";
import { Props as IconProps } from "/component/base/Icon/Icon.types";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import MainContentLoginCta from "/component/partial/MainContentLoginCta";
import SetCaregiver from "/component/partial/SetCaregiver";
import { useCaregiverProvider } from "/component/provider/CaregiverProvider";
import routes from "/constant/url.constant";
import { useAuth, useTranslation } from "/hook";
import { reactPlugin } from "/util/appInsights.util";
import {
  hasFeatureFlagDisabled,
  hasFeatureFlagEnabled,
  hasFeatureFlagLegacy,
} from "/util/featureFlags/featureFlags";
import {
  AnalyticsEvent,
  AnalyticsSource,
  AnalyticsUserFlow,
  ButtonClickParams,
  logEvent,
} from "/util/firebase.util";

import {
  BadgeContainer,
  HeaderContainer,
  IconContainer,
  ListContainer,
  MyHealthItem,
  TextContent,
} from "./MyHealthIndex.styles";

const ENABLE_CAREGIVER_PROXY = process.env.ENABLE_CAREGIVER_PROXY === "true";

interface OptionProp {
  Icon: (prop: IconProps) => JSX.Element;
  title: string;
  to: string & {
    pathname: string;
  };
  hide?: boolean;
  badge?: number | null;
}

const MyHealthIndex = () => {
  const { t } = useTranslation("myHealth");
  const history = useHistory();

  const { isAuthenticated, login } = useAuth();
  const { caregiverMode, caregiver } = useCaregiverProvider();

  const logButtonClickEvent = (buttonName: string) => {
    logEvent(AnalyticsEvent.BUTTON_CLICK, {
      user_flow: AnalyticsUserFlow.MY_HEALTH,
      source: AnalyticsSource.MY_HEALTH,
      button_name: buttonName,
    } as ButtonClickParams);
  };

  //Not authorized show an empty state
  if (!isAuthenticated) {
    return (
      <MainContent
        pageTitle={caregiverMode ? t("titleWithName", { name: caregiver.firstName }) : t("title")}
      >
        <Helmet>
          <title>
            {caregiverMode ? t("titleWithName", { name: caregiver.firstName }) : t("title")}
          </title>
        </Helmet>

        <MainContentLoginCta
          title={t("empty.title")}
          subtitle={t("empty.subtitle")}
          analyticsParams={
            {
              user_flow: AnalyticsUserFlow.MY_HEALTH,
              source: AnalyticsSource.MY_HEALTH,
            } as ButtonClickParams
          }
          login={() => login()}
        />
      </MainContent>
    );
  }

  const options = [
    {
      Icon: Icons.Clipboard,
      title: t("results"),
      to: routes.results,
      hide: hasFeatureFlagDisabled("test_results_enabled"),
    },
    {
      Icon: Icons.Medicine,
      title: t("medications", "Medications"),
      to: routes.medications,
      hide: !hasFeatureFlagEnabled("medications_enabled"),
    },
    {
      Icon: Icons.Medicine,
      title: t("prescriptions"),
      to: routes.prescriptions,
      hide: !hasFeatureFlagLegacy("medications_enabled"),
    },
    {
      Icon: Icons.PreferredPharmacy,
      title: t("preferredPharmacy"),
      to: routes.preferredPharmacy,
      hide: !hasFeatureFlagLegacy("medications_enabled"),
    },
    {
      Icon: Icons.FilePlus,
      title: t("careSummaries"),
      to: {
        pathname: routes.careSummaries,
      },
      hide: !hasFeatureFlagEnabled("care_summaries_enabled"),
    },
    {
      Icon: Icons.FileText,
      title: t("documents"),
      to: {
        pathname: routes.documents,
      },
      hide: hasFeatureFlagDisabled("documents_enabled"),
    },
  ].filter((o) => !o.hide);

  const filteredOptions = options.filter((el) => el) as OptionProp[];

  return (
    <MainContent
      pageTitle={
        caregiverMode ? t("headlineWithName", { name: caregiver.firstName }) : t("headline")
      }
      headerAccessory={
        <HeaderContainer>
          {ENABLE_CAREGIVER_PROXY && isAuthenticated && (
            <SetCaregiver
              buttonStyle="blank"
              analyticsParams={
                {
                  user_flow: AnalyticsUserFlow.CAREGIVER,
                  source: AnalyticsSource.MY_HEALTH,
                } as ButtonClickParams
              }
            />
          )}
        </HeaderContainer>
      }
    >
      <Helmet>
        <title>
          {caregiverMode ? t("titleWithName", { name: caregiver.firstName }) : t("title")}
        </title>
      </Helmet>
      <ListContainer>
        <List
          as="Buttons"
          hideLastChildBorder
          onClick={({ to }) => {
            if (to === routes.results) {
              logButtonClickEvent("Results");
            } else if (to === routes.prescriptions) {
              logButtonClickEvent("Prescriptions");
            } else if (to === routes.preferredPharmacy) {
              logButtonClickEvent("Preferred pharmacy");
            } else if (to.pathname === routes.documents) {
              logButtonClickEvent("Documents");
            }
            history.push(to);
          }}
          data={filteredOptions}
          renderItem={({ Icon, title, badge }: OptionProp) => (
            <MyHealthItem>
              <IconContainer>
                <Icon
                  color="brandSecondary"
                  background={{ size: 54, color: "brandSecondaryLight" }}
                />
              </IconContainer>
              <TextContent>
                <Text variant="body1Bold">{title}</Text>
                {badge && (
                  <BadgeContainer>
                    <Text css={{ alignSelf: "center" }} variant="body1Bold" color="white">
                      {badge}
                    </Text>
                  </BadgeContainer>
                )}
              </TextContent>
            </MyHealthItem>
          )}
        />
      </ListContainer>
    </MainContent>
  );
};

export default withAITracking(reactPlugin, MyHealthIndex, undefined, "ai-tracking");
