/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelAppointment
// ====================================================

export interface CancelAppointment_appointment_cancelAppointment {
  __typename: "CancelledAppointment";
  status: AppointmentStatus;
}

export interface CancelAppointment_appointment {
  __typename: "AppointmentMutations";
  cancelAppointment: CancelAppointment_appointment_cancelAppointment;
}

export interface CancelAppointment {
  appointment: CancelAppointment_appointment;
}

export interface CancelAppointmentVariables {
  appointmentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateOnlineCheckInUrl
// ====================================================

export interface GenerateOnlineCheckInUrl_appointment_generateOnlineCheckInUrl {
  __typename: "OnlineCheckInUrl";
  url: string;
}

export interface GenerateOnlineCheckInUrl_appointment {
  __typename: "AppointmentMutations";
  generateOnlineCheckInUrl: GenerateOnlineCheckInUrl_appointment_generateOnlineCheckInUrl;
}

export interface GenerateOnlineCheckInUrl {
  appointment: GenerateOnlineCheckInUrl_appointment;
}

export interface GenerateOnlineCheckInUrlVariables {
  appointmentId: number;
  departmentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: preferredPharmacyUpdate
// ====================================================

export interface preferredPharmacyUpdate_preferredPharmacy_add {
  __typename: "Success";
  success: boolean;
}

export interface preferredPharmacyUpdate_preferredPharmacy {
  __typename: "PreferredPharmacyMutations";
  add: preferredPharmacyUpdate_preferredPharmacy_add | null;
}

export interface preferredPharmacyUpdate {
  preferredPharmacy: preferredPharmacyUpdate_preferredPharmacy;
}

export interface preferredPharmacyUpdateVariables {
  ncpdpId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePreferredPharmacy
// ====================================================

export interface deletePreferredPharmacy_preferredPharmacy_delete {
  __typename: "Success";
  success: boolean;
}

export interface deletePreferredPharmacy_preferredPharmacy {
  __typename: "PreferredPharmacyMutations";
  delete: deletePreferredPharmacy_preferredPharmacy_delete | null;
}

export interface deletePreferredPharmacy {
  preferredPharmacy: deletePreferredPharmacy_preferredPharmacy;
}

export interface deletePreferredPharmacyVariables {
  clinicalProviderId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAppointment
// ====================================================

export interface getAppointment_patient_appointment_onlineCheckInEligibility {
  __typename: "OnlineCheckInEligibility";
  isEligible: boolean;
}

export interface getAppointment_patient_appointment_provider {
  __typename: "Provider";
  providerId: number | null;
  npi: number | null;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  specialty: string | null;
  imageUrl: string | null;
}

export interface getAppointment_patient_appointment_department {
  __typename: "Department";
  name: string;
  address: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  latitude: number | null;
  longitude: number | null;
}

export interface getAppointment_patient_appointment {
  __typename: "Appointment";
  appointmentId: number;
  appointmentStatus: AppointmentStatus;
  visitType: VisitType;
  onlineCheckInEligibility: getAppointment_patient_appointment_onlineCheckInEligibility | null;
  patientAppointmentTypeName: string;
  startDateTime: any;
  endDateTime: any;
  provider: getAppointment_patient_appointment_provider | null;
  departmentId: number;
  department: getAppointment_patient_appointment_department | null;
}

export interface getAppointment_patient {
  __typename: "Patient";
  appointment: getAppointment_patient_appointment | null;
}

export interface getAppointment {
  patient: getAppointment_patient;
}

export interface getAppointmentVariables {
  appointmentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAppointments
// ====================================================

export interface getAppointments_patient_appointments_onlineCheckInEligibility {
  __typename: "OnlineCheckInEligibility";
  isEligible: boolean;
}

export interface getAppointments_patient_appointments_provider {
  __typename: "Provider";
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  specialty: string | null;
  providerId: number | null;
  imageUrl: string | null;
}

export interface getAppointments_patient_appointments_department {
  __typename: "Department";
  name: string;
}

export interface getAppointments_patient_appointments {
  __typename: "Appointment";
  appointmentId: number;
  appointmentStatus: AppointmentStatus;
  startDateTime: any;
  endDateTime: any;
  visitType: VisitType;
  onlineCheckInEligibility: getAppointments_patient_appointments_onlineCheckInEligibility | null;
  patientAppointmentTypeName: string;
  provider: getAppointments_patient_appointments_provider | null;
  departmentId: number;
  department: getAppointments_patient_appointments_department | null;
}

export interface getAppointments_patient {
  __typename: "Patient";
  appointments: getAppointments_patient_appointments[] | null;
}

export interface getAppointments {
  patient: getAppointments_patient;
}

export interface getAppointmentsVariables {
  showPast?: boolean | null;
  cacheRefresh?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_IMMUNIZATIONS
// ====================================================

export interface GET_IMMUNIZATIONS_patient_immunizations {
  __typename: "Vaccine";
  id: string;
  administeredDate: any | null;
  route: string | null;
  units: string | null;
  expirationDate: any | null;
  lotNumber: string | null;
  manufacturer: string | null;
  name: string | null;
  quantity: number | null;
  site: string | null;
}

export interface GET_IMMUNIZATIONS_patient {
  __typename: "Patient";
  immunizations: GET_IMMUNIZATIONS_patient_immunizations[] | null;
}

export interface GET_IMMUNIZATIONS {
  patient: GET_IMMUNIZATIONS_patient;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEncountersSummit
// ====================================================

export interface GetEncountersSummit_patient_summaryCareRecords_items_provider {
  __typename: "Provider";
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
}

export interface GetEncountersSummit_patient_summaryCareRecords_items {
  __typename: "Letter";
  letterId: number;
  createdDatetime: any | null;
  html: string | null;
  letterText: string | null;
  paperformName: string | null;
  documentSubclass: string | null;
  provider: GetEncountersSummit_patient_summaryCareRecords_items_provider | null;
}

export interface GetEncountersSummit_patient_summaryCareRecords {
  __typename: "LetterPaginationWindow";
  /**
   * Total number of items in unfiltered window
   */
  totalCount: number;
  /**
   * List of items in this window
   */
  items: GetEncountersSummit_patient_summaryCareRecords_items[];
}

export interface GetEncountersSummit_patient_letterEpisodes_items_provider {
  __typename: "Provider";
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
}

export interface GetEncountersSummit_patient_letterEpisodes_items {
  __typename: "Letter";
  letterId: number;
  createdDatetime: any | null;
  html: string | null;
  letterText: string | null;
  paperformName: string | null;
  documentSubclass: string | null;
  provider: GetEncountersSummit_patient_letterEpisodes_items_provider | null;
}

export interface GetEncountersSummit_patient_letterEpisodes {
  __typename: "LetterPaginationWindow";
  /**
   * Total number of items in unfiltered window
   */
  totalCount: number;
  /**
   * List of items in this window
   */
  items: GetEncountersSummit_patient_letterEpisodes_items[];
}

export interface GetEncountersSummit_patient_patientsCareSummaries_items_provider {
  __typename: "Provider";
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
}

export interface GetEncountersSummit_patient_patientsCareSummaries_items {
  __typename: "Letter";
  letterId: number;
  createdDatetime: any | null;
  html: string | null;
  letterText: string | null;
  paperformName: string | null;
  documentSubclass: string | null;
  provider: GetEncountersSummit_patient_patientsCareSummaries_items_provider | null;
}

export interface GetEncountersSummit_patient_patientsCareSummaries {
  __typename: "LetterPaginationWindow";
  /**
   * Total number of items in unfiltered window
   */
  totalCount: number;
  /**
   * List of items in this window
   */
  items: GetEncountersSummit_patient_patientsCareSummaries_items[];
}

export interface GetEncountersSummit_patient {
  __typename: "Patient";
  summaryCareRecords: GetEncountersSummit_patient_summaryCareRecords | null;
  letterEpisodes: GetEncountersSummit_patient_letterEpisodes | null;
  patientsCareSummaries: GetEncountersSummit_patient_patientsCareSummaries | null;
}

export interface GetEncountersSummit {
  patient: GetEncountersSummit_patient;
}

export interface GetEncountersSummitVariables {
  limit?: number | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_DEMOGRAPHICS
// ====================================================

export interface GET_DEMOGRAPHICS_patient_demographics {
  __typename: "Demographics";
  address1: string | null;
  address2: string | null;
  city: string | null;
  dateOfBirth: any | null;
  firstName: string | null;
  lastName: string | null;
  state: string | null;
  zipCode: string | null;
}

export interface GET_DEMOGRAPHICS_patient {
  __typename: "Patient";
  demographics: GET_DEMOGRAPHICS_patient_demographics | null;
}

export interface GET_DEMOGRAPHICS {
  patient: GET_DEMOGRAPHICS_patient;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_LETTERS
// ====================================================

export interface GET_LETTERS_patient_letters_items_provider {
  __typename: "Provider";
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
}

export interface GET_LETTERS_patient_letters_items {
  __typename: "Letter";
  letterId: number;
  createdDatetime: any | null;
  html: string | null;
  letterText: string | null;
  paperformName: string | null;
  provider: GET_LETTERS_patient_letters_items_provider | null;
}

export interface GET_LETTERS_patient_letters {
  __typename: "LetterPaginationWindow";
  /**
   * Total number of items in unfiltered window
   */
  totalCount: number;
  /**
   * List of items in this window
   */
  items: GET_LETTERS_patient_letters_items[];
}

export interface GET_LETTERS_patient {
  __typename: "Patient";
  letters: GET_LETTERS_patient_letters | null;
}

export interface GET_LETTERS {
  patient: GET_LETTERS_patient;
}

export interface GET_LETTERSVariables {
  limit?: number | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_IMAGING_RESULT_PAGE
// ====================================================

export interface GET_IMAGING_RESULT_PAGE_patient {
  __typename: "Patient";
  imagingResultPage: string | null;
}

export interface GET_IMAGING_RESULT_PAGE {
  patient: GET_IMAGING_RESULT_PAGE_patient;
}

export interface GET_IMAGING_RESULT_PAGEVariables {
  imagingResultId: number;
  pageId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_INSURANCE
// ====================================================

export interface GET_INSURANCE_patient_insurances {
  __typename: "Insurance";
  confidentialityCode: string | null;
  eligibilityStatus: string | null;
  expirationDate: any | null;
  insuranceId: string | null;
  insuranceIdNumber: string | null;
  insurancePackageId: number | null;
  insurancePlanName: string | null;
  insurancePolicyHolder: string | null;
  insurancePolicyHolderSex: string | null;
  insuranceType: string | null;
  ircId: number | null;
  ircName: string | null;
  lastUpdated: any | null;
  lastUpdatedBy: string | null;
  policyNumber: string | null;
  relationshipToInsured: string | null;
  relationshipToInsuredId: number | null;
  sequenceNumber: number | null;
}

export interface GET_INSURANCE_patient {
  __typename: "Patient";
  insurances: GET_INSURANCE_patient_insurances[] | null;
}

export interface GET_INSURANCE {
  patient: GET_INSURANCE_patient;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_LAB_RESULT_PAGE
// ====================================================

export interface GET_LAB_RESULT_PAGE_patient {
  __typename: "Patient";
  labResultPage: string | null;
}

export interface GET_LAB_RESULT_PAGE {
  patient: GET_LAB_RESULT_PAGE_patient;
}

export interface GET_LAB_RESULT_PAGEVariables {
  labResultId: number;
  pageId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_LAZY_DEMOGRAPHICS
// ====================================================

export interface GET_LAZY_DEMOGRAPHICS_patient_demographics {
  __typename: "Demographics";
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
}

export interface GET_LAZY_DEMOGRAPHICS_patient {
  __typename: "Patient";
  demographics: GET_LAZY_DEMOGRAPHICS_patient_demographics | null;
}

export interface GET_LAZY_DEMOGRAPHICS {
  patient: GET_LAZY_DEMOGRAPHICS_patient;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_MEDICATIONS
// ====================================================

export interface GET_MEDICATIONS_patient_activeMedications_pharmacyFacility {
  __typename: "Pharmacy";
  address: string | null;
  city: string | null;
  facilityId: number | null;
  name: string | null;
  ncpdpId: string | null;
  pharmacyType: string | null;
  phoneNumber: string | null;
  state: string | null;
  zip: string | null;
}

export interface GET_MEDICATIONS_patient_activeMedications {
  __typename: "Medication";
  medicationName: string | null;
  medicationId: number | null;
  datePrescribed: any | null;
  medicationType: MedicationType | null;
  medicationEntryId: string | null;
  patientNote: string | null;
  quantity: number | null;
  quantityUnit: string | null;
  status: string | null;
  unstructuredSig: string | null;
  pharmacyFacility: GET_MEDICATIONS_patient_activeMedications_pharmacyFacility | null;
}

export interface GET_MEDICATIONS_patient_historicalMedications_pharmacyFacility {
  __typename: "Pharmacy";
  address: string | null;
  city: string | null;
  facilityId: number | null;
  name: string | null;
  ncpdpId: string | null;
  pharmacyType: string | null;
  phoneNumber: string | null;
  state: string | null;
  zip: string | null;
}

export interface GET_MEDICATIONS_patient_historicalMedications {
  __typename: "Medication";
  medicationName: string | null;
  medicationId: number | null;
  datePrescribed: any | null;
  medicationType: MedicationType | null;
  medicationEntryId: string | null;
  patientNote: string | null;
  quantity: number | null;
  quantityUnit: string | null;
  status: string | null;
  unstructuredSig: string | null;
  pharmacyFacility: GET_MEDICATIONS_patient_historicalMedications_pharmacyFacility | null;
}

export interface GET_MEDICATIONS_patient {
  __typename: "Patient";
  activeMedications: GET_MEDICATIONS_patient_activeMedications[] | null;
  historicalMedications: GET_MEDICATIONS_patient_historicalMedications[] | null;
}

export interface GET_MEDICATIONS {
  patient: GET_MEDICATIONS_patient;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_PHARMACY
// ====================================================

export interface GET_PHARMACY_patient_preferredPharmacies {
  __typename: "PreferredPharmacy";
  pharmacyType: string | null;
  address: string | null;
  state: string | null;
  city: string | null;
  zip: string | null;
  phoneNumber: string | null;
  clinicalProviderName: string | null;
  clinicalProviderId: number | null;
}

export interface GET_PHARMACY_patient {
  __typename: "Patient";
  preferredPharmacies: GET_PHARMACY_patient_preferredPharmacies[] | null;
}

export interface GET_PHARMACY {
  patient: GET_PHARMACY_patient;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PHARMACY_SEARCH
// ====================================================

export interface PHARMACY_SEARCH_pharmacies {
  __typename: "Pharmacy";
  name: string | null;
  facilityId: number | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  distance: number | null;
  phoneNumber: string | null;
  faxNumber: string | null;
  ncpdpId: string | null;
  pharmacyType: string | null;
}

export interface PHARMACY_SEARCH {
  pharmacies: PHARMACY_SEARCH_pharmacies[] | null;
}

export interface PHARMACY_SEARCHVariables {
  search: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAppointmentTelehealthLink
// ====================================================

export interface getAppointmentTelehealthLink_patient_appointmentTelehealthLink {
  __typename: "TelehealthLink";
  joinToken: string;
  patientUrl: string;
  appointmentId: number;
}

export interface getAppointmentTelehealthLink_patient {
  __typename: "Patient";
  appointmentTelehealthLink: getAppointmentTelehealthLink_patient_appointmentTelehealthLink | null;
}

export interface getAppointmentTelehealthLink {
  patient: getAppointmentTelehealthLink_patient;
}

export interface getAppointmentTelehealthLinkVariables {
  appointmentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_LAB_DETAILS
// ====================================================

export interface GET_LAB_DETAILS_patient_labResult_analytes {
  __typename: "LabAnalyte";
  abnormalFlag: string | null;
  analyteName: string | null;
  note: string | null;
  referenceRange: string | null;
  units: string | null;
  value: string | null;
}

export interface GET_LAB_DETAILS_patient_labResult_pages {
  __typename: "DocumentPage";
  contentType: string;
  pageId: number;
  pageOrdering: number;
}

export interface GET_LAB_DETAILS_patient_labResult_provider {
  __typename: "Provider";
  displayName: string | null;
  firstName: string | null;
  lastName: string | null;
  specialty: string | null;
  imageUrl: string | null;
}

export interface GET_LAB_DETAILS_patient_labResult_department {
  __typename: "Department";
  brand: Brand;
}

export interface GET_LAB_DETAILS_patient_labResult {
  __typename: "LabResultDetail";
  description: string | null;
  labResultDatetime: any | null;
  labResultId: number;
  patientNote: string | null;
  portalPublishedDatetime: any | null;
  analytes: GET_LAB_DETAILS_patient_labResult_analytes[];
  pages: GET_LAB_DETAILS_patient_labResult_pages[];
  provider: GET_LAB_DETAILS_patient_labResult_provider | null;
  performingLabName: string | null;
  performingLabAddress1: string | null;
  performingLabCity: string | null;
  performingLabState: string | null;
  performingLabZip: string | null;
  status: string | null;
  department: GET_LAB_DETAILS_patient_labResult_department | null;
}

export interface GET_LAB_DETAILS_patient {
  __typename: "Patient";
  labResult: GET_LAB_DETAILS_patient_labResult | null;
}

export interface GET_LAB_DETAILS {
  patient: GET_LAB_DETAILS_patient;
}

export interface GET_LAB_DETAILSVariables {
  labResultId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_IMAGING_DETAILS
// ====================================================

export interface GET_IMAGING_DETAILS_patient_imagingResult_provider {
  __typename: "Provider";
  displayName: string | null;
  firstName: string | null;
  lastName: string | null;
  specialty: string | null;
  imageUrl: string | null;
}

export interface GET_IMAGING_DETAILS_patient_imagingResult_pages {
  __typename: "DocumentPage";
  pageOrdering: number;
  pageId: number;
  contentType: string;
}

export interface GET_IMAGING_DETAILS_patient_imagingResult_performingFacility {
  __typename: "PerformingFacility";
  name: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
}

export interface GET_IMAGING_DETAILS_patient_imagingResult {
  __typename: "ImagingResult";
  createdDatetime: any | null;
  createdUser: string | null;
  description: string | null;
  documentData: string | null;
  imagingResultId: number | null;
  portalPublishedDatetime: any | null;
  providerUsername: string | null;
  patientNote: string | null;
  status: string | null;
  provider: GET_IMAGING_DETAILS_patient_imagingResult_provider | null;
  pages: GET_IMAGING_DETAILS_patient_imagingResult_pages[] | null;
  performingFacility: GET_IMAGING_DETAILS_patient_imagingResult_performingFacility | null;
}

export interface GET_IMAGING_DETAILS_patient {
  __typename: "Patient";
  imagingResult: GET_IMAGING_DETAILS_patient_imagingResult | null;
}

export interface GET_IMAGING_DETAILS {
  patient: GET_IMAGING_DETAILS_patient;
}

export interface GET_IMAGING_DETAILSVariables {
  imagingResultId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_LABS
// ====================================================

export interface GET_LABS_patient_labResults_items {
  __typename: "LabResultListItem";
  description: string | null;
  labResultId: number;
  portalPublishedDatetime: any | null;
}

export interface GET_LABS_patient_labResults {
  __typename: "LabResultListItemPaginationWindow";
  /**
   * List of items in this window
   */
  items: GET_LABS_patient_labResults_items[];
}

export interface GET_LABS_patient {
  __typename: "Patient";
  labResults: GET_LABS_patient_labResults | null;
}

export interface GET_LABS {
  patient: GET_LABS_patient;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GET_IMAGING_RESULTS
// ====================================================

export interface GET_IMAGING_RESULTS_patient_imagingResults_items {
  __typename: "ImagingResultListItem";
  imagingResultId: number | null;
  documentDescription: string | null;
  createdDatetime: any | null;
}

export interface GET_IMAGING_RESULTS_patient_imagingResults {
  __typename: "ImagingResultListItemPaginationWindow";
  /**
   * List of items in this window
   */
  items: GET_IMAGING_RESULTS_patient_imagingResults_items[];
}

export interface GET_IMAGING_RESULTS_patient {
  __typename: "Patient";
  imagingResults: GET_IMAGING_RESULTS_patient_imagingResults | null;
}

export interface GET_IMAGING_RESULTS {
  patient: GET_IMAGING_RESULTS_patient;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppointmentStatus {
  CANCELLED = "CANCELLED",
  CHARGE_ENTERED = "CHARGE_ENTERED",
  CHECKED_IN = "CHECKED_IN",
  CHECKED_OUT = "CHECKED_OUT",
  FUTURE = "FUTURE",
  OPEN = "OPEN",
}

export enum Brand {
  CITYMD = "CITYMD",
  SUMMIT_HEALTH = "SUMMIT_HEALTH",
  VILLAGE_MEDICAL = "VILLAGE_MEDICAL",
}

export enum MedicationType {
  ACTIVE = "ACTIVE",
  DENIED = "DENIED",
  HISTORICAL = "HISTORICAL",
}

export enum VisitType {
  HOME = "HOME",
  OFFICE = "OFFICE",
  UNKNOWN = "UNKNOWN",
  VIRTUAL = "VIRTUAL",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
