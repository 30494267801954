import { gql, LazyQueryResultTuple } from "@apollo/client";

import { useB2CLazyQuery } from "../client";
import {
  getAppointmentTelehealthLink,
  getAppointmentTelehealthLinkVariables,
} from "../schema/types";

const AppointmentTelehealthLinkQuery = gql`
  query getAppointmentTelehealthLink($appointmentId: Int!) {
    patient {
      appointmentTelehealthLink(appointmentId: $appointmentId) {
        joinToken
        patientUrl
        appointmentId
      }
    }
  }
`;

export const useQueryAppointmentTelehealth = (
  appointmentId: getAppointmentTelehealthLinkVariables["appointmentId"],
): LazyQueryResultTuple<getAppointmentTelehealthLink, getAppointmentTelehealthLinkVariables> => {
  return useB2CLazyQuery(AppointmentTelehealthLinkQuery, {
    variables: { appointmentId },
    fetchPolicy: "network-only",
  });
};
