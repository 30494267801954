import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { Helmet } from "react-helmet-async";

import noResultsImage from "/asset/image/noResultsFound.png";
import useInsurance from "/b2c/query/useInsurance.b2c";
import { AlertCircleOpen } from "/component/base/Icon";
import List from "/component/base/List";
import Loading from "/component/base/Loading";
import Text from "/component/base/Text";
import MainContent from "/component/layout/MainContent";
import EmptyStateCta from "/component/partial/EmptyStateCta";
import { useTranslation } from "/hook";
import { layout } from "/styles";
import { reactPlugin } from "/util/appInsights.util";

import { convertToPolicyOrderString } from "../Insurance/Insurance.utils";
import { ListContainer, ListWrapper } from "./InsuranceB2C.styles";
import InsuranceItem from "./InsuranceItem";

const IMAGE_HEIGHT = 261;
const IMAGE_WIDTH = 183;

const Insurance = () => {
  const { t } = useTranslation("insurance");
  const { data, error, loading } = useInsurance();
  const insurances = data?.patient.insurances || [];

  if (error) {
    return (
      <MainContent pageTitle={t("title")} parentPage="account">
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>
        <div css={layout.flexVerticalCenter}>
          <div css={layout.marginVertical(80)}>
            <EmptyStateCta
              icon={<AlertCircleOpen color="brandPrimary" size={48} />}
              title={t("error.fetchError.title")}
              subtitle={t("error.fetchError.subtitle")}
            />
          </div>
        </div>
      </MainContent>
    );
  }

  return (
    <MainContent parentPage="account" pageTitle={t("title")}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      {loading && <Loading />}

      {!loading &&
        (insurances.length === 0 ? (
          <div css={layout.flexVerticalCenter}>
            <div css={layout.marginVertical(80)}>
              <EmptyStateCta
                imageHeight={IMAGE_HEIGHT}
                imageWidth={IMAGE_WIDTH}
                imageSource={noResultsImage}
                title={t("emptyStateTitle")}
                subtitle={t("emptyStateSubtitle")}
              />
            </div>
          </div>
        ) : (
          <>
            <Text>{t("subtitle")}</Text>
            <ListWrapper>
              <ListContainer>
                <List
                  as="Divs"
                  showChevron={false}
                  data={insurances}
                  renderItem={(item, index) => (
                    <InsuranceItem
                      insurancePlanName={item.insurancePlanName}
                      memberId={item.policyNumber}
                      groupNumber={item.insuranceIdNumber}
                      dateLastVerified={item.lastUpdated}
                      insuranceType={convertToPolicyOrderString(t, item.sequenceNumber)}
                      eligibilityStatus={item.eligibilityStatus}
                      index={index}
                    />
                  )}
                />
              </ListContainer>
            </ListWrapper>
          </>
        ))}
    </MainContent>
  );
};

export default withAITracking(reactPlugin, Insurance, undefined, "ai-tracking");
