import { ApolloError, gql } from "@apollo/client";

import { useB2CQuery } from "../client";
import { GET_PHARMACY, GET_PHARMACY_patient_preferredPharmacies } from "../schema/types";

export const preferredPharmacyQuery = gql`
  query GET_PHARMACY {
    patient {
      preferredPharmacies {
        pharmacyType
        address
        state
        city
        zip
        phoneNumber
        clinicalProviderName
        clinicalProviderId
      }
    }
  }
`;

const usePreferredPharmacy = (
  enabled = true,
): {
  error: ApolloError | undefined;
  loading: boolean;
  data: GET_PHARMACY_patient_preferredPharmacies | null | undefined;
} => {
  const { data, error, loading } = useB2CQuery<GET_PHARMACY>(preferredPharmacyQuery, {
    skip: !enabled,
  });

  return {
    error,
    loading,
    data: data?.patient?.preferredPharmacies?.[0],
  };
};

export default usePreferredPharmacy;
