/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AbandonDecisionSupport
// ====================================================

export interface AbandonDecisionSupport_abandonDecisionSupport_errors {
  __typename: "ResponseError";
  attribute: string | null;
  code: number;
  message: string;
  type: ErrorType;
}

export interface AbandonDecisionSupport_abandonDecisionSupport {
  __typename: "ResponseType";
  errors: AbandonDecisionSupport_abandonDecisionSupport_errors[];
}

export interface AbandonDecisionSupport {
  /**
   * Abandon's the previously started decision support session. Used for analytics purposes.
   */
  abandonDecisionSupport: AbandonDecisionSupport_abandonDecisionSupport;
}

export interface AbandonDecisionSupportVariables {
  flowSessionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: bookAppointment
// ====================================================

export interface bookAppointment_bookAppointment_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface bookAppointment_bookAppointment {
  __typename: "BookAppointmentResponse";
  errors: bookAppointment_bookAppointment_errors[];
  /**
   * The identifier returned from Experian that can be used to cancel a booked Appointment
   */
  appointmentReferenceId: string | null;
}

export interface bookAppointment {
  /**
   * Turn a Reservation, which is temporary, into an booked Appointment for the authenticated user
   */
  bookAppointment: bookAppointment_bookAppointment;
}

export interface bookAppointmentVariables {
  data: BookAppointmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCodeVerification
// ====================================================

export interface CreateCodeVerification_createCodeVerification_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
  type: ErrorType;
}

export interface CreateCodeVerification_createCodeVerification_verification {
  __typename: "TwilioVerificationStatus";
  id: string;
  status: string;
  to: string;
  type: string;
}

export interface CreateCodeVerification_createCodeVerification {
  __typename: "CreateCodeVerificationResponse";
  errors: CreateCodeVerification_createCodeVerification_errors[];
  verification: CreateCodeVerification_createCodeVerification_verification | null;
}

export interface CreateCodeVerification {
  /**
   * Sends a verification email/sms/call
   */
  createCodeVerification: CreateCodeVerification_createCodeVerification;
}

export interface CreateCodeVerificationVariables {
  type: string;
  to: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCodeVerification
// ====================================================

export interface UpdateCodeVerification_updateCodeVerification_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
  type: ErrorType;
}

export interface UpdateCodeVerification_updateCodeVerification_verification {
  __typename: "TwilioVerificationStatus";
  id: string;
  status: string;
  to: string;
  type: string;
}

export interface UpdateCodeVerification_updateCodeVerification {
  __typename: "UpdateCodeVerificationResponse";
  errors: UpdateCodeVerification_updateCodeVerification_errors[];
  verification: UpdateCodeVerification_updateCodeVerification_verification | null;
}

export interface UpdateCodeVerification {
  /**
   * Sends the verification information to twilio updating the current status
   */
  updateCodeVerification: UpdateCodeVerification_updateCodeVerification;
}

export interface UpdateCodeVerificationVariables {
  input: UpdateCodeVerificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ContinueDecisionSupport
// ====================================================

export interface ContinueDecisionSupport_continueDecisionSupport_errors {
  __typename: "ResponseError";
  attribute: string | null;
  code: number;
  message: string;
  type: ErrorType;
}

export interface ContinueDecisionSupport_continueDecisionSupport_flowResponse_calendarIds {
  __typename: "CalendarIds";
  /**
   * Calendar ids for the selected provider.
   */
  selected: string[] | null;
  /**
   * Calendar ids for providers that are not the selected provider.
   */
  recommended: string[] | null;
}

export interface ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet_elements_selectOptions {
  __typename: "FlowResponseSelectOption";
  fieldName: string | null;
  label: string | null;
  value: string | null;
}

export interface ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet_elements {
  __typename: "FlowResponseQuestion";
  dateRestrictionType: string | null;
  elementName: string | null;
  elementRef: string;
  elementSetRef: string;
  elementType: string | null;
  falseLabel: string | null;
  fieldName: string | null;
  inputType: DSInputType | null;
  isNoOptionSelectionEnabled: boolean | null;
  isRequired: boolean | null;
  maxLength: number | null;
  maxValue: string | null;
  messageText: string | null;
  minLength: number | null;
  minValue: string | null;
  noOptionSelectionDisplayName: string | null;
  orderIndex: number | null;
  questionText: string | null;
  selectOptions: ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet_elements_selectOptions[] | null;
  trueLabel: string | null;
  valueType: DSValueType | null;
}

export interface ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet {
  __typename: "FlowResponseElementSet";
  elementSetRef: string;
  elements: ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet_elements[] | null;
  title: string | null;
}

export interface ContinueDecisionSupport_continueDecisionSupport_flowResponse_results_fields {
  __typename: "FlowResponseDataField";
  /**
   * The serialized value associated with the response. For data fields of type `Date`, the expected format is `yyyy-MM-dd`
   */
  currentValue: string | null;
  fieldName: string;
  valueType: DSValueType;
}

export interface ContinueDecisionSupport_continueDecisionSupport_flowResponse_results {
  __typename: "FlowResults";
  fields: ContinueDecisionSupport_continueDecisionSupport_flowResponse_results_fields[];
}

export interface ContinueDecisionSupport_continueDecisionSupport_flowResponse {
  __typename: "FlowResponse";
  calendarIds: ContinueDecisionSupport_continueDecisionSupport_flowResponse_calendarIds | null;
  elementSet: ContinueDecisionSupport_continueDecisionSupport_flowResponse_elementSet | null;
  enableUrgentCare: boolean;
  finishedDueToKickout: boolean | null;
  flowId: string;
  flowSessionId: string;
  hasValidationErrors: boolean | null;
  isEstablishedPatient: boolean;
  isFlowFinished: boolean | null;
  kickoutMessage: string | null;
  reasonForVisit: string | null;
  results: ContinueDecisionSupport_continueDecisionSupport_flowResponse_results | null;
  validationMessages: string[] | null;
}

export interface ContinueDecisionSupport_continueDecisionSupport {
  __typename: "DecisionSupportResponse";
  errors: ContinueDecisionSupport_continueDecisionSupport_errors[];
  flowResponse: ContinueDecisionSupport_continueDecisionSupport_flowResponse | null;
}

export interface ContinueDecisionSupport {
  continueDecisionSupport: ContinueDecisionSupport_continueDecisionSupport;
}

export interface ContinueDecisionSupportVariables {
  input: ContinueDecisionSupportInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAmWellAuthToken
// ====================================================

export interface createAmWellAuthToken_createAmWellAuthToken_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface createAmWellAuthToken_createAmWellAuthToken {
  __typename: "CreateAmWellAuthTokenResponse";
  errors: createAmWellAuthToken_createAmWellAuthToken_errors[];
  token: string | null;
}

export interface createAmWellAuthToken {
  /**
   * Create an Auth Token tied to the currently authenticated Azure user that the client can use to authenticate via the AmWell SDK
   */
  createAmWellAuthToken: createAmWellAuthToken_createAmWellAuthToken;
}

export interface createAmWellAuthTokenVariables {
  useMyAccount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createCedarPayment
// ====================================================

export interface createCedarPayment_createCedarPayment_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface createCedarPayment_createCedarPayment_payment {
  __typename: "CedarPayment";
  /**
   * ID of payment object
   */
  publicId: string;
}

export interface createCedarPayment_createCedarPayment {
  __typename: "CreateCedarPaymentResponse";
  errors: createCedarPayment_createCedarPayment_errors[];
  /**
   * Returns the newly created payment object
   */
  payment: createCedarPayment_createCedarPayment_payment | null;
}

export interface createCedarPayment {
  /**
   * Create a new payment at the account level, allocating funds to oldest invoices first
   */
  createCedarPayment: createCedarPayment_createCedarPayment;
}

export interface createCedarPaymentVariables {
  mrn: string;
  org: ScmdOrganization;
  paymentMethodId: string;
  amount: string;
  savePaymentMethod: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createCedarPaymentSchedule
// ====================================================

export interface createCedarPaymentSchedule_createCedarPaymentSchedule_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface createCedarPaymentSchedule_createCedarPaymentSchedule_paymentSchedule {
  __typename: "CedarPaymentSchedule";
  /**
   * ID of payment schedule object
   */
  publicId: string;
}

export interface createCedarPaymentSchedule_createCedarPaymentSchedule {
  __typename: "CreateCedarPaymentScheduleResponse";
  errors: createCedarPaymentSchedule_createCedarPaymentSchedule_errors[];
  /**
   * Returns the newly created payment schedule object
   */
  paymentSchedule: createCedarPaymentSchedule_createCedarPaymentSchedule_paymentSchedule | null;
}

export interface createCedarPaymentSchedule {
  /**
   * Creates a payment schedule for a given set of invoices. If invoice_ids is not present, creates a schedule for all payable invoices.
   */
  createCedarPaymentSchedule: createCedarPaymentSchedule_createCedarPaymentSchedule;
}

export interface createCedarPaymentScheduleVariables {
  mrn: string;
  org: ScmdOrganization;
  installmentAmount: string;
  totalAmount: string;
  paymentMethodId: string;
  savePaymentMethod: boolean;
  dateOfFirstPayment: any;
  frequency: PaymentScheduleFrequency;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFeedback
// ====================================================

export interface CreateFeedback_createFeedback_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface CreateFeedback_createFeedback {
  __typename: "ResponseType";
  errors: CreateFeedback_createFeedback_errors[];
}

export interface CreateFeedback {
  /**
   * Create user feedback by sending an email to Summit Health
   */
  createFeedback: CreateFeedback_createFeedback;
}

export interface CreateFeedbackVariables {
  data: CreateFeedbackInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPatient
// ====================================================

export interface createPatient_createPatient_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface createPatient_createPatient_patient {
  __typename: "Patient";
  id: string;
}

export interface createPatient_createPatient {
  __typename: "CreatePatientResponse";
  errors: createPatient_createPatient_errors[];
  patient: createPatient_createPatient_patient | null;
}

export interface createPatient {
  /**
   * Create a Patient in the UCR and then register them into AmWell. Both of these
   * calls act as Create or Update and are idempotent (as long as input doesn't change),
   * so you can feel free to call this mutation more than once if it fails.
   */
  createPatient: createPatient_createPatient;
}

export interface createPatientVariables {
  data: CreatePatientInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createReservation
// ====================================================

export interface createReservation_createReservation_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface createReservation_createReservation_reservation {
  __typename: "Reservation";
  /**
   * The Id for this reservation, which is used to convert a Reservation into a booked Appointment
   */
  id: string;
  expirationTime: any;
}

export interface createReservation_createReservation {
  __typename: "CreateReservationResponse";
  errors: createReservation_createReservation_errors[];
  reservation: createReservation_createReservation_reservation | null;
}

export interface createReservation {
  /**
   * Reserve a TimeSlot for the authenticated user which will expire in 5 minutes. Call the BookAppointment mutation to convert a Reservation to a booked Appointment
   */
  createReservation: createReservation_createReservation;
}

export interface createReservationVariables {
  availabilityId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface CreateUser_createUser {
  __typename: "CreateUserResponse";
  errors: CreateUser_createUser_errors[];
  azureId: string | null;
}

export interface CreateUser {
  /**
   * Creates a new user into Azure B2C using a Graph API Beta call.
   */
  createUser: CreateUser_createUser;
}

export interface CreateUserVariables {
  input: CreateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteCedarPaymentSchedule
// ====================================================

export interface deleteCedarPaymentSchedule_deleteCedarPaymentSchedule_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface deleteCedarPaymentSchedule_deleteCedarPaymentSchedule {
  __typename: "ResponseType";
  errors: deleteCedarPaymentSchedule_deleteCedarPaymentSchedule_errors[];
}

export interface deleteCedarPaymentSchedule {
  /**
   * Delete a payment schedule given an ID
   */
  deleteCedarPaymentSchedule: deleteCedarPaymentSchedule_deleteCedarPaymentSchedule;
}

export interface deleteCedarPaymentScheduleVariables {
  mrn: string;
  org: ScmdOrganization;
  paymentScheduleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRegistrations
// ====================================================

export interface deleteRegistrations_deleteRegistrations_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
  type: ErrorType;
}

export interface deleteRegistrations_deleteRegistrations {
  __typename: "ResponseType";
  errors: deleteRegistrations_deleteRegistrations_errors[];
}

export interface deleteRegistrations {
  /**
   * Remove the device token from the authenticated user's device list. They will no longer receive push notifications to this device
   */
  deleteRegistrations: deleteRegistrations_deleteRegistrations;
}

export interface deleteRegistrationsVariables {
  platform: Platform;
  deviceToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PreviousDecisionSupport
// ====================================================

export interface PreviousDecisionSupport_previousDecisionSupport_errors {
  __typename: "ResponseError";
  attribute: string | null;
  code: number;
  message: string;
  type: ErrorType;
}

export interface PreviousDecisionSupport_previousDecisionSupport_flowResponse_calendarIds {
  __typename: "CalendarIds";
  /**
   * Calendar ids for the selected provider.
   */
  selected: string[] | null;
  /**
   * Calendar ids for providers that are not the selected provider.
   */
  recommended: string[] | null;
}

export interface PreviousDecisionSupport_previousDecisionSupport_flowResponse_elementSet_elements_selectOptions {
  __typename: "FlowResponseSelectOption";
  fieldName: string | null;
  label: string | null;
  value: string | null;
}

export interface PreviousDecisionSupport_previousDecisionSupport_flowResponse_elementSet_elements {
  __typename: "FlowResponseQuestion";
  dateRestrictionType: string | null;
  elementName: string | null;
  elementRef: string;
  elementSetRef: string;
  elementType: string | null;
  falseLabel: string | null;
  fieldName: string | null;
  inputType: DSInputType | null;
  isNoOptionSelectionEnabled: boolean | null;
  isRequired: boolean | null;
  maxLength: number | null;
  maxValue: string | null;
  messageText: string | null;
  minLength: number | null;
  minValue: string | null;
  noOptionSelectionDisplayName: string | null;
  orderIndex: number | null;
  questionText: string | null;
  selectOptions: PreviousDecisionSupport_previousDecisionSupport_flowResponse_elementSet_elements_selectOptions[] | null;
  trueLabel: string | null;
  valueType: DSValueType | null;
}

export interface PreviousDecisionSupport_previousDecisionSupport_flowResponse_elementSet {
  __typename: "FlowResponseElementSet";
  elementSetRef: string;
  elements: PreviousDecisionSupport_previousDecisionSupport_flowResponse_elementSet_elements[] | null;
  title: string | null;
}

export interface PreviousDecisionSupport_previousDecisionSupport_flowResponse_results_fields {
  __typename: "FlowResponseDataField";
  /**
   * The serialized value associated with the response. For data fields of type `Date`, the expected format is `yyyy-MM-dd`
   */
  currentValue: string | null;
  fieldName: string;
  valueType: DSValueType;
}

export interface PreviousDecisionSupport_previousDecisionSupport_flowResponse_results {
  __typename: "FlowResults";
  fields: PreviousDecisionSupport_previousDecisionSupport_flowResponse_results_fields[];
}

export interface PreviousDecisionSupport_previousDecisionSupport_flowResponse {
  __typename: "FlowResponse";
  calendarIds: PreviousDecisionSupport_previousDecisionSupport_flowResponse_calendarIds | null;
  elementSet: PreviousDecisionSupport_previousDecisionSupport_flowResponse_elementSet | null;
  enableUrgentCare: boolean;
  finishedDueToKickout: boolean | null;
  flowId: string;
  flowSessionId: string;
  hasValidationErrors: boolean | null;
  isEstablishedPatient: boolean;
  isFlowFinished: boolean | null;
  kickoutMessage: string | null;
  reasonForVisit: string | null;
  results: PreviousDecisionSupport_previousDecisionSupport_flowResponse_results | null;
  validationMessages: string[] | null;
}

export interface PreviousDecisionSupport_previousDecisionSupport {
  __typename: "DecisionSupportResponse";
  errors: PreviousDecisionSupport_previousDecisionSupport_errors[];
  flowResponse: PreviousDecisionSupport_previousDecisionSupport_flowResponse | null;
}

export interface PreviousDecisionSupport {
  previousDecisionSupport: PreviousDecisionSupport_previousDecisionSupport;
}

export interface PreviousDecisionSupportVariables {
  flowSessionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: registerDevice
// ====================================================

export interface registerDevice_registerDevice_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
  type: ErrorType;
}

export interface registerDevice_registerDevice {
  __typename: "ResponseType";
  errors: registerDevice_registerDevice_errors[];
}

export interface registerDevice {
  /**
   * Add the device token to the authenticated user's device list so they receive push notifications to that device
   */
  registerDevice: registerDevice_registerDevice;
}

export interface registerDeviceVariables {
  platform: Platform;
  deviceToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendPasswordReset
// ====================================================

export interface SendPasswordReset_sendPasswordReset_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface SendPasswordReset_sendPasswordReset {
  __typename: "ResponseType";
  errors: SendPasswordReset_sendPasswordReset_errors[];
}

export interface SendPasswordReset {
  sendPasswordReset: SendPasswordReset_sendPasswordReset;
}

export interface SendPasswordResetVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendVerification
// ====================================================

export interface SendVerification_sendVerification_verification {
  __typename: "TwilioVerificationStatus";
  id: string;
  status: string;
  to: string;
  type: string;
}

export interface SendVerification_sendVerification {
  __typename: "SendTwilioVerificationResponse";
  verification: SendVerification_sendVerification_verification | null;
}

export interface SendVerification {
  /**
   * Sends a verification email/sms
   */
  sendVerification: SendVerification_sendVerification;
}

export interface SendVerificationVariables {
  type: string;
  to: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp
// ====================================================

export interface SignUp_signUp_errors {
  __typename: "ResponseError";
  message: string;
}

export interface SignUp_signUp {
  __typename: "SignUpResponse";
  errors: SignUp_signUp_errors[];
}

export interface SignUp {
  /**
   * Creates a new user into Azure B2C using a Graph API Beta call.
   */
  signUp: SignUp_signUp;
}

export interface SignUpVariables {
  input: SignUpInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartDecisionSupport
// ====================================================

export interface StartDecisionSupport_startDecisionSupport_errors {
  __typename: "ResponseError";
  attribute: string | null;
  code: number;
  message: string;
  /**
   * If this error is coming from a third-party API we can return their internal error code
   */
  thirdPartyCode: string | null;
  type: ErrorType;
  validationType: ValidationType | null;
}

export interface StartDecisionSupport_startDecisionSupport_flowResponse_calendarIds {
  __typename: "CalendarIds";
  /**
   * Calendar ids for the selected provider.
   */
  selected: string[] | null;
  /**
   * Calendar ids for providers that are not the selected provider.
   */
  recommended: string[] | null;
}

export interface StartDecisionSupport_startDecisionSupport_flowResponse_elementSet_elements_selectOptions {
  __typename: "FlowResponseSelectOption";
  fieldName: string | null;
  label: string | null;
  value: string | null;
}

export interface StartDecisionSupport_startDecisionSupport_flowResponse_elementSet_elements {
  __typename: "FlowResponseQuestion";
  dateRestrictionType: string | null;
  elementName: string | null;
  elementRef: string;
  elementSetRef: string;
  elementType: string | null;
  falseLabel: string | null;
  fieldName: string | null;
  inputType: DSInputType | null;
  isNoOptionSelectionEnabled: boolean | null;
  isRequired: boolean | null;
  maxLength: number | null;
  maxValue: string | null;
  messageText: string | null;
  minLength: number | null;
  minValue: string | null;
  noOptionSelectionDisplayName: string | null;
  orderIndex: number | null;
  questionText: string | null;
  selectOptions: StartDecisionSupport_startDecisionSupport_flowResponse_elementSet_elements_selectOptions[] | null;
  trueLabel: string | null;
  valueType: DSValueType | null;
}

export interface StartDecisionSupport_startDecisionSupport_flowResponse_elementSet {
  __typename: "FlowResponseElementSet";
  elementSetRef: string;
  elements: StartDecisionSupport_startDecisionSupport_flowResponse_elementSet_elements[] | null;
  title: string | null;
}

export interface StartDecisionSupport_startDecisionSupport_flowResponse {
  __typename: "FlowResponse";
  calendarIds: StartDecisionSupport_startDecisionSupport_flowResponse_calendarIds | null;
  elementSet: StartDecisionSupport_startDecisionSupport_flowResponse_elementSet | null;
  enableUrgentCare: boolean;
  flowSessionId: string;
  hasValidationErrors: boolean | null;
  isEstablishedPatient: boolean;
  isFlowFinished: boolean | null;
  kickoutMessage: string | null;
  finishedDueToKickout: boolean | null;
  reasonForVisit: string | null;
  validationMessages: string[] | null;
}

export interface StartDecisionSupport_startDecisionSupport {
  __typename: "DecisionSupportResponse";
  errors: StartDecisionSupport_startDecisionSupport_errors[];
  flowResponse: StartDecisionSupport_startDecisionSupport_flowResponse | null;
}

export interface StartDecisionSupport {
  startDecisionSupport: StartDecisionSupport_startDecisionSupport;
}

export interface StartDecisionSupportVariables {
  input: StartDecisionSupportInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDocumentReadStatus
// ====================================================

export interface UpdateDocumentReadStatus_updateDocumentReadStatus_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface UpdateDocumentReadStatus_updateDocumentReadStatus {
  __typename: "ResponseType";
  errors: UpdateDocumentReadStatus_updateDocumentReadStatus_errors[];
}

export interface UpdateDocumentReadStatus {
  /**
   * Update the user's notification settings
   */
  updateDocumentReadStatus: UpdateDocumentReadStatus_updateDocumentReadStatus;
}

export interface UpdateDocumentReadStatusVariables {
  input: UpdateDocumentReadStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateHomeAddress
// ====================================================

export interface UpdateHomeAddress_updateHomeAddress_viewer_user_patient_homeAddress {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export interface UpdateHomeAddress_updateHomeAddress_viewer_user_patient {
  __typename: "Patient";
  /**
   * The patient's home address
   */
  homeAddress: UpdateHomeAddress_updateHomeAddress_viewer_user_patient_homeAddress | null;
}

export interface UpdateHomeAddress_updateHomeAddress_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: UpdateHomeAddress_updateHomeAddress_viewer_user_patient | null;
}

export interface UpdateHomeAddress_updateHomeAddress_viewer {
  __typename: "Viewer";
  user: UpdateHomeAddress_updateHomeAddress_viewer_user;
}

export interface UpdateHomeAddress_updateHomeAddress_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface UpdateHomeAddress_updateHomeAddress {
  __typename: "UpdateHomeAddressResponse";
  viewer: UpdateHomeAddress_updateHomeAddress_viewer | null;
  errors: UpdateHomeAddress_updateHomeAddress_errors[];
}

export interface UpdateHomeAddress {
  /**
   * Update the authenticated user's home address.
   */
  updateHomeAddress: UpdateHomeAddress_updateHomeAddress;
}

export interface UpdateHomeAddressVariables {
  input: UpdateHomeAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLinkedPhoneNumber
// ====================================================

export interface UpdateLinkedPhoneNumber_updateLinkedPhoneNumber_errors {
  __typename: "ResponseError";
  message: string;
}

export interface UpdateLinkedPhoneNumber_updateLinkedPhoneNumber {
  __typename: "ResponseType";
  errors: UpdateLinkedPhoneNumber_updateLinkedPhoneNumber_errors[];
}

export interface UpdateLinkedPhoneNumber {
  /**
   * Updates a linked Phone Number for a AAD user using a Graph API Beta call.
   */
  updateLinkedPhoneNumber: UpdateLinkedPhoneNumber_updateLinkedPhoneNumber;
}

export interface UpdateLinkedPhoneNumberVariables {
  data: UpdateLinkedPhoneNumberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMFAPhoneNumber
// ====================================================

export interface UpdateMFAPhoneNumber_updateMFAPhoneNumber_errors {
  __typename: "ResponseError";
  message: string;
}

export interface UpdateMFAPhoneNumber_updateMFAPhoneNumber {
  __typename: "ResponseType";
  errors: UpdateMFAPhoneNumber_updateMFAPhoneNumber_errors[];
}

export interface UpdateMFAPhoneNumber {
  /**
   * Updates an MFA Phone Number for a AAD user using a Graph API Beta call.
   */
  updateMFAPhoneNumber: UpdateMFAPhoneNumber_updateMFAPhoneNumber;
}

export interface UpdateMFAPhoneNumberVariables {
  data: UpdateMFAPhoneNumberInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateNotificationPreference
// ====================================================

export interface UpdateNotificationPreference_updateNotificationPreference_notificationPreference_appointmentReminders {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive Push Notifications to their mobile devices
   */
  pushNotification: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
}

export interface UpdateNotificationPreference_updateNotificationPreference_notificationPreference_patientInformation {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive Push Notifications to their mobile devices
   */
  pushNotification: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
}

export interface UpdateNotificationPreference_updateNotificationPreference_notificationPreference_dealsAndPromotions {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive Push Notifications to their mobile devices
   */
  pushNotification: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
}

export interface UpdateNotificationPreference_updateNotificationPreference_notificationPreference {
  __typename: "NotificationPreference";
  appointmentReminders: UpdateNotificationPreference_updateNotificationPreference_notificationPreference_appointmentReminders;
  patientInformation: UpdateNotificationPreference_updateNotificationPreference_notificationPreference_patientInformation;
  dealsAndPromotions: UpdateNotificationPreference_updateNotificationPreference_notificationPreference_dealsAndPromotions;
}

export interface UpdateNotificationPreference_updateNotificationPreference_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface UpdateNotificationPreference_updateNotificationPreference {
  __typename: "UpdateNotificationPreferenceResponse";
  notificationPreference: UpdateNotificationPreference_updateNotificationPreference_notificationPreference | null;
  errors: UpdateNotificationPreference_updateNotificationPreference_errors[];
}

export interface UpdateNotificationPreference {
  /**
   * Update the authenticated user's notification preferences
   */
  updateNotificationPreference: UpdateNotificationPreference_updateNotificationPreference;
}

export interface UpdateNotificationPreferenceVariables {
  input: UpdateNotificationPreferenceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePreferredPharmacy
// ====================================================

export interface UpdatePreferredPharmacy_updatePreferredPharmacy_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface UpdatePreferredPharmacy_updatePreferredPharmacy {
  __typename: "ResponseType";
  errors: UpdatePreferredPharmacy_updatePreferredPharmacy_errors[];
}

export interface UpdatePreferredPharmacy {
  /**
   * Update the authenticated user's preferred pharmacy location
   */
  updatePreferredPharmacy: UpdatePreferredPharmacy_updatePreferredPharmacy;
}

export interface UpdatePreferredPharmacyVariables {
  data: UpdatePreferredPharmacyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserPassword
// ====================================================

export interface UpdateUserPassword_updateUserPassword_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface UpdateUserPassword_updateUserPassword {
  __typename: "ResponseType";
  errors: UpdateUserPassword_updateUserPassword_errors[];
}

export interface UpdateUserPassword {
  /**
   * Updates a users password for a AAD user using a Graph API Beta call.
   */
  updateUserPassword: UpdateUserPassword_updateUserPassword;
}

export interface UpdateUserPasswordVariables {
  data: UpdateB2CUserPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateVerification
// ====================================================

export interface UpdateVerification_updateVerification_verification {
  __typename: "TwilioVerificationStatus";
  id: string;
  status: string;
  to: string;
  type: string;
}

export interface UpdateVerification_updateVerification {
  __typename: "UpdateTwilioVerificationResponse";
  verification: UpdateVerification_updateVerification_verification | null;
}

export interface UpdateVerification {
  /**
   * Sends the verification information to twilio updating the current status
   */
  updateVerification: UpdateVerification_updateVerification;
}

export interface UpdateVerificationVariables {
  input: UpdateTwilioVerificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaregiverNotificationPreference
// ====================================================

export interface GetCaregiverNotificationPreference_viewer_user_notificationPreference_caregivers {
  __typename: "CaregiverNotificationPreferenceOption";
  /**
   * Set to true if the caregiver would like to recieve patient appointment notifications
   */
  appointmentReminders: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient information notifications
   */
  patientInformation: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient prescription notifications
   */
  prescriptions: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient company notifications
   */
  dealsAndPromotions: boolean | null;
}

export interface GetCaregiverNotificationPreference_viewer_user_notificationPreference {
  __typename: "NotificationPreference";
  caregivers: GetCaregiverNotificationPreference_viewer_user_notificationPreference_caregivers;
}

export interface GetCaregiverNotificationPreference_viewer_user {
  __typename: "User";
  /**
   * How and what the User would like to be notified about
   */
  notificationPreference: GetCaregiverNotificationPreference_viewer_user_notificationPreference | null;
}

export interface GetCaregiverNotificationPreference_viewer {
  __typename: "Viewer";
  user: GetCaregiverNotificationPreference_viewer_user;
}

export interface GetCaregiverNotificationPreference {
  /**
   * Return the currently authenticated user
   */
  viewer: GetCaregiverNotificationPreference_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCaregiverNotificationPreference
// ====================================================

export interface SetCaregiverNotificationPreference_updateNotificationPreference_errors {
  __typename: "ResponseError";
  message: string;
  type: ErrorType;
}

export interface SetCaregiverNotificationPreference_updateNotificationPreference_notificationPreference_caregivers {
  __typename: "CaregiverNotificationPreferenceOption";
  /**
   * Set to true if the caregiver would like to recieve patient appointment notifications
   */
  appointmentReminders: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient information notifications
   */
  patientInformation: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient prescription notifications
   */
  prescriptions: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient company notifications
   */
  dealsAndPromotions: boolean | null;
}

export interface SetCaregiverNotificationPreference_updateNotificationPreference_notificationPreference {
  __typename: "NotificationPreference";
  caregivers: SetCaregiverNotificationPreference_updateNotificationPreference_notificationPreference_caregivers;
}

export interface SetCaregiverNotificationPreference_updateNotificationPreference {
  __typename: "UpdateNotificationPreferenceResponse";
  errors: SetCaregiverNotificationPreference_updateNotificationPreference_errors[];
  notificationPreference: SetCaregiverNotificationPreference_updateNotificationPreference_notificationPreference | null;
}

export interface SetCaregiverNotificationPreference {
  /**
   * Update the authenticated user's notification preferences
   */
  updateNotificationPreference: SetCaregiverNotificationPreference_updateNotificationPreference;
}

export interface SetCaregiverNotificationPreferenceVariables {
  input: UpdateNotificationPreferenceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmChildCaregiverAccess
// ====================================================

export interface ConfirmChildCaregiverAccess_confirmChildCaregiverAccess_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
  type: ErrorType;
}

export interface ConfirmChildCaregiverAccess_confirmChildCaregiverAccess {
  __typename: "ConfirmChildCaregiverAccessResponse";
  errors: ConfirmChildCaregiverAccess_confirmChildCaregiverAccess_errors[];
}

export interface ConfirmChildCaregiverAccess {
  /**
   * Grant access to the caregiver child access
   */
  confirmChildCaregiverAccess: ConfirmChildCaregiverAccess_confirmChildCaregiverAccess;
}

export interface ConfirmChildCaregiverAccessVariables {
  data: ConfirmChildCaregiverAccessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCaregiverAccess
// ====================================================

export interface CreateCaregiverAccess_createCaregiverAccess_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
  type: ErrorType;
}

export interface CreateCaregiverAccess_createCaregiverAccess_telecom {
  __typename: "CaregiverTelecom";
  phone: string | null;
  email: string | null;
}

export interface CreateCaregiverAccess_createCaregiverAccess_results_success {
  __typename: "CaregiverPatientDetails";
  firstName: string;
  lastName: string;
}

export interface CreateCaregiverAccess_createCaregiverAccess_results_failed {
  __typename: "CaregiverPatientDetails";
  firstName: string;
  lastName: string;
}

export interface CreateCaregiverAccess_createCaregiverAccess_results {
  __typename: "CaregiverAccessResults";
  /**
   * List of account creations that succeeded
   */
  success: CreateCaregiverAccess_createCaregiverAccess_results_success[] | null;
  /**
   * List of account creations that failed
   */
  failed: CreateCaregiverAccess_createCaregiverAccess_results_failed[] | null;
}

export interface CreateCaregiverAccess_createCaregiverAccess {
  __typename: "CreateCaregiverAccessResponse";
  errors: CreateCaregiverAccess_createCaregiverAccess_errors[];
  /**
   * Telecom details
   */
  telecom: CreateCaregiverAccess_createCaregiverAccess_telecom | null;
  /**
   * Results of success/failed
   */
  results: CreateCaregiverAccess_createCaregiverAccess_results | null;
  /**
   * Access Identifier
   */
  accessId: string | null;
  /**
   * Creates a caregiver access request
   */
  status: CaregiverStatus | null;
}

export interface CreateCaregiverAccess {
  /**
   * Create caregiver access for either a child dependent or adult caregiver
   */
  createCaregiverAccess: CreateCaregiverAccess_createCaregiverAccess;
}

export interface CreateCaregiverAccessVariables {
  data: CreateCaregiverAccessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PhoneNumber
// ====================================================

export interface PhoneNumber_viewer_user_account {
  __typename: "UserAccount";
  phoneNumber: string | null;
}

export interface PhoneNumber_viewer_user {
  __typename: "User";
  /**
   * SCMD specific account information
   */
  account: PhoneNumber_viewer_user_account;
}

export interface PhoneNumber_viewer {
  __typename: "Viewer";
  user: PhoneNumber_viewer_user;
}

export interface PhoneNumber {
  /**
   * Return the currently authenticated user
   */
  viewer: PhoneNumber_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAmwellFlag
// ====================================================

export interface GetAmwellFlag {
  /**
   * Fetch the current feature flags
   */
  amwellCouponEnabled: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserAccount
// ====================================================

export interface UserAccount_auth {
  __typename: "LocalAuth";
  isAuthenticated: boolean | null;
}

export interface UserAccount_viewer_user_account {
  __typename: "UserAccount";
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  verificationStatus: string | null;
  emailVerificationStatus: string | null;
  phoneVerificationStatus: string | null;
  gender: string | null;
  line1: string | null;
  line2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export interface UserAccount_viewer_user {
  __typename: "User";
  /**
   * SCMD specific account information
   */
  account: UserAccount_viewer_user_account;
}

export interface UserAccount_viewer {
  __typename: "Viewer";
  user: UserAccount_viewer_user;
}

export interface UserAccount {
  auth: UserAccount_auth | null;
  /**
   * Return the currently authenticated user
   */
  viewer: UserAccount_viewer | null;
}

export interface UserAccountVariables {
  isAuthenticated?: boolean | null;
  useMyAccount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProviderDetailsQuery
// ====================================================

export interface ProviderDetailsQuery_contactInfo {
  __typename: "ContactInfo";
  /**
   * Name of the Support Team.
   */
  name: string | null;
  phoneNumber: string | null;
}

export interface ProviderDetailsQuery_provider_facilities_address {
  __typename: "Address";
  city: string | null;
  line1: string | null;
  line2: string | null;
  postalCode: string | null;
  state: string | null;
}

export interface ProviderDetailsQuery_provider_facilities_coordinates {
  __typename: "Coordinates";
  lat: number;
  lng: number;
}

export interface ProviderDetailsQuery_provider_facilities {
  __typename: "Facility";
  address: ProviderDetailsQuery_provider_facilities_address | null;
  coordinates: ProviderDetailsQuery_provider_facilities_coordinates | null;
  facilityNameLong: string | null;
  id: number;
}

export interface ProviderDetailsQuery_provider_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface ProviderDetailsQuery_provider_qualifications {
  __typename: "Qualification";
  description: string | null;
  type: string | null;
}

export interface ProviderDetailsQuery_provider {
  __typename: "Provider";
  athenaId: string | null;
  bio: string | null;
  experianFlowId: string | null;
  /**
   * Facilities this Provider practices at
   */
  facilities: ProviderDetailsQuery_provider_facilities[] | null;
  id: string;
  languages: Language[];
  name: ProviderDetailsQuery_provider_name | null;
  nameWithDesignations: string;
  photoUrl: string | null;
  primarySpecialty: string | null;
  qualifications: ProviderDetailsQuery_provider_qualifications[] | null;
}

export interface ProviderDetailsQuery {
  /**
   * Returns contact information for each Support Team.
   */
  contactInfo: ProviderDetailsQuery_contactInfo[];
  /**
   * Fetch a single Provider by Id
   */
  provider: ProviderDetailsQuery_provider | null;
}

export interface ProviderDetailsQueryVariables {
  providerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BuildDynamicLink
// ====================================================

export interface BuildDynamicLink_buildDynamicLink {
  __typename: "BuildDynamicLinkResponse";
  link: string | null;
}

export interface BuildDynamicLink {
  /**
   * Given a UCR Appointment ID, retrieve the corresponding Epion Previsit Status URL.
   */
  buildDynamicLink: BuildDynamicLink_buildDynamicLink;
}

export interface BuildDynamicLinkVariables {
  input: BuildDynamicLinkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCedarPaymentModal
// ====================================================

export interface GetCedarPaymentModal_billingAccount_account_paymentMethods {
  __typename: "CedarPaymentMethod";
  paymentMethodId: string;
  paymentMethodType: PaymentMethodType;
  issuer: string;
  last4: string;
  expirationDate: string;
}

export interface GetCedarPaymentModal_billingAccount_account {
  __typename: "CedarAccount";
  paymentMethods: GetCedarPaymentModal_billingAccount_account_paymentMethods[];
}

export interface GetCedarPaymentModal_billingAccount {
  __typename: "CedarBillingAccount";
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  /**
   * Fetch the user's account info
   */
  account: GetCedarPaymentModal_billingAccount_account | null;
}

export interface GetCedarPaymentModal {
  billingAccount: GetCedarPaymentModal_billingAccount;
}

export interface GetCedarPaymentModalVariables {
  patientMrn: string;
  organization: ScmdOrganization;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPaymentPlanDetails
// ====================================================

export interface GetPaymentPlanDetails_viewer_user_patient_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface GetPaymentPlanDetails_viewer_user_patient {
  __typename: "Patient";
  name: GetPaymentPlanDetails_viewer_user_patient_name | null;
}

export interface GetPaymentPlanDetails_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: GetPaymentPlanDetails_viewer_user_patient | null;
}

export interface GetPaymentPlanDetails_viewer {
  __typename: "Viewer";
  user: GetPaymentPlanDetails_viewer_user;
}

export interface GetPaymentPlanDetails_billingAccount_summary {
  __typename: "CedarSummary";
  /**
   * The total amount due of all payable invoices
   */
  totalAmountDue: number;
}

export interface GetPaymentPlanDetails_billingAccount_paymentOptions_installmentOptions_monthly {
  __typename: "CedarInstallmentPayment";
  /**
   * Installment amount
   */
  amount: number;
  /**
   * Number of installments
   */
  numPayments: number;
  /**
   * True if the final payment is smaller
   */
  hasPartial: boolean;
  /**
   * Decimal amount of the final payment
   */
  lastPayment: number;
}

export interface GetPaymentPlanDetails_billingAccount_paymentOptions_installmentOptions_biweekly {
  __typename: "CedarInstallmentPayment";
  /**
   * Installment amount
   */
  amount: number;
  /**
   * Number of installments
   */
  numPayments: number;
  /**
   * True if the final payment is smaller
   */
  hasPartial: boolean;
  /**
   * Decimal amount of the final payment
   */
  lastPayment: number;
}

export interface GetPaymentPlanDetails_billingAccount_paymentOptions_installmentOptions_weekly {
  __typename: "CedarInstallmentPayment";
  /**
   * Installment amount
   */
  amount: number;
  /**
   * Number of installments
   */
  numPayments: number;
  /**
   * True if the final payment is smaller
   */
  hasPartial: boolean;
  /**
   * Decimal amount of the final payment
   */
  lastPayment: number;
}

export interface GetPaymentPlanDetails_billingAccount_paymentOptions_installmentOptions {
  __typename: "CedarInstallmentOptions";
  monthly: GetPaymentPlanDetails_billingAccount_paymentOptions_installmentOptions_monthly[] | null;
  biweekly: GetPaymentPlanDetails_billingAccount_paymentOptions_installmentOptions_biweekly[] | null;
  weekly: GetPaymentPlanDetails_billingAccount_paymentOptions_installmentOptions_weekly[] | null;
}

export interface GetPaymentPlanDetails_billingAccount_paymentOptions {
  __typename: "CedarPaymentOptions";
  startDateOptions: any[];
  /**
   * The template payment schedule contract to be accepted by the user. The template uses lodash _.template syntax, with these variables:
   *   <%= first_name %> Payer first name
   *   <%= last_name %> Payer last name
   *   <%= installment_amount %> Installment amount <%= period %> Payment period, one of “month”, “week”, or “two weeks”
   *   <%= invoice_balance %> Total amount of the payment schedule
   */
  contractTemplate: string;
  /**
   * Dictionary of installment options. Frequency is one of 'monthly', 'weekly', 'biweekly'
   */
  installmentOptions: GetPaymentPlanDetails_billingAccount_paymentOptions_installmentOptions;
}

export interface GetPaymentPlanDetails_billingAccount {
  __typename: "CedarBillingAccount";
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  /**
   * Fetch the user's summary
   */
  summary: GetPaymentPlanDetails_billingAccount_summary | null;
  /**
   * Fetch the user's payment options
   */
  paymentOptions: GetPaymentPlanDetails_billingAccount_paymentOptions | null;
}

export interface GetPaymentPlanDetails {
  /**
   * Return the currently authenticated user
   */
  viewer: GetPaymentPlanDetails_viewer | null;
  billingAccount: GetPaymentPlanDetails_billingAccount;
}

export interface GetPaymentPlanDetailsVariables {
  patientMrn: string;
  organization: ScmdOrganization;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBillingAccount
// ====================================================

export interface GetBillingAccount_billingAccount_summary {
  __typename: "CedarSummary";
  /**
   * The total amount due of all payable invoices
   */
  totalAmountDue: number;
}

export interface GetBillingAccount_billingAccount_paymentSchedules_data {
  __typename: "CedarPaymentSchedule";
  /**
   * ID of payment schedule object
   */
  publicId: string;
  /**
   * Total amount associated with payment plan
   */
  totalAmount: number;
  /**
   * Amount per installment
   */
  installmentAmount: number;
  /**
   * Array of invoices IDs associated with payment schedule
   */
  invoices: string[];
  /**
   * Recurrence rule
   */
  frequency: PaymentScheduleFrequency;
  /**
   * Number of payments successfully made to date
   */
  installmentsPaid: number;
  /**
   * Number of payments remaining to be made (including the final payment which may be a different amount)
   */
  installmentsRemaining: number;
  canceledOn: any | null;
  completedOn: any | null;
  /**
   * Payment method type (credit_card | ach)
   */
  paymentMethodType: PaymentMethodType;
}

export interface GetBillingAccount_billingAccount_paymentSchedules {
  __typename: "PaymentSchedulesResponse";
  /**
   * Array of all invoices for the patient
   */
  data: GetBillingAccount_billingAccount_paymentSchedules_data[];
}

export interface GetBillingAccount_billingAccount_invoices_data_patientActivityEvents {
  __typename: "CedarInvoicePatientActivityEvent";
  /**
   * Date on which event occurred
   */
  date: any;
}

export interface GetBillingAccount_billingAccount_invoices_data {
  __typename: "CedarInvoice";
  /**
   * Name of doctor, NP, or nurse that provided services
   */
  clinicianName: string;
  dateIssued: any | null;
  /**
   * Unique Cedar identifier for each invoice
   */
  publicId: string;
  /**
   * Amount remaining on invoice (post-provider adj.)
   */
  remainingAmount: number;
  /**
   * Invoice status with predefined set of values
   */
  state: InvoiceState;
  /**
   * Total charge amount of invoice before insurance
   */
  totalAmountDue: number;
  /**
   * A timeline of the evolution of the invoice
   */
  patientActivityEvents: GetBillingAccount_billingAccount_invoices_data_patientActivityEvents[];
}

export interface GetBillingAccount_billingAccount_invoices {
  __typename: "InvoicesResponse";
  /**
   * Array of all invoices for the patient
   */
  data: GetBillingAccount_billingAccount_invoices_data[];
}

export interface GetBillingAccount_billingAccount_completeInvoices_data_patientActivityEvents {
  __typename: "CedarInvoicePatientActivityEvent";
  /**
   * Date on which event occurred
   */
  date: any;
}

export interface GetBillingAccount_billingAccount_completeInvoices_data {
  __typename: "CedarInvoice";
  /**
   * Name of doctor, NP, or nurse that provided services
   */
  clinicianName: string;
  dateIssued: any | null;
  /**
   * Unique Cedar identifier for each invoice
   */
  publicId: string;
  /**
   * Amount remaining on invoice (post-provider adj.)
   */
  remainingAmount: number;
  /**
   * Invoice status with predefined set of values
   */
  state: InvoiceState;
  /**
   * Total charge amount of invoice before insurance
   */
  totalAmountDue: number;
  /**
   * A timeline of the evolution of the invoice
   */
  patientActivityEvents: GetBillingAccount_billingAccount_completeInvoices_data_patientActivityEvents[];
}

export interface GetBillingAccount_billingAccount_completeInvoices {
  __typename: "InvoicesResponse";
  hasNextPage: boolean;
  /**
   * Array of all invoices for the patient
   */
  data: GetBillingAccount_billingAccount_completeInvoices_data[];
}

export interface GetBillingAccount_billingAccount {
  __typename: "CedarBillingAccount";
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  entity: ScmdOrganization | null;
  /**
   * Fetch the user's summary
   */
  summary: GetBillingAccount_billingAccount_summary | null;
  /**
   * Fetch the user's payment options
   */
  paymentSchedules: GetBillingAccount_billingAccount_paymentSchedules | null;
  /**
   * Fetch the user's list of invoices
   */
  invoices: GetBillingAccount_billingAccount_invoices | null;
  /**
   * Fetch the user's list of invoices
   */
  completeInvoices: GetBillingAccount_billingAccount_completeInvoices | null;
}

export interface GetBillingAccount {
  billingAccount: GetBillingAccount_billingAccount;
}

export interface GetBillingAccountVariables {
  patientMrn: string;
  organization: ScmdOrganization;
  completeInvoicesLimit: number;
  completeInvoicesOffset: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BillingAccounts
// ====================================================

export interface BillingAccounts_viewer_user_billingAccounts_summary {
  __typename: "CedarSummary";
  /**
   * The number of payable/outstanding invoices
   */
  invoiceCount: number;
}

export interface BillingAccounts_viewer_user_billingAccounts {
  __typename: "CedarBillingAccount";
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  entity: ScmdOrganization | null;
  /**
   * Fetch the user's summary
   */
  summary: BillingAccounts_viewer_user_billingAccounts_summary | null;
}

export interface BillingAccounts_viewer_user {
  __typename: "User";
  /**
   * BillingAccounts for a Patient, which may span many different organizations
   */
  billingAccounts: BillingAccounts_viewer_user_billingAccounts[] | null;
}

export interface BillingAccounts_viewer {
  __typename: "Viewer";
  user: BillingAccounts_viewer_user;
}

export interface BillingAccounts {
  /**
   * Return the currently authenticated user
   */
  viewer: BillingAccounts_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReceipt
// ====================================================

export interface GetReceipt_billingAccount_receipt {
  __typename: "CedarReceipt";
  contentType: string | null;
  data: string | null;
  title: string | null;
}

export interface GetReceipt_billingAccount {
  __typename: "CedarBillingAccount";
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  /**
   * Fetch a receipt for a single payment given an id
   */
  receipt: GetReceipt_billingAccount_receipt | null;
}

export interface GetReceipt {
  billingAccount: GetReceipt_billingAccount;
}

export interface GetReceiptVariables {
  mrn: string;
  org: ScmdOrganization;
  paymentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInvoiceDetails
// ====================================================

export interface GetInvoiceDetails_billingAccount_invoice_lineItems {
  __typename: "CedarInvoiceLineItem";
  /**
   * Title of the line item charge
   */
  descriptionTitle: string;
  /**
   * Amount charged for line item
   */
  chargeAmount: number;
  dateOfService: any | null;
  /**
   * Description of the line item charge
   */
  descriptionBody: string;
}

export interface GetInvoiceDetails_billingAccount_invoice_patientActivityEvents {
  __typename: "CedarInvoicePatientActivityEvent";
  /**
   * Description of event (suitable to display to patient)
   */
  description: string;
  /**
   * Date on which event occurred
   */
  date: any;
  /**
   * Returns true / false if event has been completed (will be false for events that are scheduled but have not occurred yet)
   */
  completed: boolean;
  /**
   * The ID of the payment associated with this event
   */
  paymentId: string | null;
  /**
   * The ID of the payment schedule associated with this event
   */
  paymentScheduleId: string | null;
}

export interface GetInvoiceDetails_billingAccount_invoice {
  __typename: "CedarInvoice";
  /**
   * Unique Cedar identifier for each invoice
   */
  publicId: string;
  /**
   * Amount remaining on invoice (post-provider adj.)
   */
  remainingAmount: number;
  /**
   * Total charge amount of invoice before insurance
   */
  totalAmountDue: number;
  /**
   * Total amount of insurance provider reimbursements
   */
  insuranceCoverageAmount: number;
  dateDue: any | null;
  dateIssued: any | null;
  /**
   * Invoice status with predefined set of values
   */
  state: InvoiceState;
  /**
   * Primary insurer name
   */
  primaryInsurer: string | null;
  /**
   * Secondary insurer name
   */
  secondaryInsurer: string | null;
  /**
   * Tertiary insurer name
   */
  tertiaryInsurer: string | null;
  /**
   * Name of doctor, NP, or nurse that provided services
   */
  clinicianName: string;
  /**
   * Individual invoice line items
   */
  lineItems: GetInvoiceDetails_billingAccount_invoice_lineItems[];
  /**
   * A timeline of the evolution of the invoice
   */
  patientActivityEvents: GetInvoiceDetails_billingAccount_invoice_patientActivityEvents[];
}

export interface GetInvoiceDetails_billingAccount {
  __typename: "CedarBillingAccount";
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  /**
   * Fetch a single invoice by ID
   */
  invoice: GetInvoiceDetails_billingAccount_invoice | null;
}

export interface GetInvoiceDetails {
  billingAccount: GetInvoiceDetails_billingAccount;
}

export interface GetInvoiceDetailsVariables {
  patientMrn: string;
  organization: ScmdOrganization;
  publicId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CaregiverPortalQuery
// ====================================================

export interface CaregiverPortalQuery {
  /**
   * Portal for new caregiver access
   */
  caregiverPortal: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmCaregiverAccess
// ====================================================

export interface ConfirmCaregiverAccess_confirmInviteCaregiverAccess_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface ConfirmCaregiverAccess_confirmInviteCaregiverAccess {
  __typename: "ConfirmInviteCaregiverAccessResponse";
  errors: ConfirmCaregiverAccess_confirmInviteCaregiverAccess_errors[];
  name: string | null;
  /**
   * Returns Success when access is granted or Expired if the access is no longer valid
   */
  status: CaregiverStatus | null;
}

export interface ConfirmCaregiverAccess {
  /**
   * Confirm that the account has been revoked
   */
  confirmInviteCaregiverAccess: ConfirmCaregiverAccess_confirmInviteCaregiverAccess;
}

export interface ConfirmCaregiverAccessVariables {
  data: ConfirmInviteCaregiverAccessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmRevokedCaregiverAccess
// ====================================================

export interface ConfirmRevokedCaregiverAccess_confirmRevokedCaregiverAccess_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
  type: ErrorType;
}

export interface ConfirmRevokedCaregiverAccess_confirmRevokedCaregiverAccess {
  __typename: "ConfirmRevokedCaregiverAccessResponse";
  errors: ConfirmRevokedCaregiverAccess_confirmRevokedCaregiverAccess_errors[];
  /**
   * Marks the caregivers account as revoked
   */
  status: CaregiverStatus | null;
}

export interface ConfirmRevokedCaregiverAccess {
  /**
   * Confirm that the account has been revoked
   */
  confirmRevokedCaregiverAccess: ConfirmRevokedCaregiverAccess_confirmRevokedCaregiverAccess;
}

export interface ConfirmRevokedCaregiverAccessVariables {
  data: ConfirmRevokedCaregiverAccessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCaregiverAccess
// ====================================================

export interface DeleteCaregiverAccess_deleteCaregiverAccess_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface DeleteCaregiverAccess_deleteCaregiverAccess {
  __typename: "DeleteCaregiverAccessResponse";
  errors: DeleteCaregiverAccess_deleteCaregiverAccess_errors[];
}

export interface DeleteCaregiverAccess {
  /**
   * Revoke caregiver access optional pass in isCancelled to remove complete access
   */
  deleteCaregiverAccess: DeleteCaregiverAccess_deleteCaregiverAccess;
}

export interface DeleteCaregiverAccessVariables {
  data: DeleteCaregiverAccessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCaregiverReminder
// ====================================================

export interface SendCaregiverReminder_sendCaregiverAccessReminder_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface SendCaregiverReminder_sendCaregiverAccessReminder {
  __typename: "SendCaregiverAccessReminderResponse";
  errors: SendCaregiverReminder_sendCaregiverAccessReminder_errors[];
}

export interface SendCaregiverReminder {
  /**
   * Resend email to invite the user
   */
  sendCaregiverAccessReminder: SendCaregiverReminder_sendCaregiverAccessReminder;
}

export interface SendCaregiverReminderVariables {
  data: SendCaregiverAccessReminderInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCaregiverAccess
// ====================================================

export interface UpdateCaregiverAccess_updateCaregiverAccess_errors {
  __typename: "ResponseError";
  message: string;
  code: number;
}

export interface UpdateCaregiverAccess_updateCaregiverAccess {
  __typename: "UpdateCaregiverAccessResponse";
  errors: UpdateCaregiverAccess_updateCaregiverAccess_errors[];
}

export interface UpdateCaregiverAccess {
  /**
   * Updates a caregivers access
   */
  updateCaregiverAccess: UpdateCaregiverAccess_updateCaregiverAccess;
}

export interface UpdateCaregiverAccessVariables {
  data: UpdateCaregiverAccessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CaregiverAccessDetails
// ====================================================

export interface CaregiverAccessDetails_viewer_user_caregiverAccess_address {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  state: string | null;
  city: string | null;
  postalCode: string | null;
}

export interface CaregiverAccessDetails_viewer_user_caregiverAccess_statusDetails {
  __typename: "CaregiverAccessDetails";
  status: CaregiverStatus;
  statusName: string | null;
  createdDate: any | null;
  acceptedDate: any | null;
  revokedDate: any | null;
}

export interface CaregiverAccessDetails_viewer_user_caregiverAccess_contact {
  __typename: "CaregiverAccessContact";
  phone: string | null;
  email: string | null;
}

export interface CaregiverAccessDetails_viewer_user_caregiverAccess {
  __typename: "CaregiverAccess";
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  gender: AdministrativeGender | null;
  isMinor: boolean | null;
  hasAccount: boolean | null;
  address: CaregiverAccessDetails_viewer_user_caregiverAccess_address | null;
  statusDetails: CaregiverAccessDetails_viewer_user_caregiverAccess_statusDetails;
  contact: CaregiverAccessDetails_viewer_user_caregiverAccess_contact | null;
}

export interface CaregiverAccessDetails_viewer_user_caregivers_address {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  state: string | null;
  city: string | null;
  postalCode: string | null;
}

export interface CaregiverAccessDetails_viewer_user_caregivers_statusDetails {
  __typename: "CaregiverAccessDetails";
  status: CaregiverStatus;
  statusName: string | null;
  createdDate: any | null;
  acceptedDate: any | null;
  revokedDate: any | null;
}

export interface CaregiverAccessDetails_viewer_user_caregivers_contact {
  __typename: "CaregiverAccessContact";
  phone: string | null;
  email: string | null;
}

export interface CaregiverAccessDetails_viewer_user_caregivers {
  __typename: "CaregiverAccess";
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  hasAccount: boolean | null;
  gender: AdministrativeGender | null;
  address: CaregiverAccessDetails_viewer_user_caregivers_address | null;
  pendingAccountCreation: boolean | null;
  statusDetails: CaregiverAccessDetails_viewer_user_caregivers_statusDetails;
  contact: CaregiverAccessDetails_viewer_user_caregivers_contact | null;
}

export interface CaregiverAccessDetails_viewer_user {
  __typename: "User";
  /**
   * Return a list of patients that the patient is a caregiver for
   */
  caregiverAccess: CaregiverAccessDetails_viewer_user_caregiverAccess[] | null;
  /**
   * Return a list of caregivers that have access to my account
   */
  caregivers: CaregiverAccessDetails_viewer_user_caregivers[] | null;
}

export interface CaregiverAccessDetails_viewer {
  __typename: "Viewer";
  user: CaregiverAccessDetails_viewer_user;
}

export interface CaregiverAccessDetails {
  /**
   * Return the currently authenticated user
   */
  viewer: CaregiverAccessDetails_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFeedbackTopics
// ====================================================

export interface GetFeedbackTopics_feedbackTopics {
  __typename: "FeedbackTopic";
  id: string;
  title: string;
  tag: string;
}

export interface GetFeedbackTopics {
  /**
   * Fetch a list of feedback topics
   */
  feedbackTopics: GetFeedbackTopics_feedbackTopics[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsuranceList
// ====================================================

export interface InsuranceList_viewer_user_patient_insuranceInfo {
  __typename: "PatientInsurance";
  companyName: string | null;
  memberId: string | null;
  groupNumber: string | null;
  order: number | null;
}

export interface InsuranceList_viewer_user_patient {
  __typename: "Patient";
  /**
   * Returns a list of the patient's insurance information sorted by `order`.
   */
  insuranceInfo: InsuranceList_viewer_user_patient_insuranceInfo[] | null;
}

export interface InsuranceList_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: InsuranceList_viewer_user_patient | null;
}

export interface InsuranceList_viewer {
  __typename: "Viewer";
  user: InsuranceList_viewer_user;
}

export interface InsuranceList {
  /**
   * Return the currently authenticated user
   */
  viewer: InsuranceList_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactSupportQuery
// ====================================================

export interface ContactSupportQuery_contactInfo {
  __typename: "ContactInfo";
  /**
   * Name of the Support Team.
   */
  name: string | null;
  phoneNumber: string | null;
  faxNumber: string | null;
  emailAddress: string | null;
}

export interface ContactSupportQuery {
  /**
   * Returns contact information for each Support Team.
   */
  contactInfo: ContactSupportQuery_contactInfo[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBillingAccountSettings
// ====================================================

export interface GetBillingAccountSettings_viewer_user_billingAccounts_settings {
  __typename: "CedarSettings";
  email: boolean | null;
  paperMail: boolean | null;
  phoneCall: boolean | null;
  phoneText: boolean | null;
  ringlessCall: boolean | null;
}

export interface GetBillingAccountSettings_viewer_user_billingAccounts {
  __typename: "CedarBillingAccount";
  entity: ScmdOrganization | null;
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  /**
   * Fetch the user's notification settings
   */
  settings: GetBillingAccountSettings_viewer_user_billingAccounts_settings | null;
}

export interface GetBillingAccountSettings_viewer_user {
  __typename: "User";
  /**
   * BillingAccounts for a Patient, which may span many different organizations
   */
  billingAccounts: GetBillingAccountSettings_viewer_user_billingAccounts[] | null;
}

export interface GetBillingAccountSettings_viewer {
  __typename: "Viewer";
  user: GetBillingAccountSettings_viewer_user;
}

export interface GetBillingAccountSettings {
  /**
   * Return the currently authenticated user
   */
  viewer: GetBillingAccountSettings_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetBillingAccountSettings
// ====================================================

export interface SetBillingAccountSettings_updateCedarSettings_errors {
  __typename: "ResponseError";
  code: number;
  message: string;
}

export interface SetBillingAccountSettings_updateCedarSettings_settings {
  __typename: "CedarSettings";
  email: boolean | null;
  paperMail: boolean | null;
  phoneCall: boolean | null;
  phoneText: boolean | null;
  ringlessCall: boolean | null;
}

export interface SetBillingAccountSettings_updateCedarSettings {
  __typename: "UpdateCedarSettingsResponse";
  errors: SetBillingAccountSettings_updateCedarSettings_errors[];
  /**
   * The updated notification settings
   */
  settings: SetBillingAccountSettings_updateCedarSettings_settings | null;
}

export interface SetBillingAccountSettings {
  /**
   * Update the user's notification settings
   */
  updateCedarSettings: SetBillingAccountSettings_updateCedarSettings;
}

export interface SetBillingAccountSettingsVariables {
  input: UpdateCedarSettingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNotificationPreference
// ====================================================

export interface GetNotificationPreference_viewer_user_notificationPreference_appointmentReminders {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface GetNotificationPreference_viewer_user_notificationPreference_patientInformation {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface GetNotificationPreference_viewer_user_notificationPreference_prescriptions {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface GetNotificationPreference_viewer_user_notificationPreference_dealsAndPromotions {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface GetNotificationPreference_viewer_user_notificationPreference_caregivers {
  __typename: "CaregiverNotificationPreferenceOption";
  /**
   * Set to true if the caregiver would like to recieve patient appointment notifications
   */
  appointmentReminders: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient information notifications
   */
  patientInformation: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient prescription notifications
   */
  prescriptions: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient company notifications
   */
  dealsAndPromotions: boolean | null;
}

export interface GetNotificationPreference_viewer_user_notificationPreference {
  __typename: "NotificationPreference";
  appointmentReminders: GetNotificationPreference_viewer_user_notificationPreference_appointmentReminders;
  patientInformation: GetNotificationPreference_viewer_user_notificationPreference_patientInformation;
  prescriptions: GetNotificationPreference_viewer_user_notificationPreference_prescriptions;
  dealsAndPromotions: GetNotificationPreference_viewer_user_notificationPreference_dealsAndPromotions;
  caregivers: GetNotificationPreference_viewer_user_notificationPreference_caregivers;
}

export interface GetNotificationPreference_viewer_user {
  __typename: "User";
  /**
   * How and what the User would like to be notified about
   */
  notificationPreference: GetNotificationPreference_viewer_user_notificationPreference | null;
}

export interface GetNotificationPreference_viewer {
  __typename: "Viewer";
  user: GetNotificationPreference_viewer_user;
}

export interface GetNotificationPreference {
  /**
   * Return the currently authenticated user
   */
  viewer: GetNotificationPreference_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetNotificationPreference
// ====================================================

export interface SetNotificationPreference_updateNotificationPreference_errors {
  __typename: "ResponseError";
  message: string;
  type: ErrorType;
}

export interface SetNotificationPreference_updateNotificationPreference_notificationPreference_appointmentReminders {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface SetNotificationPreference_updateNotificationPreference_notificationPreference_patientInformation {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface SetNotificationPreference_updateNotificationPreference_notificationPreference_prescriptions {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface SetNotificationPreference_updateNotificationPreference_notificationPreference_dealsAndPromotions {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface SetNotificationPreference_updateNotificationPreference_notificationPreference_caregivers {
  __typename: "CaregiverNotificationPreferenceOption";
  /**
   * Set to true if the caregiver would like to recieve patient appointment notifications
   */
  appointmentReminders: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient information notifications
   */
  patientInformation: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient prescription notifications
   */
  prescriptions: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient company notifications
   */
  dealsAndPromotions: boolean | null;
}

export interface SetNotificationPreference_updateNotificationPreference_notificationPreference {
  __typename: "NotificationPreference";
  appointmentReminders: SetNotificationPreference_updateNotificationPreference_notificationPreference_appointmentReminders;
  patientInformation: SetNotificationPreference_updateNotificationPreference_notificationPreference_patientInformation;
  prescriptions: SetNotificationPreference_updateNotificationPreference_notificationPreference_prescriptions;
  dealsAndPromotions: SetNotificationPreference_updateNotificationPreference_notificationPreference_dealsAndPromotions;
  caregivers: SetNotificationPreference_updateNotificationPreference_notificationPreference_caregivers;
}

export interface SetNotificationPreference_updateNotificationPreference {
  __typename: "UpdateNotificationPreferenceResponse";
  errors: SetNotificationPreference_updateNotificationPreference_errors[];
  notificationPreference: SetNotificationPreference_updateNotificationPreference_notificationPreference | null;
}

export interface SetNotificationPreference {
  /**
   * Update the authenticated user's notification preferences
   */
  updateNotificationPreference: SetNotificationPreference_updateNotificationPreference;
}

export interface SetNotificationPreferenceVariables {
  input: UpdateNotificationPreferenceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindCreateNotificationPreferences
// ====================================================

export interface FindCreateNotificationPreferences_viewer_user_billingAccounts_settings {
  __typename: "CedarSettings";
  email: boolean | null;
  paperMail: boolean | null;
  phoneCall: boolean | null;
  phoneText: boolean | null;
  ringlessCall: boolean | null;
}

export interface FindCreateNotificationPreferences_viewer_user_billingAccounts {
  __typename: "CedarBillingAccount";
  entity: ScmdOrganization | null;
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  /**
   * Fetch the user's notification settings
   */
  settings: FindCreateNotificationPreferences_viewer_user_billingAccounts_settings | null;
}

export interface FindCreateNotificationPreferences_viewer_user_notificationPreference_appointmentReminders {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface FindCreateNotificationPreferences_viewer_user_notificationPreference_patientInformation {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface FindCreateNotificationPreferences_viewer_user_notificationPreference_prescriptions {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface FindCreateNotificationPreferences_viewer_user_notificationPreference_dealsAndPromotions {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

export interface FindCreateNotificationPreferences_viewer_user_notificationPreference_caregivers {
  __typename: "CaregiverNotificationPreferenceOption";
  /**
   * Set to true if the caregiver would like to recieve patient appointment notifications
   */
  appointmentReminders: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient information notifications
   */
  patientInformation: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient prescription notifications
   */
  prescriptions: boolean | null;
  /**
   * Set to true if the caregiver would like to recieve patient company notifications
   */
  dealsAndPromotions: boolean | null;
}

export interface FindCreateNotificationPreferences_viewer_user_notificationPreference {
  __typename: "NotificationPreference";
  appointmentReminders: FindCreateNotificationPreferences_viewer_user_notificationPreference_appointmentReminders;
  patientInformation: FindCreateNotificationPreferences_viewer_user_notificationPreference_patientInformation;
  prescriptions: FindCreateNotificationPreferences_viewer_user_notificationPreference_prescriptions;
  dealsAndPromotions: FindCreateNotificationPreferences_viewer_user_notificationPreference_dealsAndPromotions;
  caregivers: FindCreateNotificationPreferences_viewer_user_notificationPreference_caregivers;
}

export interface FindCreateNotificationPreferences_viewer_user {
  __typename: "User";
  /**
   * BillingAccounts for a Patient, which may span many different organizations
   */
  billingAccounts: FindCreateNotificationPreferences_viewer_user_billingAccounts[] | null;
  /**
   * How and what the User would like to be notified about
   */
  notificationPreference: FindCreateNotificationPreferences_viewer_user_notificationPreference | null;
}

export interface FindCreateNotificationPreferences_viewer {
  __typename: "Viewer";
  user: FindCreateNotificationPreferences_viewer_user;
}

export interface FindCreateNotificationPreferences {
  /**
   * Return the currently authenticated user
   */
  viewer: FindCreateNotificationPreferences_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PersonalInfo
// ====================================================

export interface PersonalInfo_contactInfo {
  __typename: "ContactInfo";
  /**
   * Name of the Support Team.
   */
  name: string | null;
  phoneNumber: string | null;
  faxNumber: string | null;
  emailAddress: string | null;
}

export interface PersonalInfo_viewer_user_patient_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface PersonalInfo_viewer_user_patient_homeAddress {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export interface PersonalInfo_viewer_user_patient {
  __typename: "Patient";
  name: PersonalInfo_viewer_user_patient_name | null;
  gender: AdministrativeGender | null;
  birthDate: string | null;
  /**
   * The patient's home address
   */
  homeAddress: PersonalInfo_viewer_user_patient_homeAddress | null;
}

export interface PersonalInfo_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: PersonalInfo_viewer_user_patient | null;
}

export interface PersonalInfo_viewer {
  __typename: "Viewer";
  user: PersonalInfo_viewer_user;
}

export interface PersonalInfo {
  /**
   * Returns contact information for each Support Team.
   */
  contactInfo: PersonalInfo_contactInfo[];
  /**
   * Return the currently authenticated user
   */
  viewer: PersonalInfo_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountQuery
// ====================================================

export interface AccountQuery_viewer_user_account {
  __typename: "UserAccount";
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
}

export interface AccountQuery_viewer_user {
  __typename: "User";
  /**
   * SCMD specific account information
   */
  account: AccountQuery_viewer_user_account;
}

export interface AccountQuery_viewer {
  __typename: "Viewer";
  user: AccountQuery_viewer_user;
}

export interface AccountQuery {
  /**
   * Return the currently authenticated user
   */
  viewer: AccountQuery_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProfileHome
// ====================================================

export interface ProfileHome_auth {
  __typename: "LocalAuth";
  isAuthenticated: boolean | null;
}

export interface ProfileHome_viewer_user_patient_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface ProfileHome_viewer_user_patient {
  __typename: "Patient";
  name: ProfileHome_viewer_user_patient_name | null;
  hasAthenaAccount: boolean | null;
}

export interface ProfileHome_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: ProfileHome_viewer_user_patient | null;
}

export interface ProfileHome_viewer {
  __typename: "Viewer";
  user: ProfileHome_viewer_user;
}

export interface ProfileHome {
  auth: ProfileHome_auth | null;
  /**
   * Return the currently authenticated user
   */
  viewer: ProfileHome_viewer | null;
}

export interface ProfileHomeVariables {
  isAuthenticated?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFaqQuery
// ====================================================

export interface GetFaqQuery_faqContent_categories {
  __typename: "FAQCategory";
  id: number;
}

export interface GetFaqQuery_faqContent {
  __typename: "FAQContent";
  id: number;
  question: string | null;
  body: string | null;
  isTopQuestion: boolean;
  categories: GetFaqQuery_faqContent_categories[] | null;
}

export interface GetFaqQuery_faqCategories {
  __typename: "FAQCategory";
  id: number;
  title: string | null;
  description: string | null;
  icon: string | null;
  ctaTile: boolean | null;
  ctaName: string | null;
  ctaHeader: string | null;
  ctaSubHeader: string | null;
  ctaContact: string | null;
  ctaSecondaryName: string | null;
  ctaSecondaryContact: string | null;
}

export interface GetFaqQuery {
  /**
   * Returns all of the FAQs.
   */
  faqContent: GetFaqQuery_faqContent[];
  /**
   * Returns all of the FAQ Categories.
   */
  faqCategories: GetFaqQuery_faqCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Dashboard
// ====================================================

export interface Dashboard_auth {
  __typename: "LocalAuth";
  isAuthenticated: boolean | null;
}

export interface Dashboard_address {
  __typename: "LocalAddress";
  lat: number | null;
  lng: number | null;
}

export interface Dashboard_smgFacilities {
  __typename: "Facility";
  id: number;
}

export interface Dashboard_cmdFacilities {
  __typename: "Facility";
  id: number;
}

export interface Dashboard {
  auth: Dashboard_auth | null;
  address: Dashboard_address | null;
  /**
   * Fetch a list of all Facilities
   */
  smgFacilities: Dashboard_smgFacilities[];
  /**
   * Fetch a list of all Facilities
   */
  cmdFacilities: Dashboard_cmdFacilities[];
}

export interface DashboardVariables {
  isAuthenticated?: boolean | null;
  lat: number;
  lng: number;
  radius: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDocument
// ====================================================

export interface GetDocument_document {
  __typename: "DocumentReference";
  /**
   * Content-Type of the file.
   */
  contentType: string | null;
  /**
   * Date/time created time of the file.
   */
  createdDate: any | null;
  /**
   * Base64-encoded representation of the file.
   */
  file: string | null;
  /**
   * Title of the file.
   */
  title: string | null;
}

export interface GetDocument {
  /**
   * Fetch a single DocumentReference by Id. It will only be returned if it belongs to the authenticated user
   */
  document: GetDocument_document | null;
}

export interface GetDocumentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDocuments
// ====================================================

export interface GetDocuments_viewer_user_patient_documents_appointment_provider_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface GetDocuments_viewer_user_patient_documents_appointment_provider {
  __typename: "Provider";
  name: GetDocuments_viewer_user_patient_documents_appointment_provider_name | null;
  nameWithDesignations: string;
  primarySpecialty: string | null;
}

export interface GetDocuments_viewer_user_patient_documents_appointment_location {
  __typename: "Facility";
  id: number;
  facilityNameShort: string | null;
}

export interface GetDocuments_viewer_user_patient_documents_appointment {
  __typename: "Appointment";
  id: string;
  isVirtual: boolean | null;
  startDateTime: any | null;
  endDateTime: any | null;
  /**
   * The provider that performed the appointment.
   */
  provider: GetDocuments_viewer_user_patient_documents_appointment_provider | null;
  /**
   * The location where the appointment took place.
   */
  location: GetDocuments_viewer_user_patient_documents_appointment_location | null;
}

export interface GetDocuments_viewer_user_patient_documents {
  __typename: "Document";
  /**
   * The document ID used to fetch the document data
   */
  documentId: string;
  /**
   * The encounter ID of the visit, if the document is tied to a visit
   */
  encounterId: string | null;
  /**
   * The Athena appt ID (ehrApptId/FillerApptId) of the visit (if available).
   *       This value is used to fetch the appointment details.
   */
  ehrApptId: string | null;
  /**
   * The title of the document
   */
  title: string;
  /**
   * The description of the document
   */
  description: string;
  /**
   * The type of the document
   */
  type: DocumentType;
  /**
   * Date/time created time of the file.
   */
  date: any;
  appointment: GetDocuments_viewer_user_patient_documents_appointment | null;
  providerName: string | null;
  /**
   * The document number used for changing read status.
   */
  documentNumber: string | null;
  /**
   * The entered at code (facility) for the document used for changing read status.
   */
  enteredAtCode: string | null;
  /**
   * If the user has read the document.
   */
  isRead: boolean | null;
}

export interface GetDocuments_viewer_user_patient {
  __typename: "Patient";
  documents: GetDocuments_viewer_user_patient_documents[] | null;
}

export interface GetDocuments_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: GetDocuments_viewer_user_patient | null;
}

export interface GetDocuments_viewer {
  __typename: "Viewer";
  user: GetDocuments_viewer_user;
}

export interface GetDocuments {
  /**
   * Return the currently authenticated user
   */
  viewer: GetDocuments_viewer | null;
}

export interface GetDocumentsVariables {
  startDate?: any | null;
  endDate?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PharmacySearch
// ====================================================

export interface PharmacySearch_pharmacies_address {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export interface PharmacySearch_pharmacies_contact {
  __typename: "FacilityContact";
  email: string | null;
  phoneNumber: string | null;
  extension: string | null;
  faxNumber: string | null;
}

export interface PharmacySearch_pharmacies {
  __typename: "PharmacyLocation";
  /**
   * The NCPDPID for this pharmacy
   */
  id: string;
  pharmacyType: string | null;
  name: string | null;
  facilityId: string | null;
  address: PharmacySearch_pharmacies_address;
  contact: PharmacySearch_pharmacies_contact;
}

export interface PharmacySearch {
  /**
   * Fetch a list of all PharmacyLocations for the given query string
   */
  pharmacies: PharmacySearch_pharmacies[];
}

export interface PharmacySearchVariables {
  search: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PharmacyPreferred
// ====================================================

export interface PharmacyPreferred_viewer_user_patient_preferredPharmacy_address {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  postalCode: string | null;
  state: string | null;
  city: string | null;
}

export interface PharmacyPreferred_viewer_user_patient_preferredPharmacy_contact {
  __typename: "FacilityContact";
  phoneNumber: string | null;
}

export interface PharmacyPreferred_viewer_user_patient_preferredPharmacy {
  __typename: "PatientPreferredPharmacy";
  name: string | null;
  pharmacyType: string | null;
  address: PharmacyPreferred_viewer_user_patient_preferredPharmacy_address;
  contact: PharmacyPreferred_viewer_user_patient_preferredPharmacy_contact;
}

export interface PharmacyPreferred_viewer_user_patient {
  __typename: "Patient";
  id: string;
  preferredPharmacy: PharmacyPreferred_viewer_user_patient_preferredPharmacy | null;
}

export interface PharmacyPreferred_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: PharmacyPreferred_viewer_user_patient | null;
}

export interface PharmacyPreferred_viewer {
  __typename: "Viewer";
  user: PharmacyPreferred_viewer_user;
}

export interface PharmacyPreferred {
  /**
   * Return the currently authenticated user
   */
  viewer: PharmacyPreferred_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Prescription
// ====================================================

export interface Prescription_prescription_pharmacy_address {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export interface Prescription_prescription_pharmacy_contact {
  __typename: "FacilityContact";
  phoneNumber: string | null;
}

export interface Prescription_prescription_pharmacy {
  __typename: "PharmacyLocation";
  name: string | null;
  address: Prescription_prescription_pharmacy_address;
  pharmacyType: string | null;
  contact: Prescription_prescription_pharmacy_contact;
}

export interface Prescription_prescription_provider {
  __typename: "Provider";
  nameWithDesignations: string;
}

export interface Prescription_prescription {
  __typename: "Prescription";
  /**
   * If the prescription is active. Active prescriptions are any prescription that is not over 180 days old.
   */
  active: boolean;
  createdDateTime: string | null;
  documentDescription: string | null;
  dosageDurationUnit: string | null;
  dosageFrequencyUnit: string | null;
  futureSubmitDate: string | null;
  /**
   * The pharmacy that provided the prescription.
   */
  pharmacy: Prescription_prescription_pharmacy | null;
  prescriptionId: number;
  /**
   * The Provider that ordered the prescription.
   */
  provider: Prescription_prescription_provider | null;
  quantityUnit: string | null;
  quantityValue: number | null;
  refillsAllowed: number | null;
  sig: string | null;
  status: string | null;
}

export interface Prescription {
  /**
   * Fetch a single Prescription by ID.
   */
  prescription: Prescription_prescription;
}

export interface PrescriptionVariables {
  id: string;
  mrn: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PrescriptionsQuery
// ====================================================

export interface PrescriptionsQuery_viewer_user_patient_prescriptions {
  __typename: "PrescriptionListItem";
  documentDescription: string | null;
  priority: string | null;
  prescriptionId: number;
  createdDateTime: string | null;
  facilityId: number | null;
  status: string | null;
  /**
   * The MRN that this prescription belongs to.
   */
  mrn: string;
}

export interface PrescriptionsQuery_viewer_user_patient {
  __typename: "Patient";
  prescriptions: PrescriptionsQuery_viewer_user_patient_prescriptions[];
}

export interface PrescriptionsQuery_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: PrescriptionsQuery_viewer_user_patient | null;
}

export interface PrescriptionsQuery_viewer {
  __typename: "Viewer";
  user: PrescriptionsQuery_viewer_user;
}

export interface PrescriptionsQuery {
  /**
   * Return the currently authenticated user
   */
  viewer: PrescriptionsQuery_viewer | null;
}

export interface PrescriptionsQueryVariables {
  active?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDiagnosticReport
// ====================================================

export interface GetDiagnosticReport_contactInfo {
  __typename: "ContactInfo";
  /**
   * Name of the Support Team.
   */
  name: string | null;
  phoneNumber: string | null;
  faxNumber: string | null;
  emailAddress: string | null;
}

export interface GetDiagnosticReport_diagnosticReport_observations_helperText {
  __typename: "ObservationHelperText";
  description: string | null;
  title: string | null;
}

export interface GetDiagnosticReport_diagnosticReport_observations {
  __typename: "Observation";
  /**
   * Indicates if the observation represents an elevated level which should be indicated in the client
   */
  elevated: boolean | null;
  helperText: GetDiagnosticReport_diagnosticReport_observations_helperText | null;
  /**
   * Effective date time of the observation object
   */
  effectiveDate: string | null;
  /**
   * Indicates whether this observation line should be highlighted in the client
   */
  highlight: boolean | null;
  id: string;
  /**
   * Indicates if the observation represents a lowered level which should be indicated in the client
   */
  lowered: boolean | null;
  /**
   * Medical code for this observation
   */
  name: string | null;
  /**
   * An observation can contain optional notes as well as name/value pairs. These notes can be very large text blobs.
   */
  notes: string[] | null;
  reference: string | null;
  value: string | null;
  valueString: string | null;
}

export interface GetDiagnosticReport_diagnosticReport_requestedByProvider_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface GetDiagnosticReport_diagnosticReport_requestedByProvider {
  __typename: "Provider";
  id: string;
  name: GetDiagnosticReport_diagnosticReport_requestedByProvider_name | null;
  nameWithDesignations: string;
  photoUrl: string | null;
  primarySpecialty: string | null;
}

export interface GetDiagnosticReport_diagnosticReport_resultMedia {
  __typename: "DiagnosticReportResultMedia";
  contentType: string | null;
  data: string | null;
}

export interface GetDiagnosticReport_diagnosticReport {
  __typename: "DiagnosticReport";
  id: string;
  /**
   * A collection of Observations from a DiagnosticReport
   */
  observations: GetDiagnosticReport_diagnosticReport_observations[] | null;
  receivedDateTime: any | null;
  reportTitle: string | null;
  reportLocation: string | null;
  orderedBy: string | null;
  requestedByProvider: GetDiagnosticReport_diagnosticReport_requestedByProvider | null;
  resultMedia: GetDiagnosticReport_diagnosticReport_resultMedia | null;
  /**
   * The organization to which the originator of the report belongs.
   */
  sourceOrg: FacilityOrg | null;
  testSummary: string | null;
  type: string[] | null;
}

export interface GetDiagnosticReport {
  /**
   * Returns contact information for each Support Team.
   */
  contactInfo: GetDiagnosticReport_contactInfo[];
  /**
   * Fetch a single DiagnosticReport by Id. It will only be returned if it belongs to the authenticated user
   */
  diagnosticReport: GetDiagnosticReport_diagnosticReport | null;
}

export interface GetDiagnosticReportVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDiagnosticReports
// ====================================================

export interface GetDiagnosticReports_address {
  __typename: "LocalAddress";
  lat: number | null;
  lng: number | null;
}

export interface GetDiagnosticReports_viewer_user_patient_diagnosticReports_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface GetDiagnosticReports_viewer_user_patient_diagnosticReports_edges_node {
  __typename: "DiagnosticReport";
  id: string;
  receivedDateTime: any | null;
  reportTitle: string | null;
  testSummary: string | null;
  type: string[] | null;
}

export interface GetDiagnosticReports_viewer_user_patient_diagnosticReports_edges {
  __typename: "DiagnosticReportEdge";
  node: GetDiagnosticReports_viewer_user_patient_diagnosticReports_edges_node;
}

export interface GetDiagnosticReports_viewer_user_patient_diagnosticReports {
  __typename: "DiagnosticReportConnection";
  pageInfo: GetDiagnosticReports_viewer_user_patient_diagnosticReports_pageInfo;
  edges: GetDiagnosticReports_viewer_user_patient_diagnosticReports_edges[];
}

export interface GetDiagnosticReports_viewer_user_patient {
  __typename: "Patient";
  diagnosticReports: GetDiagnosticReports_viewer_user_patient_diagnosticReports | null;
}

export interface GetDiagnosticReports_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: GetDiagnosticReports_viewer_user_patient | null;
}

export interface GetDiagnosticReports_viewer {
  __typename: "Viewer";
  user: GetDiagnosticReports_viewer_user;
}

export interface GetDiagnosticReports_smgFacilities {
  __typename: "Facility";
  id: number;
}

export interface GetDiagnosticReports {
  address: GetDiagnosticReports_address | null;
  /**
   * Return the currently authenticated user
   */
  viewer: GetDiagnosticReports_viewer | null;
  /**
   * Fetch a list of all Facilities
   */
  smgFacilities: GetDiagnosticReports_smgFacilities[];
}

export interface GetDiagnosticReportsVariables {
  after?: string | null;
  categories?: DiagnosticReportCategory[] | null;
  endDate?: any | null;
  first?: number | null;
  sort?: SortDirection | null;
  startDate?: any | null;
  lat: number;
  lng: number;
  radius?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllAvailabilities
// ====================================================

export interface GetAllAvailabilities_selectedCalendarAvailabilities_pages_timeSlots {
  __typename: "TimeSlot";
  appointmentTypeId: string;
  availabilityId: string;
  calendarId: string;
  /**
   * The date of the TimeSlot relative to the Provider's location.
   */
  date: string;
  /**
   * The start time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  startTime: string;
  /**
   * The end time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  endTime: string;
  /**
   * The start time of the TimeSlot in UTC.
   */
  startAtUtc: any;
  /**
   * The end time of the TimeSlot in UTC.
   */
  endAtUtc: any;
}

export interface GetAllAvailabilities_selectedCalendarAvailabilities_pages_providerDetails {
  __typename: "AvailabilityProviderDetails";
  /**
   * The CouchDB id for the provider. To be used with the `provider` query
   */
  id: string | null;
  /**
   * Athena Id. Useful in combination with the Availabilities query when you need the calendar of a specific provider
   */
  athenaId: string | null;
  /**
   * Url to render the Provider's avatar
   */
  photoUrl: string | null;
  /**
   * Formatted string that includes the Provider's name and designations, eg: 'Ashley Appleton, LPC, NCC'
   */
  nameWithDesignations: string;
  primarySpecialtyName: string | null;
  shortAddress: string | null;
  latitude: number | null;
  longitude: number | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GetAllAvailabilities_selectedCalendarAvailabilities_pages {
  __typename: "Availability";
  date: string | null;
  calendarId: string;
  timeSlots: GetAllAvailabilities_selectedCalendarAvailabilities_pages_timeSlots[];
  /**
   * Provider information associated with an Experian Calendar. Some fields may be resolved via the SCMD Provider model when not available as part of the Experian payload. 
   */
  providerDetails: GetAllAvailabilities_selectedCalendarAvailabilities_pages_providerDetails;
}

export interface GetAllAvailabilities_selectedCalendarAvailabilities {
  __typename: "AvailabilitiesPaginatedResponse";
  pages: GetAllAvailabilities_selectedCalendarAvailabilities_pages[];
}

export interface GetAllAvailabilities_availabilities_pageInfo {
  __typename: "AvailabilitiesPageInfo";
  /**
   * The cursor to be used in the `after` argument to fetch the next iteration of availabilities
   */
  nextCursor: string | null;
}

export interface GetAllAvailabilities_availabilities_pages_timeSlots {
  __typename: "TimeSlot";
  appointmentTypeId: string;
  availabilityId: string;
  calendarId: string;
  /**
   * The date of the TimeSlot relative to the Provider's location.
   */
  date: string;
  /**
   * The start time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  startTime: string;
  /**
   * The end time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  endTime: string;
  /**
   * The start time of the TimeSlot in UTC.
   */
  startAtUtc: any;
  /**
   * The end time of the TimeSlot in UTC.
   */
  endAtUtc: any;
}

export interface GetAllAvailabilities_availabilities_pages_providerDetails {
  __typename: "AvailabilityProviderDetails";
  /**
   * The CouchDB id for the provider. To be used with the `provider` query
   */
  id: string | null;
  /**
   * Athena Id. Useful in combination with the Availabilities query when you need the calendar of a specific provider
   */
  athenaId: string | null;
  /**
   * Url to render the Provider's avatar
   */
  photoUrl: string | null;
  /**
   * Formatted string that includes the Provider's name and designations, eg: 'Ashley Appleton, LPC, NCC'
   */
  nameWithDesignations: string;
  primarySpecialtyName: string | null;
  shortAddress: string | null;
  latitude: number | null;
  longitude: number | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GetAllAvailabilities_availabilities_pages {
  __typename: "Availability";
  date: string | null;
  calendarId: string;
  timeSlots: GetAllAvailabilities_availabilities_pages_timeSlots[];
  /**
   * Provider information associated with an Experian Calendar. Some fields may be resolved via the SCMD Provider model when not available as part of the Experian payload. 
   */
  providerDetails: GetAllAvailabilities_availabilities_pages_providerDetails;
}

export interface GetAllAvailabilities_availabilities {
  __typename: "AvailabilitiesPaginatedResponse";
  /**
   * Metadata related to the pagination of the query
   */
  pageInfo: GetAllAvailabilities_availabilities_pageInfo;
  pages: GetAllAvailabilities_availabilities_pages[];
}

export interface GetAllAvailabilities {
  /**
   * Get a list of all Availabilities, which a user can select to book an Appointment. Results are paginated. The intial query should be made with the `after` set to null. Results are returned with a `nextCursor` field, which can be used in conjunction with `after` to advance to the next set of availabilities. The client may continue in this fashion until no further results are available OR other filter paramters are changed, at which point the `after` argument should again be set to null. The use of `athenaId` and `flowSessionId` are mutually exclusive, but one is required.
   */
  selectedCalendarAvailabilities: GetAllAvailabilities_selectedCalendarAvailabilities;
  __typename: "Query";
  /**
   * Get a list of all Availabilities, which a user can select to book an Appointment. Results are paginated. The intial query should be made with the `after` set to null. Results are returned with a `nextCursor` field, which can be used in conjunction with `after` to advance to the next set of availabilities. The client may continue in this fashion until no further results are available OR other filter paramters are changed, at which point the `after` argument should again be set to null. The use of `athenaId` and `flowSessionId` are mutually exclusive, but one is required.
   */
  availabilities: GetAllAvailabilities_availabilities;
}

export interface GetAllAvailabilitiesVariables {
  after?: string | null;
  daysOfWeek?: DayOfWeek[] | null;
  flowSessionId?: string | null;
  gender?: ProviderGender | null;
  language?: Language | null;
  maxStartTime?: any | null;
  minStartDate?: any | null;
  minStartTime?: any | null;
  geo?: GeoCircleInput | null;
  querySelectedCalendarIds: boolean;
  selectedCalendarIds?: string[] | null;
  calendarIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSelectedCalendars
// ====================================================

export interface GetSelectedCalendars_availabilities_pageInfo {
  __typename: "AvailabilitiesPageInfo";
  /**
   * The cursor to be used in the `after` argument to fetch the next iteration of availabilities
   */
  nextCursor: string | null;
}

export interface GetSelectedCalendars_availabilities_pages_timeSlots {
  __typename: "TimeSlot";
  appointmentTypeId: string;
  availabilityId: string;
  calendarId: string;
  /**
   * The date of the TimeSlot relative to the Provider's location.
   */
  date: string;
  /**
   * The start time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  startTime: string;
  /**
   * The end time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  endTime: string;
  /**
   * The start time of the TimeSlot in UTC.
   */
  startAtUtc: any;
  /**
   * The end time of the TimeSlot in UTC.
   */
  endAtUtc: any;
}

export interface GetSelectedCalendars_availabilities_pages_providerDetails {
  __typename: "AvailabilityProviderDetails";
  /**
   * The CouchDB id for the provider. To be used with the `provider` query
   */
  id: string | null;
  /**
   * Athena Id. Useful in combination with the Availabilities query when you need the calendar of a specific provider
   */
  athenaId: string | null;
  /**
   * Url to render the Provider's avatar
   */
  photoUrl: string | null;
  /**
   * Formatted string that includes the Provider's name and designations, eg: 'Ashley Appleton, LPC, NCC'
   */
  nameWithDesignations: string;
  primarySpecialtyName: string | null;
  shortAddress: string | null;
  latitude: number | null;
  longitude: number | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GetSelectedCalendars_availabilities_pages {
  __typename: "Availability";
  date: string | null;
  calendarId: string;
  timeSlots: GetSelectedCalendars_availabilities_pages_timeSlots[];
  /**
   * Provider information associated with an Experian Calendar. Some fields may be resolved via the SCMD Provider model when not available as part of the Experian payload. 
   */
  providerDetails: GetSelectedCalendars_availabilities_pages_providerDetails;
}

export interface GetSelectedCalendars_availabilities {
  __typename: "AvailabilitiesPaginatedResponse";
  /**
   * Metadata related to the pagination of the query
   */
  pageInfo: GetSelectedCalendars_availabilities_pageInfo;
  pages: GetSelectedCalendars_availabilities_pages[];
}

export interface GetSelectedCalendars {
  __typename: "Query";
  /**
   * Get a list of all Availabilities, which a user can select to book an Appointment. Results are paginated. The intial query should be made with the `after` set to null. Results are returned with a `nextCursor` field, which can be used in conjunction with `after` to advance to the next set of availabilities. The client may continue in this fashion until no further results are available OR other filter paramters are changed, at which point the `after` argument should again be set to null. The use of `athenaId` and `flowSessionId` are mutually exclusive, but one is required.
   */
  availabilities: GetSelectedCalendars_availabilities;
}

export interface GetSelectedCalendarsVariables {
  after?: string | null;
  daysOfWeek?: DayOfWeek[] | null;
  flowSessionId?: string | null;
  gender?: ProviderGender | null;
  language?: Language | null;
  maxStartTime?: any | null;
  minStartDate?: any | null;
  minStartTime?: any | null;
  geo?: GeoCircleInput | null;
  calendarIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDecisionSupportIndex
// ====================================================

export interface GetDecisionSupportIndex_decisionSupportFlows {
  __typename: "DecisionSupportFlow";
  /**
   * The id that drives the decision support flow. Pass this value to `startDecisionSupport`
   */
  id: string;
  title: string;
  experianUrl: string | null;
  useExperianUrl: boolean | null;
}

export interface GetDecisionSupportIndex_auth {
  __typename: "LocalAuth";
  isAuthenticated: boolean | null;
}

export interface GetDecisionSupportIndex_viewer_user_patient_providers_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface GetDecisionSupportIndex_viewer_user_patient_providers {
  __typename: "Provider";
  athenaId: string | null;
  id: string;
  nameWithDesignations: string;
  photoUrl: string | null;
  name: GetDecisionSupportIndex_viewer_user_patient_providers_name | null;
}

export interface GetDecisionSupportIndex_viewer_user_patient {
  __typename: "Patient";
  /**
   * Return a list of providers not associated with virtual urgent care (Amwell) visits
   */
  providers: GetDecisionSupportIndex_viewer_user_patient_providers[] | null;
}

export interface GetDecisionSupportIndex_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: GetDecisionSupportIndex_viewer_user_patient | null;
}

export interface GetDecisionSupportIndex_viewer {
  __typename: "Viewer";
  user: GetDecisionSupportIndex_viewer_user;
}

export interface GetDecisionSupportIndex {
  decisionSupportFlows: GetDecisionSupportIndex_decisionSupportFlows[] | null;
  auth: GetDecisionSupportIndex_auth | null;
  /**
   * Return the currently authenticated user
   */
  viewer: GetDecisionSupportIndex_viewer | null;
}

export interface GetDecisionSupportIndexVariables {
  isAuthenticated?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFacility
// ====================================================

export interface GetFacility_facility_address {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export interface GetFacility_facility_contactInfo {
  __typename: "FacilityContact";
  email: string | null;
  faxNumber: string | null;
  phoneNumber: string | null;
}

export interface GetFacility_facility_coordinates {
  __typename: "Coordinates";
  lat: number;
  lng: number;
}

export interface GetFacility_facility_hoursOfOperation {
  __typename: "FacilityHoursOfOperation";
  closingTime: string | null;
  dayOfWeek: DayOfWeek | null;
  open: boolean | null;
  openingTime: string | null;
}

export interface GetFacility_facility {
  __typename: "Facility";
  address: GetFacility_facility_address | null;
  alertUrl: string | null;
  clinical: boolean | null;
  contactInfo: GetFacility_facility_contactInfo | null;
  coordinates: GetFacility_facility_coordinates | null;
  facilityNameLong: string | null;
  facilityNameShort: string | null;
  hoursOfOperation: GetFacility_facility_hoursOfOperation[] | null;
  id: number;
  imageUrl: string | null;
  org: FacilityOrg | null;
  supportedServices: string[] | null;
  urgentCareWalkIn: boolean | null;
  waitTimeSeconds: number | null;
  ehrLocationIds: number[] | null;
}

export interface GetFacility {
  /**
   * Fetch a single Facility by Id
   */
  facility: GetFacility_facility;
}

export interface GetFacilityVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFacilities
// ====================================================

export interface GetFacilities_address {
  __typename: "LocalAddress";
  lat: number | null;
  lng: number | null;
}

export interface GetFacilities_facilities_address {
  __typename: "Address";
  city: string | null;
  line1: string | null;
  line2: string | null;
  postalCode: string | null;
  state: string | null;
}

export interface GetFacilities_facilities_coordinates {
  __typename: "Coordinates";
  lat: number;
  lng: number;
}

export interface GetFacilities_facilities_hoursOfOperation {
  __typename: "FacilityHoursOfOperation";
  dayOfWeek: DayOfWeek | null;
  open: boolean | null;
  openingTime: string | null;
  closingTime: string | null;
}

export interface GetFacilities_facilities {
  __typename: "Facility";
  facilityNameLong: string | null;
  waitTimeSeconds: number | null;
  address: GetFacilities_facilities_address | null;
  coordinates: GetFacilities_facilities_coordinates | null;
  id: number;
  urgentCareWalkIn: boolean | null;
  hoursOfOperation: GetFacilities_facilities_hoursOfOperation[] | null;
}

export interface GetFacilities {
  address: GetFacilities_address | null;
  /**
   * Fetch a list of all Facilities
   */
  facilities: GetFacilities_facilities[];
}

export interface GetFacilitiesVariables {
  lat: number;
  lng: number;
  org?: FacilityOrg | null;
  radius: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaregiverAccess
// ====================================================

export interface GetCaregiverAccess_viewer_user_caregiverAccess_address {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  state: string | null;
  city: string | null;
  postalCode: string | null;
}

export interface GetCaregiverAccess_viewer_user_caregiverAccess_statusDetails {
  __typename: "CaregiverAccessDetails";
  status: CaregiverStatus;
  statusName: string | null;
  createdDate: any | null;
  acceptedDate: any | null;
  revokedDate: any | null;
}

export interface GetCaregiverAccess_viewer_user_caregiverAccess_contact {
  __typename: "CaregiverAccessContact";
  phone: string | null;
  email: string | null;
}

export interface GetCaregiverAccess_viewer_user_caregiverAccess {
  __typename: "CaregiverAccess";
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  gender: AdministrativeGender | null;
  isMinor: boolean | null;
  hasAccount: boolean | null;
  address: GetCaregiverAccess_viewer_user_caregiverAccess_address | null;
  statusDetails: GetCaregiverAccess_viewer_user_caregiverAccess_statusDetails;
  contact: GetCaregiverAccess_viewer_user_caregiverAccess_contact | null;
}

export interface GetCaregiverAccess_viewer_user {
  __typename: "User";
  /**
   * Return a list of patients that the patient is a caregiver for
   */
  caregiverAccess: GetCaregiverAccess_viewer_user_caregiverAccess[] | null;
}

export interface GetCaregiverAccess_viewer {
  __typename: "Viewer";
  user: GetCaregiverAccess_viewer_user;
}

export interface GetCaregiverAccess {
  /**
   * Return the currently authenticated user
   */
  viewer: GetCaregiverAccess_viewer | null;
}

export interface GetCaregiverAccessVariables {
  includeRevoked?: boolean | null;
  useMyAccount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaregiverAccessWithParams
// ====================================================

export interface GetCaregiverAccessWithParams_viewer_user_caregiverAccess_address {
  __typename: "Address";
  line1: string | null;
  line2: string | null;
  state: string | null;
  city: string | null;
  postalCode: string | null;
}

export interface GetCaregiverAccessWithParams_viewer_user_caregiverAccess_statusDetails {
  __typename: "CaregiverAccessDetails";
  status: CaregiverStatus;
  statusName: string | null;
  createdDate: any | null;
  acceptedDate: any | null;
  revokedDate: any | null;
}

export interface GetCaregiverAccessWithParams_viewer_user_caregiverAccess_contact {
  __typename: "CaregiverAccessContact";
  phone: string | null;
  email: string | null;
}

export interface GetCaregiverAccessWithParams_viewer_user_caregiverAccess {
  __typename: "CaregiverAccess";
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: any | null;
  gender: AdministrativeGender | null;
  isMinor: boolean | null;
  hasAccount: boolean | null;
  address: GetCaregiverAccessWithParams_viewer_user_caregiverAccess_address | null;
  statusDetails: GetCaregiverAccessWithParams_viewer_user_caregiverAccess_statusDetails;
  contact: GetCaregiverAccessWithParams_viewer_user_caregiverAccess_contact | null;
}

export interface GetCaregiverAccessWithParams_viewer_user {
  __typename: "User";
  /**
   * Return a list of patients that the patient is a caregiver for
   */
  caregiverAccess: GetCaregiverAccessWithParams_viewer_user_caregiverAccess[] | null;
}

export interface GetCaregiverAccessWithParams_viewer {
  __typename: "Viewer";
  user: GetCaregiverAccessWithParams_viewer_user;
}

export interface GetCaregiverAccessWithParams {
  /**
   * Return the currently authenticated user
   */
  viewer: GetCaregiverAccessWithParams_viewer | null;
}

export interface GetCaregiverAccessWithParamsVariables {
  includeRevoked?: boolean | null;
  useMyAccount?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPendingInvoiceCount
// ====================================================

export interface GetPendingInvoiceCount_viewer_user_billingAccounts_summary {
  __typename: "CedarSummary";
  /**
   * The number of payable/outstanding invoices
   */
  invoiceCount: number;
}

export interface GetPendingInvoiceCount_viewer_user_billingAccounts {
  __typename: "CedarBillingAccount";
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  /**
   * Fetch the user's summary
   */
  summary: GetPendingInvoiceCount_viewer_user_billingAccounts_summary | null;
}

export interface GetPendingInvoiceCount_viewer_user {
  __typename: "User";
  /**
   * BillingAccounts for a Patient, which may span many different organizations
   */
  billingAccounts: GetPendingInvoiceCount_viewer_user_billingAccounts[] | null;
}

export interface GetPendingInvoiceCount_viewer {
  __typename: "Viewer";
  user: GetPendingInvoiceCount_viewer_user;
}

export interface GetPendingInvoiceCount {
  /**
   * Return the currently authenticated user
   */
  viewer: GetPendingInvoiceCount_viewer | null;
}

export interface GetPendingInvoiceCountVariables {
  isAuthenticated: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseContactInfoQuery
// ====================================================

export interface UseContactInfoQuery_contactInfo {
  __typename: "ContactInfo";
  /**
   * Name of the Support Team.
   */
  name: string | null;
  phoneNumber: string | null;
  faxNumber: string | null;
  emailAddress: string | null;
}

export interface UseContactInfoQuery {
  /**
   * Returns contact information for each Support Team.
   */
  contactInfo: UseContactInfoQuery_contactInfo[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOutages
// ====================================================

export interface GetOutages_outages {
  __typename: "Outage";
  id: string;
  source: string;
  message: string;
  showOutage: boolean;
  link: string | null;
}

export interface GetOutages {
  /**
   * Fetch the current outages for the app
   */
  outages: GetOutages_outages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PatientExistsQuery
// ====================================================

export interface PatientExistsQuery_viewer_user_patient_homeAddress {
  __typename: "Address";
  line1: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
}

export interface PatientExistsQuery_viewer_user_patient {
  __typename: "Patient";
  id: string;
  /**
   * The patient's home address
   */
  homeAddress: PatientExistsQuery_viewer_user_patient_homeAddress | null;
}

export interface PatientExistsQuery_viewer_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: PatientExistsQuery_viewer_user_patient | null;
}

export interface PatientExistsQuery_viewer {
  __typename: "Viewer";
  user: PatientExistsQuery_viewer_user;
}

export interface PatientExistsQuery {
  /**
   * Return the currently authenticated user
   */
  viewer: PatientExistsQuery_viewer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CedarInvoiceListItemFragment
// ====================================================

export interface CedarInvoiceListItemFragment_patientActivityEvents {
  __typename: "CedarInvoicePatientActivityEvent";
  /**
   * Date on which event occurred
   */
  date: any;
}

export interface CedarInvoiceListItemFragment {
  __typename: "CedarInvoice";
  /**
   * Name of doctor, NP, or nurse that provided services
   */
  clinicianName: string;
  dateIssued: any | null;
  /**
   * Unique Cedar identifier for each invoice
   */
  publicId: string;
  /**
   * Amount remaining on invoice (post-provider adj.)
   */
  remainingAmount: number;
  /**
   * Invoice status with predefined set of values
   */
  state: InvoiceState;
  /**
   * Total charge amount of invoice before insurance
   */
  totalAmountDue: number;
  /**
   * A timeline of the evolution of the invoice
   */
  patientActivityEvents: CedarInvoiceListItemFragment_patientActivityEvents[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BillingAccountCardFragment
// ====================================================

export interface BillingAccountCardFragment_summary {
  __typename: "CedarSummary";
  /**
   * The number of payable/outstanding invoices
   */
  invoiceCount: number;
}

export interface BillingAccountCardFragment {
  __typename: "CedarBillingAccount";
  /**
   * A Patient will have a different MRNs (Medical Record Number) for each Organization they have an account with
   */
  patientMrn: string;
  entity: ScmdOrganization | null;
  /**
   * Fetch the user's summary
   */
  summary: BillingAccountCardFragment_summary | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: contactSupportFragment
// ====================================================

export interface contactSupportFragment {
  __typename: "ContactInfo";
  /**
   * Name of the Support Team.
   */
  name: string | null;
  phoneNumber: string | null;
  faxNumber: string | null;
  emailAddress: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NotificationPreferenceOptionFragment
// ====================================================

export interface NotificationPreferenceOptionFragment {
  __typename: "NotificationPreferenceOption";
  /**
   * Set to true if the user would like to receive email notifications
   */
  emailNotification: boolean;
  /**
   * Set to true if the user would like to receive notifications via a phone call
   */
  phoneCall: boolean;
  /**
   * Set to true if the user would like to receive SMS notifications
   */
  smsNotification: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProfileHeaderFragment
// ====================================================

export interface ProfileHeaderFragment_user_patient_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface ProfileHeaderFragment_user_patient {
  __typename: "Patient";
  name: ProfileHeaderFragment_user_patient_name | null;
}

export interface ProfileHeaderFragment_user {
  __typename: "User";
  /**
   * The Patient record associated to this User
   */
  patient: ProfileHeaderFragment_user_patient | null;
}

export interface ProfileHeaderFragment {
  __typename: "Viewer";
  user: ProfileHeaderFragment_user;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: diagnosticReportFragment
// ====================================================

export interface diagnosticReportFragment {
  __typename: "DiagnosticReport";
  id: string;
  receivedDateTime: any | null;
  reportTitle: string | null;
  testSummary: string | null;
  type: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProviderAvailabilitiesListItemFragment
// ====================================================

export interface ProviderAvailabilitiesListItemFragment_timeSlots {
  __typename: "TimeSlot";
  appointmentTypeId: string;
  availabilityId: string;
  calendarId: string;
  /**
   * The date of the TimeSlot relative to the Provider's location.
   */
  date: string;
  /**
   * The start time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  startTime: string;
  /**
   * The end time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  endTime: string;
  /**
   * The start time of the TimeSlot in UTC.
   */
  startAtUtc: any;
  /**
   * The end time of the TimeSlot in UTC.
   */
  endAtUtc: any;
}

export interface ProviderAvailabilitiesListItemFragment_providerDetails {
  __typename: "AvailabilityProviderDetails";
  /**
   * The CouchDB id for the provider. To be used with the `provider` query
   */
  id: string | null;
  /**
   * Athena Id. Useful in combination with the Availabilities query when you need the calendar of a specific provider
   */
  athenaId: string | null;
  /**
   * Url to render the Provider's avatar
   */
  photoUrl: string | null;
  /**
   * Formatted string that includes the Provider's name and designations, eg: 'Ashley Appleton, LPC, NCC'
   */
  nameWithDesignations: string;
  primarySpecialtyName: string | null;
  shortAddress: string | null;
  latitude: number | null;
  longitude: number | null;
  firstName: string | null;
  lastName: string | null;
}

export interface ProviderAvailabilitiesListItemFragment {
  __typename: "Availability";
  date: string | null;
  calendarId: string;
  timeSlots: ProviderAvailabilitiesListItemFragment_timeSlots[];
  /**
   * Provider information associated with an Experian Calendar. Some fields may be resolved via the SCMD Provider model when not available as part of the Experian payload. 
   */
  providerDetails: ProviderAvailabilitiesListItemFragment_providerDetails;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProviderAvailabilitiesListFragment
// ====================================================

export interface ProviderAvailabilitiesListFragment_availabilities_pageInfo {
  __typename: "AvailabilitiesPageInfo";
  /**
   * The cursor to be used in the `after` argument to fetch the next iteration of availabilities
   */
  nextCursor: string | null;
}

export interface ProviderAvailabilitiesListFragment_availabilities_pages_timeSlots {
  __typename: "TimeSlot";
  appointmentTypeId: string;
  availabilityId: string;
  calendarId: string;
  /**
   * The date of the TimeSlot relative to the Provider's location.
   */
  date: string;
  /**
   * The start time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  startTime: string;
  /**
   * The end time of the TimeSlot relative to the Provider's location. 24 hour time format
   */
  endTime: string;
  /**
   * The start time of the TimeSlot in UTC.
   */
  startAtUtc: any;
  /**
   * The end time of the TimeSlot in UTC.
   */
  endAtUtc: any;
}

export interface ProviderAvailabilitiesListFragment_availabilities_pages_providerDetails {
  __typename: "AvailabilityProviderDetails";
  /**
   * The CouchDB id for the provider. To be used with the `provider` query
   */
  id: string | null;
  /**
   * Athena Id. Useful in combination with the Availabilities query when you need the calendar of a specific provider
   */
  athenaId: string | null;
  /**
   * Url to render the Provider's avatar
   */
  photoUrl: string | null;
  /**
   * Formatted string that includes the Provider's name and designations, eg: 'Ashley Appleton, LPC, NCC'
   */
  nameWithDesignations: string;
  primarySpecialtyName: string | null;
  shortAddress: string | null;
  latitude: number | null;
  longitude: number | null;
  firstName: string | null;
  lastName: string | null;
}

export interface ProviderAvailabilitiesListFragment_availabilities_pages {
  __typename: "Availability";
  date: string | null;
  calendarId: string;
  timeSlots: ProviderAvailabilitiesListFragment_availabilities_pages_timeSlots[];
  /**
   * Provider information associated with an Experian Calendar. Some fields may be resolved via the SCMD Provider model when not available as part of the Experian payload. 
   */
  providerDetails: ProviderAvailabilitiesListFragment_availabilities_pages_providerDetails;
}

export interface ProviderAvailabilitiesListFragment_availabilities {
  __typename: "AvailabilitiesPaginatedResponse";
  /**
   * Metadata related to the pagination of the query
   */
  pageInfo: ProviderAvailabilitiesListFragment_availabilities_pageInfo;
  pages: ProviderAvailabilitiesListFragment_availabilities_pages[];
}

export interface ProviderAvailabilitiesListFragment {
  __typename: "Query";
  /**
   * Get a list of all Availabilities, which a user can select to book an Appointment. Results are paginated. The intial query should be made with the `after` set to null. Results are returned with a `nextCursor` field, which can be used in conjunction with `after` to advance to the next set of availabilities. The client may continue in this fashion until no further results are available OR other filter paramters are changed, at which point the `after` argument should again be set to null. The use of `athenaId` and `flowSessionId` are mutually exclusive, but one is required.
   */
  availabilities: ProviderAvailabilitiesListFragment_availabilities;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProviderAvatarListFragment
// ====================================================

export interface ProviderAvatarListFragment_name {
  __typename: "PersonName";
  firstName: string | null;
  lastName: string | null;
}

export interface ProviderAvatarListFragment {
  __typename: "Provider";
  athenaId: string | null;
  id: string;
  nameWithDesignations: string;
  photoUrl: string | null;
  name: ProviderAvatarListFragment_name | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * AddressUse exposes different types of addresses that are stored on Patients in Intersystems
 */
export enum AddressUse {
  BILLING = "BILLING",
  HOME = "HOME",
  OLD = "OLD",
  TEMP = "TEMP",
  WORK = "WORK",
}

/**
 * Gender of a person used for administrative purposes
 */
export enum AdministrativeGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
  UNKNOWN = "UNKNOWN",
}

/**
 * Current caregiver status information
 */
export enum CaregiverStatus {
  CANCELLED = "CANCELLED",
  GRANTED = "GRANTED",
  INVITED = "INVITED",
  REVOKED = "REVOKED",
  UNKNOWN = "UNKNOWN",
}

export enum DSInputType {
  BOOL = "BOOL",
  CURRENCY = "CURRENCY",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  DECIMAL = "DECIMAL",
  EMAIL = "EMAIL",
  INTEGER = "INTEGER",
  MULTI_LINE_TEXT = "MULTI_LINE_TEXT",
  PHONE_NUMBER = "PHONE_NUMBER",
  SELECTION = "SELECTION",
  TEXT = "TEXT",
  TIME = "TIME",
  UNKNOWN = "UNKNOWN",
}

export enum DSValueType {
  BOOL = "BOOL",
  DATE = "DATE",
  DATE_AND_TIME = "DATE_AND_TIME",
  DOUBLE = "DOUBLE",
  INTEGER = "INTEGER",
  STRING = "STRING",
  TAG_LIST = "TAG_LIST",
  TIME = "TIME",
  UNKNOWN = "UNKNOWN",
}

export enum DayOfWeek {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

export enum DiagnosticReportCategory {
  LAB = "LAB",
  RAD = "RAD",
}

export enum DocumentType {
  CONSENT = "CONSENT",
  DISCHARGE_INSTRUCTIONS = "DISCHARGE_INSTRUCTIONS",
  OTHER = "OTHER",
  VISIT_SUMMARY = "VISIT_SUMMARY",
  WORK_SCHOOL_NOTE = "WORK_SCHOOL_NOTE",
}

export enum ErrorType {
  AMWELL_ERROR = "AMWELL_ERROR",
  AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR",
  BAD_REQUEST_ERROR = "BAD_REQUEST_ERROR",
  FORBIDDEN_ERROR = "FORBIDDEN_ERROR",
  NOT_FOUND_ERROR = "NOT_FOUND_ERROR",
  UNPROCESSIBLE_ENTITY = "UNPROCESSIBLE_ENTITY",
  USER_INPUT_ERROR = "USER_INPUT_ERROR",
}

/**
 * Which organization a Facility belongs to
 */
export enum FacilityOrg {
  CMD = "CMD",
  SMG = "SMG",
}

export enum InvoiceState {
  COMPLETE = "COMPLETE",
  PARTIAL = "PARTIAL",
  PENDING = "PENDING",
  PLAN = "PLAN",
  READY = "READY",
}

export enum Language {
  AFRIKAANS = "AFRIKAANS",
  AMHARIC = "AMHARIC",
  ARABIC = "ARABIC",
  ASL = "ASL",
  BENGALI = "BENGALI",
  CANTONESE = "CANTONESE",
  CHINESE = "CHINESE",
  CREOLE = "CREOLE",
  CROATIAN = "CROATIAN",
  CZECH = "CZECH",
  DUTCH = "DUTCH",
  ENGLISH = "ENGLISH",
  FILIPINO = "FILIPINO",
  FINNISH = "FINNISH",
  FRENCH = "FRENCH",
  FRYSIAN = "FRYSIAN",
  GERMAN = "GERMAN",
  GREEK = "GREEK",
  GUJARATI = "GUJARATI",
  HEBREW = "HEBREW",
  HINDI = "HINDI",
  ITALIAN = "ITALIAN",
  JAPANESE = "JAPANESE",
  KOREAN = "KOREAN",
  LITHUANIAN = "LITHUANIAN",
  MARATHI = "MARATHI",
  NORWEGIAN = "NORWEGIAN",
  POLISH = "POLISH",
  PORTUGUESE = "PORTUGUESE",
  PUNJABI = "PUNJABI",
  ROMANIAN = "ROMANIAN",
  RUSSIAN = "RUSSIAN",
  SANSKRIT = "SANSKRIT",
  SERBIAN = "SERBIAN",
  SPANISH = "SPANISH",
  SWEDISH = "SWEDISH",
  TAGALOG = "TAGALOG",
  TAIWANESE = "TAIWANESE",
  TAMIL = "TAMIL",
  TELUGU = "TELUGU",
  UKRANIAN = "UKRANIAN",
  URDU = "URDU",
}

export enum PaymentMethodType {
  ACH = "ACH",
  CREDIT_CARD = "CREDIT_CARD",
}

export enum PaymentScheduleFrequency {
  BIWEEKLY = "BIWEEKLY",
  MONTHLY_DAY_OF_MONTH = "MONTHLY_DAY_OF_MONTH",
  MONTHLY_LAST_DAY_OF_MONTH = "MONTHLY_LAST_DAY_OF_MONTH",
  MONTHLY_NTH_DAY_OF_WEEK = "MONTHLY_NTH_DAY_OF_WEEK",
  WEEKLY = "WEEKLY",
}

/**
 * Platforms that users register devices to for push notifications. The same notification can be sent to many devices and platforms
 */
export enum Platform {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

/**
 * A restricted set of genders which are used by the Provider API
 */
export enum ProviderGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum ScmdOrganization {
  CITY_MD = "CITY_MD",
  SUMMIT_HEALTH = "SUMMIT_HEALTH",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum StateCode {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  GU = "GU",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VA = "VA",
  VI = "VI",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export enum ValidationType {
  ALLOW = "ALLOW",
  ARRAY_CONTAINS = "ARRAY_CONTAINS",
  ARRAY_MAX_SIZE = "ARRAY_MAX_SIZE",
  ARRAY_MIN_SIZE = "ARRAY_MIN_SIZE",
  ARRAY_NOT_CONTAINS = "ARRAY_NOT_CONTAINS",
  ARRAY_NOT_EMPTY = "ARRAY_NOT_EMPTY",
  ARRAY_UNIQUE = "ARRAY_UNIQUE",
  CONTAINS = "CONTAINS",
  EQUALS = "EQUALS",
  IS_ALPHA = "IS_ALPHA",
  IS_ALPHANUMERIC = "IS_ALPHANUMERIC",
  IS_ARRAY = "IS_ARRAY",
  IS_ASCII = "IS_ASCII",
  IS_BASE32 = "IS_BASE32",
  IS_BASE64 = "IS_BASE64",
  IS_BIC = "IS_BIC",
  IS_BOOLEAN = "IS_BOOLEAN",
  IS_BOOLEAN_STRING = "IS_BOOLEAN_STRING",
  IS_BTC_ADDRESS = "IS_BTC_ADDRESS",
  IS_BYTE_LENGTH = "IS_BYTE_LENGTH",
  IS_CREDIT_CARD = "IS_CREDIT_CARD",
  IS_CURRENCY = "IS_CURRENCY",
  IS_DATA_URI = "IS_DATA_URI",
  IS_DATE = "IS_DATE",
  IS_DATE_STRING = "IS_DATE_STRING",
  IS_DECIMAL = "IS_DECIMAL",
  IS_DEFINED = "IS_DEFINED",
  IS_DIVISIBLE_BY = "IS_DIVISIBLE_BY",
  IS_EAN = "IS_EAN",
  IS_EMAIL = "IS_EMAIL",
  IS_EMPTY = "IS_EMPTY",
  IS_ENUM = "IS_ENUM",
  IS_ETHEREUM_ADDRESS = "IS_ETHEREUM_ADDRESS",
  IS_FIREBASE_PUSH_ID = "IS_FIREBASE_PUSH_ID",
  IS_FQDN = "IS_FQDN",
  IS_FULL_WIDTH = "IS_FULL_WIDTH",
  IS_HALF_WIDTH = "IS_HALF_WIDTH",
  IS_HASH = "IS_HASH",
  IS_HEXADECIMAL = "IS_HEXADECIMAL",
  IS_HEX_COLOR = "IS_HEX_COLOR",
  IS_HSL = "IS_HSL",
  IS_IBAN = "IS_IBAN",
  IS_IDENTITY_CARD = "IS_IDENTITY_CARD",
  IS_IN = "IS_IN",
  IS_INSTANCE = "IS_INSTANCE",
  IS_INT = "IS_INT",
  IS_IP = "IS_IP",
  IS_ISBN = "IS_ISBN",
  IS_ISIN = "IS_ISIN",
  IS_ISO31661_ALPHA2 = "IS_ISO31661_ALPHA2",
  IS_ISO31661_ALPHA3 = "IS_ISO31661_ALPHA3",
  IS_ISO8601 = "IS_ISO8601",
  IS_ISRC = "IS_ISRC",
  IS_ISSN = "IS_ISSN",
  IS_JSON = "IS_JSON",
  IS_JWT = "IS_JWT",
  IS_LATITUDE = "IS_LATITUDE",
  IS_LAT_LONG = "IS_LAT_LONG",
  IS_LOCALE = "IS_LOCALE",
  IS_LONGITUDE = "IS_LONGITUDE",
  IS_LOWERCASE = "IS_LOWERCASE",
  IS_MAC_ADDRESS = "IS_MAC_ADDRESS",
  IS_MAGNET_URI = "IS_MAGNET_URI",
  IS_MILITARY_TIME = "IS_MILITARY_TIME",
  IS_MIME_TYPE = "IS_MIME_TYPE",
  IS_MOBILE_PHONE = "IS_MOBILE_PHONE",
  IS_MONGO_ID = "IS_MONGO_ID",
  IS_MULTIBYTE = "IS_MULTIBYTE",
  IS_NEGATIVE = "IS_NEGATIVE",
  IS_NOT_EMPTY = "IS_NOT_EMPTY",
  IS_NOT_EMPTY_OBJECT = "IS_NOT_EMPTY_OBJECT",
  IS_NOT_IN = "IS_NOT_IN",
  IS_NUMBER = "IS_NUMBER",
  IS_NUMBER_STRING = "IS_NUMBER_STRING",
  IS_OBJECT = "IS_OBJECT",
  IS_OCTAL = "IS_OCTAL",
  IS_OPTIONAL = "IS_OPTIONAL",
  IS_PASSPORT_NUMBER = "IS_PASSPORT_NUMBER",
  IS_PHONE_NUMBER = "IS_PHONE_NUMBER",
  IS_PORT = "IS_PORT",
  IS_POSITIVE = "IS_POSITIVE",
  IS_POSTAL_CODE = "IS_POSTAL_CODE",
  IS_RFC3339 = "IS_RFC3339",
  IS_RGB_COLOR = "IS_RGB_COLOR",
  IS_SEM_VER = "IS_SEM_VER",
  IS_STRING = "IS_STRING",
  IS_SURROGATE_PAIR = "IS_SURROGATE_PAIR",
  IS_UPPERCASE = "IS_UPPERCASE",
  IS_URL = "IS_URL",
  IS_UUID = "IS_UUID",
  IS_VARIABLE_WIDTH = "IS_VARIABLE_WIDTH",
  JWT_EMAIL_MISSING = "JWT_EMAIL_MISSING",
  JWT_PHONE_MISSING = "JWT_PHONE_MISSING",
  LENGTH = "LENGTH",
  MATCHES = "MATCHES",
  MAX = "MAX",
  MAX_DATE = "MAX_DATE",
  MAX_LENGTH = "MAX_LENGTH",
  MIN = "MIN",
  MIN_DATE = "MIN_DATE",
  MIN_LENGTH = "MIN_LENGTH",
  NOT_CONTAINS = "NOT_CONTAINS",
  NOT_EQUALS = "NOT_EQUALS",
  UNKNOWN = "UNKNOWN",
  VALIDATE = "VALIDATE",
  VALIDATE_BY = "VALIDATE_BY",
  VALIDATE_IF = "VALIDATE_IF",
  VALIDATE_NESTED = "VALIDATE_NESTED",
  VALIDATE_PROMISE = "VALIDATE_PROMISE",
}

export interface BookAppointmentInput {
  reservationId: string;
  reasonForAppointment: string;
  timeSlot: TimeSlotInput;
}

export interface BuildDynamicLinkInput {
  path: string;
}

export interface CaregiverAccessChildInput {
  firstName: string;
  lastName: string;
  dob: any;
  phoneNumber: string;
  email?: string | null;
  legalSex: AdministrativeGender;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: StateCode | null;
  postalCode?: string | null;
  useDifferentAddress: boolean;
  sourceId: string;
}

export interface CaregiverNotificationPreferenceValue {
  appointmentReminders?: boolean | null;
  patientInformation?: boolean | null;
  dealsAndPromotions?: boolean | null;
  prescriptions?: boolean | null;
}

export interface CedarSettingsInput {
  email?: boolean | null;
  phoneText?: boolean | null;
  ringlessCall?: boolean | null;
  phoneCall?: boolean | null;
  paperMail?: boolean | null;
}

export interface ConfirmChildCaregiverAccessInput {
  caregiverAccessId: string;
}

export interface ConfirmInviteCaregiverAccessInput {
  caregiverAccessId: string;
  isNewAccount?: boolean | null;
}

export interface ConfirmRevokedCaregiverAccessInput {
  caregiverAccessId: string;
}

export interface ContinueDecisionSupportInput {
  flowSessionId: string;
  elementSetRef: string;
  responses: QuestionResponseInput[];
}

export interface CreateCaregiverAccessInput {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  addChildren?: boolean | null;
  children?: CaregiverAccessChildInput[] | null;
}

export interface CreateFeedbackInput {
  topic: string;
  feedback: string;
  appVersion?: string | null;
  platform: string;
}

export interface CreatePatientInput {
  firstName: string;
  lastName: string;
  gender: AdministrativeGender;
  address: PatientAddressInput;
}

export interface CreateUserInput {
  emailAddress: string;
  firstName: string;
  linkedPhoneNumber: string;
  lastName: string;
  dateOfBirth: any;
  termsOfUseAcceptedDateTime: any;
  password: string;
  gender: AdministrativeGender;
  address: PatientAddressInput;
  keyArrayBuffer: string;
  ivArrayBuffer: string;
}

export interface DataFieldInput {
  fieldName: string;
  currentValue?: string | null;
  valueType: DSValueType;
}

export interface DeleteCaregiverAccessInput {
  caregiverAccessId: string;
  isCancelled?: boolean | null;
}

/**
 * A GeoCircle represents a circle of radius `radius` miles located at point (`lat`, `lng`) on the Earth.
 */
export interface GeoCircleInput {
  lat: number;
  lng: number;
  radius: number;
}

export interface NotificationPreferenceValue {
  pushNotification?: boolean | null;
  smsNotification?: boolean | null;
  emailNotification?: boolean | null;
  phoneCall?: boolean | null;
}

export interface PatientAddressInput {
  line1: string;
  line2?: string | null;
  city: string;
  state: StateCode;
  postalCode: string;
  use?: AddressUse | null;
}

export interface QuestionResponseInput {
  elementRef: string;
  responseValue: string;
}

export interface SendCaregiverAccessReminderInput {
  caregiverAccessId: string;
  isPendingAccount?: boolean | null;
}

export interface SignUpInput {
  emailAddress: string;
  firstName: string;
  linkedPhoneNumber: string;
  lastName: string;
  dateOfBirth: any;
  termsOfUseAcceptedDateTime: any;
  password: string;
  gender: AdministrativeGender;
  address: PatientAddressInput;
}

export interface StartDecisionSupportInput {
  flowId: string;
  fields?: DataFieldInput[] | null;
}

export interface TimeSlotInput {
  calendarId: string;
  date: string;
  startTime: string;
  endTime: string;
  appointmentTypeId: string;
}

export interface UpdateB2CUserPasswordInput {
  password: string;
  keyArrayBuffer: string;
  ivArrayBuffer: string;
}

export interface UpdateCaregiverAccessInput {
  caregiverAccessId: string;
  newStatus: CaregiverStatus;
  isPendingAccount?: boolean | null;
}

export interface UpdateCedarSettingsInput {
  mrn: string;
  org: ScmdOrganization;
  settings: CedarSettingsInput;
}

export interface UpdateCodeVerificationInput {
  sid?: string | null;
  type: string;
  code: string;
}

export interface UpdateDocumentReadStatusInput {
  documentNumber: string;
  enteredAtCode: string;
}

export interface UpdateHomeAddressInput {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  postalCode: string;
}

export interface UpdateLinkedPhoneNumberInput {
  linkedPhoneNumber: string;
}

export interface UpdateMFAPhoneNumberInput {
  mfaPhoneNumber: string;
}

export interface UpdateNotificationPreferenceInput {
  appointmentReminders?: NotificationPreferenceValue | null;
  patientInformation?: NotificationPreferenceValue | null;
  dealsAndPromotions?: NotificationPreferenceValue | null;
  prescriptions?: NotificationPreferenceValue | null;
  referrals?: NotificationPreferenceValue | null;
  caregivers?: CaregiverNotificationPreferenceValue | null;
}

export interface UpdatePreferredPharmacyInput {
  ncpdpId: string;
  facilityId: string;
}

export interface UpdateTwilioVerificationInput {
  sid?: string | null;
  type: string;
  code: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
